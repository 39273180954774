import React, { useState, useEffect, useRef, useContext } from "react";
import { dogSearch, getAlldogs } from "../../api";
// import placeHolder from "../../assets/Images/placeholderdog.jpg";
import { getUnique } from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import loader from "../../assets/Images/loader.gif";
import logo from '../../assets/Images/rottweiler-logo.png';
import Sidebar from "../../layouts/SideNavigation";
import { Link,useParams,useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from "../../components/loader";
import Sidebar_Home from "../../layouts/SideNavigation_Home";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import { GlobalContext } from "../../context/store";
import VerticalAdverts from "../../components/verticalAdverts";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const Dogs = () => {
  const [dogsData, setDogsData] = useState([]);
  const [searchLetters, setLetters] = useState([]);
  const [dogs, setDogs] = useState([]);
  // const [breeds, setBreeds] = useState([]);
  // const [breed, setBreed] = useState({});
  const [loading, setLoading] = useState(false);
  const {user}=useContext(GlobalContext)
  // const{slug}=useParams()
  // const breedRef = useRef(null)
  // var navigate=useNavigate()
  useEffect(()=>{
    setLoading(true)
    const fetchData=async()=>{
      const data = await getAlldogs()
      setDogs(data&&data);
      // const res_=await getAllBreeds()
      // setBreeds(res_&&res_)

      setTimeout(()=>{setLoading(false)
      },1000)
    }
    fetchData()
  },[])

  useEffect(async () => {
    let letter_arr = [];
    if (dogsData) {
      for (let i of dogs) {
        letter_arr.push(i?.dog_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
  }, [dogsData]);

  useEffect(async () => {
    setDogsData(dogs);
    let letter_arr = [];
    if (dogsData) {
      for (let i of dogs) {
        letter_arr.push(i?.dog_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
  }, [dogs]);

  const filterByFirstLtr = (letter) => {
    if (letter == "*") {
      setDogsData(dogs);
    }
    else {
      const data = dogs?.filter((data) => data.dog_name.charAt(0).toUpperCase() == letter);
      setDogsData(data);
    }
  };

  const getUniqueSearch = (arr) => {
      if(arr){
        let newArr=Object.values(
          arr?.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
        );
        return newArr
      }
        
else{
  return arr
}
  
    
  };

  const handleChange = async (e) => {
    setLoading(true);
    let result = await dogSearch(e);
    if (e === "") {
      setDogsData(dogs);
      let letter_arr = [];
      if (dogsData) {
        for (let i of dogs) {
          letter_arr.push(i?.dog_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    } else {
    
      setDogsData(getUniqueSearch(result));

      let letter_arr = [];
      if (dogsData) {
        for (let i of dogs) {
          letter_arr.push(i?.dog_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    }
    setLoading(false);
  };
  // const onChangeBreed=async(id)=>{
  //   const data = await getAllDogsByBreed(id);
  //   setDogs(data);
  // }
  const imageURL="https://admin.rottweiler.pk/public/dog_images/"
  const hideModal=()=>{
    let el = document.getElementById('HideCollapse ');
    if (el.onclick) {
       el.onclick();
    } else if (el.click) {
       el.click();
    }
  }

  return (
    <>
      <div className="bg-white  mx-auto rounded p-2 mt-5 row  ">
    
      <div className="col-md-2  rounded">
      {
    !user?
    <Sidebar_Home/>
    :<Sidebar_Member/>
    }
      </div>
      <div className="col-md-8  px-0 ">
        <div className="row">
        {/* <div className="container col-md-4">
        <Select
                placeholder={ breed?.breed}
                ref={breedRef}
                options={breeds?.map(({friendly_url,breed})=>({value:friendly_url,label:breed}))}
                onChange={(e) => navigate(`/PRC-database/${e?.value}`)}
              />
      </div> */}
        <div className="container">
        <h1 className="mb-3 mx-2">Rottweiler Dogs in Pakistan</h1>
          </div>
      <div className="container col-md-12 d-flex justify-content-end px-2">
        <input
          type="search"
          className="form-control search-input  mx-3"
          PlaceHolder="Search by Dog Name, KP, Microchip No. "
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
        </div>
      <div className="container  w-100 flex-wrap d-flex justify-content-end px-2 ">
        <button
          onClick={() => {
            filterByFirstLtr("*");
          }}
          className="filterBtn my-2 btn fw-bold shadow-sm border "
        >
          All
        </button>
        {searchLetters &&
          searchLetters.filter(item=>/[A-Z]/.test(item)).sort().map((item, ind) => (
            <button
              key={ind}
              onClick={() => {
                filterByFirstLtr(item);
              }}
              className="filterBtn my-2 btn fw-bold shadow-sm border"
            >
              {item}
            </button>
          ))}
            {/* <button
          onClick={() => {
            filterByFirstLtr("asc");
          }}
          className="filterBtn my-2 "
        >
          A - Z
        </button>
            <button
          onClick={() => {
            filterByFirstLtr("dec");
          }}
          className="filterBtn my-2 "
        >
          Z - A
        </button> */}
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        {loading ? (
          [0,0,0,0,0,0,0,0].map((a)=>(
            <div className="card shadow-sm my-2 mx-2 px-2" style={{ width: "12rem" }}>
              <Skeleton height={125}/>
              <Skeleton height={30} className="my-2"/>
          </div>
          ))
          
        ) : dogsData && dogsData.length ? (
          dogsData?.map(({id,friendly_URL, dog_name, image }) => (
            <Link to={`/dog/bio/${friendly_URL}`} style={{textDecoration:"none"}}>
            <div className="card shadow-sm my-2 mx-2" style={{ width: "12rem" }}>
              <div className="">
                { image == null || image == "" ? (
                  <img
                  className="card-img-top"
                  src={placeHolder}
                  alt={dog_name}
                />
                ) : (
                  <img
                  className="card-img-top"
                  src={
                    imageURL+id+"/"+image
                  }
                  alt={dog_name}
                />
                )}
              </div>
              <div className="card-body">
                <h5 className="card-title ">{dog_name}</h5>
                {/* <a href="#" className="btn-grad">Go somewhere</a> */}
              </div>
            </div>
            </Link>
          ))
        ) : <h2>No dogs found !</h2>}
      </div>
      </div>
      <div className="col-md-2  rounded justify-content-center align-items-center">
      <VerticalAdverts/>
      </div>
    </div>
    </>
  );
};

export default Dogs;

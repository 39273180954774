import React, { useContext, useEffect, useState } from 'react'
import { getMemberAccounts, getMemberAccountsByDate } from '../../api'
import {toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import { GlobalContext } from '../../context/store';

const BalanceStatment = () => {
    const [toDate,setTodate]=useState('')
    const [fromDate,setFromDate]=useState('')
    const [balance,setBalance]=useState(0)
    const [activity,setActivity]=useState([])
    const [balArr,setBalArr]=useState([])
    const [transactions,setTransactions]=useState([])

    const{user}=useContext(GlobalContext)
        
    useEffect(()=>{
        const fetchData=async()=>{
            const memberId=user?.account_id
            const response=await getMemberAccounts(memberId);
            const response2=await getMemberAccounts(memberId);
            response.json().then(balance => ({
                balance: balance,
                status: response.status
            })
            ).then(res => {
                setBalance(res.balance.balance * -1)
            });

            response2.json().then(activity => ({
                activity: activity,
                status: response.status
            })
            ).then(res2 => {
                setTransactions(res2.activity.activity)

                let balArr=[]
                let b=0
                for (let t = 0; t < res2.activity.activity.length; t++){
                    let debit=res2.activity.activity[t]?.debit
                    let credit=res2.activity.activity[t]?.credit
                
                    if(debit){
                        b=b-parseInt(debit)
                        balArr.push(b)
                    }
                 
                    if(credit){
                        b=b+parseInt(credit)
                        balArr.push(b)
                    }
                 
                }
                setBalArr([...balArr])
            });
        }
        fetchData(); 
    },[])

    // useEffect(()=>{

    //     getBalance()
    // },[transactions])
    const notify = () => toast.info('Please Select Date Range', {
        // position: "top-center",
        // autoClose: 3000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,

        style: { zIndex: "10000000" },

        });
    
    const filterAccounts=async()=>{

        if(!toDate || !fromDate){
            notify()
        }
        else{
            setTransactions([])
            const memberId=user?.id
            const res=await getMemberAccountsByDate({
    
                toDate:new Date(toDate)?.slice(0, 10),
                fromDate:new Date(fromDate).slice(0, 10),
                memberId:memberId
            })
            console.log("trabs",{
                toDate:new Date(toDate).slice(0, 10),
                fromDate:new Date(fromDate).slice(0, 10),
                memberId:memberId
            })
            setTransactions(res)
        }
        }
    
        const selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
          }


          const getBalance=()=>{
                let balArr=[]
                let b=0
                for (let t in transactions){
                    let debit=transactions[t]?.debit
                    let credit=transactions[t]?.credit
                
                    if(debit){
                        b=b-parseInt(debit)
                        balArr.push(b)
                    }
                 
                    if(credit){
                        b=b+parseInt(credit)
                        balArr.push(b)
                    }
                 
                }
                setBalArr([...balArr])

                console.log(balArr);

          }



    return (
        <div className="">
            <div className="bg-light flex-wrap d-flex justify-content-between p-2 rounded shadow-sm">
                <div>
                    <h3>Account Transactions </h3>
                    <span className="badge bg-primary">Current Balance: {balance}</span>
                </div>
                <div className='mt-2 d-flex flex-wrap justify-content-between align-items-center'>
                    <button className="shadow-sm btn btn-secondary">Fund Transfer</button>
                    <button className="shadow-sm btn btn-success">Old Transaction History</button>
                </div>
            </div>
            {/* <div className="bg-white my-4 mx-0 row p-2 rounded shadow-sm">
                <div className="col-md-3">
                    <input type="date" className="form-control" value={toDate} onChange={(e)=>setTodate(e.target.value)}/>
                </div>
        
                <div className="col-md-3">
                   <input type="date" className="form-control" value={fromDate} onChange={(e)=>setFromDate(e.target.value)}/>
                </div>

                <div className="col-md-3">
                     <input type="button" className="btn btn-warning my-0 shadow-sm text-white" value="Print Statment" onClick={()=>filterAccounts()}/>
                </div>
            </div> */}
            
            <div className="mt-3 bg-light shadow-sm p-2 rounded">
                <table className="table border bg-white">
                    <tr>
                        <th>Date</th>
                        <th>Remarks</th>
                        <th>Paid</th>
                        <th>Deposit</th>
                        <th>Balance</th>
                    </tr>
                    {
                        transactions?.length > 0 ? transactions?.map((action => 
                        <tr className="p-3">
                        <td >{new Date(action.created_at).toDateString()}</td>
                        <td>{action.remarks}</td>
                        <td>{action.debit || "-"}</td>
                        <td>{action.credit || "-"}</td>
                        <td>{
                          balArr[transactions.indexOf(action)]
                        }</td>
                    </tr>
                        ))
                        :
                        <tr className="p-3">
                            <td colSpan={5}><center>No Wallet Activity Yet {transactions}</center></td>
                        </tr>
                    }
                  
                </table>
            </div>
        </div>
    )
}



export default BalanceStatment

import React, { useState,useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { getAllBlogs, getHorizontalAds } from "../../api";
import logo from '../../assets/Images/rottweiler-logo.png'
import VerticalAdverts from "../../components/verticalAdverts";
import { GlobalContext } from "../../context/store";
import Sidebar_Home from "../../layouts/SideNavigation_Home";
import Sidebar_Member from "../../layouts/SideNavigation_Member";


function Home() {
  const [posts,setPosts]=useState([])
  const [ads,setAds]=useState([])
  const {user}=useContext(GlobalContext)

  useEffect(()=>{
    const fetchData=async()=>{
        const res=await getAllBlogs()
        setPosts(res&&res)
       
    }

    fetchData()
},[])
  return (
    <div className="">
      <div className="content_wrapper bg-white  rounded  mt-5">  
      <div className="row mx-0">
        <div className="col-md-2">
     
          
{
!user?
<Sidebar_Home/>
:<Sidebar_Member/>

}
        </div>
        <div className="col-md-8">
        <div className="container-fluid">
          <h3 className="p-2 bg-light mt-5 shadow-sm rounded fw-bold">News And Updates</h3>
          {
            posts&&posts?.map(({title,link,preview_text,publishing_date})=>(
              <div className="card my-2 px-2">
                <Link k to={"/about/news-and-updates/"+link||'#'} style={{textDecoration:'none'}}>

              <h5 className="txt-primary fw-bold my-3">
                {title?.slice(0,80)}...
              </h5>
              </Link>
              {/* <h6 className="text-muted"> {new Date(publishing_date).toDateString()}</h6> */}
              <p className="text-muted">{preview_text}</p>
              </div>
           
            ))
          }
          <hr/>
        </div>

        </div>
        <div className="col-md-2 my-2 justify-content-center align-items-center">
            <VerticalAdverts/>
       
        </div>
      </div>
  
      </div>
    </div>
  );
}

export default Home;

import React from 'react'
import loader from '../assets/Images/loader.gif'
const Loader = ({size}) => {

    return (
        <div className="d-flex justify-content-center align-items-center w-100" style={{height:'80vh'}}>
            <img src={loader} style={{height:size}}/>
        </div>
    )
}

export default Loader

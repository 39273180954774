import React, { useContext, useEffect, useState } from 'react'
import {Link, useParams} from 'react-router-dom';
import { getAllRules } from '../../api';
import { GlobalContext } from '../../context/store';
import logo from '../../assets/Images/rottweiler-logo.png'

const Sidebar_Rules = ({id}) => {

    const[rules,setRules]=useState([])

    useEffect(()=>{
        const fetchData=async()=>{
            const data=await getAllRules()
            setRules(data&&data)
        }

        fetchData()

    },[])


    return (
        <div className="side-menu  rounded">
             <div className="logo">
                 <Link to="/">
                <img src={logo}/>
                </Link>
            </div>
            <hr/>{
                  rules&&rules?
                  <ul className="mx-2 p-2 rounded  sider-bar-background">
                  {
                      rules&&rules?.map(({rule_name,friendly_url})=>(
      
                          <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={friendly_url?friendly_url:"#"}> {rule_name}</Link></li>
                      ))
                  }
            
              </ul>:null
            }

    </div>
    )
}

export default Sidebar_Rules

import React from 'react'
import { Link } from 'react-router-dom'

const News = ({id,posting_date,title,content,link}) => {
    return (
        <div className="news-item my-3 bg-white p-2 rounded shadow-sm border">
            <h5 className="txt-primary fw-bold">{title}</h5> 
            {/* <span className="">Published: {posting_date}</span> */}
            <p className="mt-3">{content}</p>
            <Link to={link} className="btn btn-sm btn-primary">Read Now</Link> 
        </div>
    )
}

export default News

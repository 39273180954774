import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { getRule } from '../../../../api'

const Rule = () => {
    const {slug}=useParams()
    const[rule,setRule]=useState({})

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getRule(slug)
            setRule(res&&res[0])
        }
        fetchData()
        console.log("data",rule)
    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    return (
        <div>
            
            <Link className="btn btn-primary" to="/about">Back</Link>
                 <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(rule?.content)}}></div>
        </div>
    )
}

export default Rule

import React from 'react'
import { Link } from 'react-router-dom';
import placeHolder from "../assets/Images/image_paceholder.png";

const Kennel = ({data}) => {

  const imageURL=""
    return (
        <div className="col-md-4">
        <Link to={`/kennels-directory/${data?.id?data?.kennel_id:"#"}`} className="text-decoration-non">
        <div className="d-flex border justify-content-around align-items-center p-2 w-100 shadow-member member-profile-main">
          <div className=" bordered">
            <img className="member-profile-img" src={data?.photo?imageURL+data?.photo:placeHolder}/>
          </div>
          <div className="member-profile-description">
            <h5 className="text-center">{data?.kennel_name}</h5>
          </div>
        </div>
      </Link>
      </div>
    )
}

export default Kennel

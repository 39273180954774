import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
// import {FiAlignRight,FiXCircle,FiChevronDown } from "react-icons/fi";
// import logo from '../../img/logo.png';
import "../../layouts/NAvBar-2/index.css";
import { dogSearch } from "../../api";
import Highlight from "react-highlighter";
import { Scrollbars } from "react-custom-scrollbars";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState([{}]);

  const getUniqueSearch = (arr) => {
    let newArr=Object.values(
      arr.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );
   return newArr
    
  };

  useEffect(async () => {
    if (keyword && keyword.length >= 3) {
      const res = await dogSearch(keyword);

      setResults(getUniqueSearch(res));
      console.log(res, "res");
    }
    if (keyword == "") {
      setResults([]);
    }
  }, [keyword]);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1 mt-3 "];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  return (
    <header className="header__middle navbar-main">
      <div className="container">
        <div className="row">
          <div className="header__middle__menus">
            <nav className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose === true ? (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    <div className="container-toggle">
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    <div className="container-toggle">
                      <div className="bar1"></div>
                      <div className="bar2"></div>
                      <div className="bar3"></div>
                    </div>
                  </span>
                </>
              )}

              <ul className={boxClass.join(" ")}>
                <li
                  onClick={toggleSubmenu}
                  className="nav-item menu-item sub__menus__arrows"
                >
                  {" "}
                  <Link
                    className="about-link"
                    style={{ margin: "0.4rem" }}
                    to="#"
                  >
                    {" "}
                    About
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      <Link onClick={toggleClass} to="/about">
                        About PRC
                      </Link>
                    </li>
                    <li>
                    <Link to={`about/the-breed`}>About The Breed </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="/about/subscription"
                      >
                        Subscription And Fee Structure
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="/rules/code-of-ethics"
                      >
                        Rules And Regulations
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="about/prc-team"
                      >
                        The PRC Team
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="about/prc-judges"
                      >
                        PRC Judges
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="about/visiting-judges"
                      >
                        Visiting Judges
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={toggleClass}
                        activeClassName="is-active"
                        to="about/news-and-updates"
                      >
                        News and Updates
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                   onClick={toggleClass}
                   activeClassName="is-active"
                  className="nav-link" to="/prc-database">
                    PRC DATABASE
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={toggleClass}
                    activeClassName="is-active"
                    className="nav-link"
                    to="kennels-directory"
                  >
                    KENNELS DIRECTROY
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={toggleClass}
                    activeClassName="is-active"
                    className="nav-link"
                    to="members-directory"
                  >
                    MEMBERS DIRECTORY
                  </Link>
                </li>

                <li className="nav-item">
                  <Link 
                  className="nav-link"
                   onClick={toggleClass}
                   activeClassName="is-active"
                  to="events">
                    EVENTS
                  </Link>
                </li>

                <li className="nav-item">
                  <Link 
                   onClick={toggleClass}
                   activeClassName="is-active"
                  className="nav-link" to="show-results">
                    SHOW RESULTS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link 
                   onClick={toggleClass}
                   activeClassName="is-active"
                  className="nav-link" to="/records-of-recent-matings">
                    Record of Recent Matings
                  </Link>
                </li>
                <li>
                  <form className="my-2">
                    <div className="search-input d-flex align-items-center">
                      {/* <label For="search">Search</label> */}
                      &nbsp;
                      <div>
                        <input
                          value={keyword}
                          type="search"
                          className="shadow "
                          onChange={(e) => setKeyword(e.target.value)}
                          placeholder="Search by dog name , owner name"
                        />
                        {keyword ? (
                          <Scrollbars
                            style={{
                              position: "absolute",
                              height: 400,
                              zIndex: 10000,
                            }}
                          >
                            <ul className="list-group  position-absolute bg-white shadow">
                              {results &&
                                results.map((item, _i) => (
                                  <li
                                    className="SearchlistItem"
                                    key={_i}
                                    onClick={() => setKeyword("")}
                                  >
                                    <Link
                                      style={{
                                        color: "black",
                                        textDecoration: "none",
                                      }}
                                      to={`/dog/bio/${item?.friendly_URL}`}
                                    >
                                      <Highlight search={keyword}>
                                        {item?.dog_name}
                                      </Highlight>
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </Scrollbars>
                        ) : null}
                      </div>
                    </div>
                  </form>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbarmenu;

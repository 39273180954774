import React, { useEffect, useState } from 'react'
import { getDogById } from '../api'

const StudDetails = ({id,type}) => {

    const [data,setData]=useState({})

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getDogById(id)
            setData(res&&res[0])
        }
        fetchData()
    },[id])
    return (
        <div>
              <div className="row mt-3">
              <div className="col-md-6">
                <label className="fw-bold text-muted">KP No.</label>
                <span className="d-block">{data&&data?.KP}</span>
              </div>
              <div className="col-md-6">
                <label className="fw-bold text-muted">Date of Birth</label>
                <span className="d-block">{new Date(data&&data?.dob).toDateString()}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label className="fw-bold text-muted">Traning level</label>
                <span className="d-block">-</span>
              </div>
              <div className="col-md-6">
                <label className="fw-bold text-muted">Tattoo</label>
                <span className="d-block">{data&&data?.microchip?data.microchip:"-"}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <label className="fw-bold text-muted">Breed Survey</label>
                <span className="d-block">{data&&data?.breed_survey_done?"Done":"Not Done"}</span>
              </div>
              <div className="col-md-6">
                <label className="fw-bold text-muted">Name of Owner (s) of {type}</label>
                <span className="d-block">{data&&data?.owners}</span>
              </div>
            </div>
        </div>
    )
}

export default StudDetails

import React, { useContext, useEffect, useState } from 'react';
import { MDBDataTableV5,MDBBadge } from 'mdbreact';
import { Link } from 'react-router-dom';
import { getAllStudCertificates, getMemberAccounts, postStudNOC } from '../../api';
import Loader from '../../components/loader';
import { GlobalContext } from '../../context/store';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function StudCertificates() {

  const [data,setData]=useState([])
  const [loading,setLoading]=useState(false)
  const [noc, setCurNOC] = useState([]);
  let navigate = useNavigate();

  const{user}=useContext(GlobalContext)

      const balance=async()=>{
        const memberId=user?.account_id
        const response=await getMemberAccounts(memberId);
        response.json().then(balance => ({
          balance: balance,
          status: response.status
        })
        ).then(res => {
          if(res.balance.balance > 0)
          {
            navigate(`/member/member-account-statement`);
          }
        });
      }

      const issueNOC = async() =>{

        let formData = new FormData();
        formData.append("stud_id", noc?.id);
        formData.append("noc_by", user?.id);

        // console.log(noc);
        const res = await postStudNOC(formData);

        // toast.loading("Please wait...",{ style: { zIndex: "10000000" } });

        if (res.status == 200) {

          // toast.dismiss();

          toast.success(
            "Stud Certificate NOC has been issued!",
            {
              // position: "top-center",
              // autoClose: 2000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            },
          );

          navigate(`/member/stud-certificates`);
        }
        else {
          if (res.status == 406) {
            // toast.dismiss();

            toast.error("Failed to Save! Non-Sufficient Funds of Member", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setCurNOC(null)
          }
          else {
            if (res.status == 500) {
              // toast.dismiss();
              // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
              toast.error("Something went wrong! Please try again", {
                // position: "top-center",
                // autoClose: 3000,
                // hideProgressBar: false,
                // closeOnClick: true,
                // pauseOnHover: true,
                // draggable: true,
                // progress: undefined,

                style: { zIndex: "10000000" },

              });
            }
            else
            {
              if (res.status == 403) {
                // toast.dismiss();
                // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                toast.error("This Stud Certificate does not belong to you!", {
                  // position: "top-center",
                  // autoClose: 3000,
                  // hideProgressBar: false,
                  // closeOnClick: true,
                  // pauseOnHover: true,
                  // draggable: true,
                  // progress: undefined,
  
                  style: { zIndex: "10000000" },
  
                });
              } 
            }
          }
        }
      }
  
  useEffect(() => {
    const fetchData=async()=>{
      setLoading(true)
      const res=await getAllStudCertificates(user?.id)
      setData(res&&res?.map(({id,sire_name,sire_url,dam_name,dam_url,mating_date,issue_noc,issue_noc_date,user,user_url,created_by,created_at,status})=>(
        {id,sire_name,sire_url,dam_name,dam_url,mating_date,issue_noc,issue_noc_date,user,user_url,created_by,created_at,status})))
        setLoading(false)
        
      }
      fetchData()
      balance();
    }, [])


  var datatable={
      columns: [
     
    ],
    rows:data
  }

  const badgesData = {
    columns: [
      ...datatable.columns,
      {
        label: 'Sire',
        field: 'sire_name',
        width: 200,
      },
      {
        label: 'Dam	',
        field: 'dam_name',
        width: 200,
      },
      {
        label: 'Created By',
        field: 'user',
        width: 200,
      },
      {
        label: 'Mating Date	',
        field: 'mating_date',
        width: 200,
      },
      {
        label: 'NOC	',
        field: 'noc',
        width: 200,
      },
      {
        label: 'Created Date',
        field: 'created_at',
        sort: 'asc',
        width: 100,
      },
     
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ],
    rows: [
      ...datatable.rows.map((row, order) => ({
        ...row,
        status: (
          <MDBBadge style={{color:'black',backgroundColor:'lightgrey'}} pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            {row?.status}
          </MDBBadge>
        ),
        actions: (
        
            <Link to={`/member/stud-certificates/${row?.id}`}><i style={{fontSize:18}} className="fas fa-eye"></i></Link>
       
        ),
        sire_name: (
            <Link to={`/dog/bio/${row?.sire_url}`} style={{textDecoration:'none'}}>{row.sire_name}</Link>
        ),
        dam_name: (
            <Link to={`/dog/bio/${row?.dam_url}`}  style={{textDecoration:'none'}}>{row.dam_name}</Link>
        ),
        user: (
            <Link to={`/member-profile/${row?.user_url}`} style={{textDecoration:'none'}}>{row.user}</Link>
        ),
        mating_date:(<span>{row?.mating_date?.slice(0,10)}</span>),
        noc:(
          <span>
            {row.issue_noc == 'Yes' ? 
              'Issue Date : ' + row.issue_noc_date.slice(0,10)
            : <button data-toggle="modal" data-target="#issueNOC" className="btn btn-warning text-white text-bold" onClick={() => setCurNOC(row)}>
                <i className="fa fa-upload"></i>&nbsp;
                Issue NOC
              </button>
            }
          </span>
        ),
        created_at:(<span>{row?.created_at?.slice(0,10)}</span>)
      })),
    ],
  };

  return( 
      <>
      <div className="d-flex justify-content-between align-items-center">

      <h1>Stud Certificate - List</h1>
      
      <Link to={`create`}><button className="btn btn-primary shadow"><i className="fas fa-plus-circle"></i>&nbsp;Create a New Stud Certificate</button></Link>
      </div>
      {
        loading?<Loader size={50}/>:
        <MDBDataTableV5 className="shadow mt-2 p-2 rounded " 
        hover entriesOptions={[10, 20, 25.,50]} entries={10}
         pagesAmount={10} data={badgesData} sortRows={['badge']} searchTop searchBottom={false} materialSearch />
      }

    <div class="modal fade" id="issueNOC" tabindex="-1" role="dialog" aria-labelledby="editModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title fw-bold" id="editModalLabel">
              Are you sure?
            </h5>
            <button type="button" class="close btn" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body " style={{textAlign:"center",}}>
            <span >Are you sure you want to issue an <strong>NOC for {noc?.sire_name + ' X ' + noc?.dam_name}</strong>?</span>
          </div>
          <div class="modal-footer" style={{justifyContent:'space-around'}}>
            <button type="button" class="btn btn-danger" data-dismiss="modal" id="closeModal">
              No
            </button>
            <button type="button" onClick={()=> issueNOC()} data-dismiss="modal" class="btn btn-success">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>

   </>
  );
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getVisitingJudgesBio } from '../../../../api';
import placeHolder from'../../../../assets/Images/userPlaceholder.png'

const VisitingJudgesBio = () => {

    const {slug}=useParams();
    const[bio,setBio]=useState({})

    useEffect(()=>{

        const fetchData=async()=>{
            const res=await getVisitingJudgesBio(slug)
            setBio(res&&res[0])
        }
        fetchData()

    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    const imgURL="https://admin.rottweiler.pk/website_images/visit_judge_images/";
    return (
        <div className="container mt-5">
        <div id="circle-shape-example">
            <div>
                <img src={bio?.image?imgURL+bio?.image:placeHolder} alt={bio?.full_name} class="curve p-5"/>
            </div>
        <div><h1>{bio?.full_name}</h1></div>
  <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(bio?.description)}}></div>
</div>
        </div>
    )
}

export default VisitingJudgesBio

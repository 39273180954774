import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllEvents } from "../../api";
import logo from "../../assets/Images/rottweiler-logo.png";
import VerticalAdverts from "../../components/verticalAdverts";
import { GlobalContext } from "../../context/store";
import Sidebar_Home from "../../layouts/SideNavigation_Home";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Events = () => {
  const { user } = useContext(GlobalContext);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      const res = await getAllEvents();
      const dateData = []
      var today = new Date();
      res.forEach(function (res) {
        var end_date = new Date(res.end_date);
        if (today < end_date) {
          dateData.push(res);
        }
      });
      // setEvents(res);
      setEvents(dateData);
      setLoading(false);
    };

    fetchEvents();
  }, []);

  return (
    <div className="row  mx-auto p-2 mt-5  bg-white rounded shadow">
      <div className="col-md-2">
        {!user ? <Sidebar_Home /> : <Sidebar_Member />}
      </div>
      <div className="col-md-8">
        <div className="p-2 ">
          <h1>PRC EVENTS</h1>
          <div className="row">
            {loading
              ? [0, 1, 2].map((a, ind) => (
                <div className="col-md-4 mb-3 pb-2" key={ind}>
                  <Skeleton height={30} className="mb-2" />
                  <Skeleton height={20} count={3} width={"60%"} />
                  <Skeleton height={25} width={"70%"} />
                  <div className="row justify-content-start mt-3">
                    <div className="col-md-12">
                      <Skeleton height={40} className=" btn" />
                    </div>
                  </div>
                </div>
              ))
              : events &&
              events
                ?.reduce((arr, item) => {
                  const filtered = arr.filter((i) => i["id"] !== item["id"]);
                  return [...filtered, item];
                }, [])
                .map(
                  ({
                    title,
                    start_date,
                    venue,
                    club_judge,
                    end_date,
                    friendly_URL,
                    visit_judge,
                  }) => (
                    <div className="col-md-4">
                      <div className="mt-4">
                        <div className="card bg-light shadow-sm">
                          <div className="card-body">
                            <h5 className="fw-bold my-3">{title}</h5>
                            <span className="d-block my-2">
                              <label className="fw-bold mx-2">Date:</label>
                              {new Date(start_date).toDateString()}
                            </span>
                            <span className="d-block my-2">
                              <label className="fw-bold mx-2">Venue:</label>{" "}
                              {venue === !null ? venue : ""}
                            </span>
                            <span className="d-block my-2">
                              <label className="fw-bold mx-2">
                                Judge(s):
                              </label>
                              {visit_judge
                                ? visit_judge
                                : "" + club_judge
                                  ? club_judge
                                  : ""}
                            </span>

                            {new Date(end_date) <= new Date() ? (
                              <span className="bg-danger rounded w-100 text-light px-2 m-2 shadow-sm">
                                Entries Are Closed
                              </span>
                            ) : user ? (
                              <Link
                                to={`/member/show-entry/${friendly_URL || "#"
                                  }`}
                                className="btn btn-sm btn-success w-100 shadow-sm"
                              >
                                Entries are Open
                              </Link>
                            ) : null}
                            <a
                              href="#"
                              className="btn btn-primary w-100 shadow-sm btn-sm mt-2"
                            >
                              View Catalog
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
          </div>
        </div>
      </div>
      <div className="col-md-2">
        <VerticalAdverts />
      </div>
    </div>
  );
};

export default Events;

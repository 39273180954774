import React from 'react'
import Select from "react-select";

export const CreateHDED = () => {
    return (
        <div>
        <h3>HD/ED ( REQUEST  )</h3>
        <div className="bg-light shadow-sm border">
          <div className=" w-50 p-3">
          <div className="">
                <label htmlFor="Dog" className="fw-bold">Dog  </label>
                <Select options={[{label:"Male",value:"Male"},{label:"Female",value:"Female"}]} />
                </div>
            <button className="shadow-sm  btn btn-danger"> <i className="far fa-paper-plane"></i> Send</button>
          </div>
        </div>
      </div>
    )
}

export default CreateHDED;
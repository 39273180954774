import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./style/index.css";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "./context/store";

import NavigationBar from "./layouts/NavigationBar";
import Sidebar from "./layouts/SideNavigation";
import sideimg from "./assets/Images/adrk-member-de-188x500.png";
import logo from "./assets/Images/website_logo_large-300x241.png";

import Footer from "./layouts/Footer";
import { getAlldogs, getDogById } from "./api";
import Header from "./layouts/Header";
import DogLayout from "./layouts/DogLayout";
import { Route, Routes } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import VirtualBreeding from "./pages/Dogs/Details/VirtualBreeding";
import BasicData from "./pages/Dogs/Details/BasicData";
import Siblings from "./pages/Dogs/Details/Siblings";
import Progeny from "./pages/Dogs/Details/Progeny";
import Padigree from "./pages/Dogs/Details/Padigree";
import Dogs from "./pages/Dogs";
import Kennels from "./pages/Kennels";
import Members from "./pages/Members";
import KennelDetails from "./pages/Kennels/KennelDetails";
import Login from "./pages/Auth/Login";
import MembersProfile from "./pages/Members/MemberProfile";
import Profile from "./pages/Profile";
import MYProfile from "./pages/Profile/my-profile";
import StudCertificates from "./pages/StudCertificates";
import CreateStudCertificate from "./pages/StudCertificates/create";
import LitterInspection from "./pages/LitterInspection";
import LitterRegistration from "./pages/LitterRegistration";
import HDEDLIST from "./pages/hdEdRequest";
import LeaseTransfer from "./pages/LeaseTransfer";
import CreateLitterInspection from "./pages/LitterInspection/create";
import CreateLitterRegistration from "./pages/LitterRegistration/create";
import CreateHDED from "./pages/hdEdRequest/create";
import CreateLeaseTransfer from "./pages/LeaseTransfer/create";
import DogEntries from "./pages/DogEntries";
import NewsAndUpdates from "./pages/News_&_Updates";
import BalanceStatment from "./pages/BalanceStatment";
import ShowEntries from "./pages/ShowEntries";
import Home from "./pages/Home";
import Register from "./pages/Register";
import Events from "./pages/Events";
import ShowResultsLinsting from "./pages/ShowResultsListing";
import ShowResults from "./pages/ShowResults";
import PostDetails from "./pages/Blog/Details";
import TheTeam from "./pages/About/TheTeam";
import TeamBio from "./pages/About/TheTeam/Details";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Breeds from "./pages/Breed";
import BreedDetails from "./pages/Breed/Details";
import DogOwners from "./pages/LeaseTransfer/owners";
import About from "./pages/About";
import SubscriptionAndFeeStructure from "./pages/About/SubscriptionAndFeeStructure";
import RulesAndRegulations from "./pages/About/RulesAndRegulations";
import AboutClub from "./pages/About/AboutClub";
import News from "./components/news";
import VisitigJudges from "./pages/About/VisitingJudges";
import Judges from "./pages/About/Judges";
import JudgesBio from "./pages/About/Judges/Details";
import VisitingJudgesBio from "./pages/About/VisitingJudges/Details";
import Rule from "./pages/About/RulesAndRegulations/Rule";
import ShowEntryForm from "./pages/ShowEntries/EntryForm";
import FullPadigree from "./pages/Dogs/Details/FullPadigree";
import StudCertificateDetails from "./pages/StudCertificates/StudCertificateDetails";
import RecordsOfRecentMatings from "./pages/RecordsOfRecentMatings";
import Navbarmenu from "./layouts/NAvBar-2";
import AboutTheBreed from "./pages/About/AboutTheBreed";
import AboutTheBreedDetails from "./pages/About/AboutTheBreed/Details";
import LitterRegistrationDetails from "./pages/LitterRegistration/Details";
import MobileBottomTabs from "./layouts/MobileBottomTabs";
import MobileTopBar from "./layouts/MobileTopBar";
import IdleTimer from "./IdleTimer";
import "animate.css/animate.min.css";
import ZTP from "./pages/Dogs/Details/ZTP";

function App() {
  const bounce = cssTransition({
    enter: "animate__animated animate__bounceInRight",
    exit: "animate__animated animate__bounceOut",
  });

  const { setAuthUser, user, isSearch, isMobileMenu } =
    useContext(GlobalContext);
  const navigate = useNavigate();
  useEffect(() => {
    setAuthUser(JSON.parse(localStorage.getItem("user")));
  }, []);

  // Usman's session work
  useEffect(() => {
    if (user) {
      const timer = new IdleTimer({
        timeout: 900, //expire after 10 seconds
        onTimeout: () => {
          setAuthUser(null);
          localStorage.removeItem("user");
          navigate("/");
        },
        onExpired: () => {
          // do something if expired on load
          console.log("Shouldn't run anyways.");
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [user]);
  // Usman's session work
  return (
    <div
      className="App background-main "
      style={
        isSearch || isMobileMenu
          ? { height: "100vh", overflow: "hidden" }
          : { height: "100%", overflow: "hidden" }
      }
    >
      <MobileTopBar />
      <Navbarmenu />
      <div className="main-container " style={{ minHeight: "83vh" }}>
        <Header />
        <Routes>
          {/* <Route path="*" element={<h1>Page not found</h1>}/> */}
          <Route path="/prc-database" element={<Dogs />} />
          <Route path="*" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/become-a-member" element={<Register />} />
          <Route
            path="/records-of-recent-matings"
            element={<RecordsOfRecentMatings />}
          />
          <Route path="/show-results" element={<ShowResultsLinsting />} />
          <Route path="/show-results/:slug" element={<ShowResults />} />
          <Route path="/events" element={<Events />} />
          <Route
            path="dog/virtualbreeding/:slug"
            element={<VirtualBreeding />}
          />
          <Route path="dog/bio/:slug" element={<BasicData />} />
          <Route path="dog/ztp/:slug" element={<ZTP />} />
          <Route path="dog/siblings/:slug" element={<Siblings />} />
          <Route path="dog/progeny/:slug" element={<Progeny />} />
          <Route path="dog/pedigree/:slug" element={<Padigree />} />
          <Route path="dog/full-pedigree/:slug" element={<FullPadigree />} />
          <Route path="/prc-database" element={<Dogs />} />
          <Route path="/kennels-directory" element={<Kennels />} />
          <Route path="/kennels-directory/:id" element={<KennelDetails />} />
          <Route path="/members-directory" element={<Members />} />
          <Route path="/member-profile/:slug" element={<MembersProfile />} />
          <Route path="/ownership/:slug" element={<DogOwners />} />

          <Route path="/about" element={<About />}>
            <Route path="/" element={<AboutClub />} />
            <Route path="news-and-updates">
              <Route path="/" element={<NewsAndUpdates />} />
              <Route path="/:slug" element={<PostDetails />} />
            </Route>
            <Route
              path="subscription"
              element={<SubscriptionAndFeeStructure />}
            />
            <Route path="the-breed" element={<AboutTheBreed />} />
            <Route path="the-breed/:slug" element={<AboutTheBreedDetails />} />

            <Route path="become-a-member" element={<Register />} />
            <Route path="prc-team">
              <Route to="/" element={<TheTeam />}></Route>
              <Route path="/:slug" element={<TeamBio />} />
            </Route>
            <Route path="visiting-judges">
              <Route to="/" element={<VisitigJudges />}></Route>
              <Route path="/:slug" element={<VisitingJudgesBio />} />
            </Route>
            <Route path="prc-judges">
              <Route to="/" element={<Judges />}></Route>
              <Route path="/:slug" element={<JudgesBio />} />
            </Route>
          </Route>

          <Route path="/rules" element={<RulesAndRegulations />}>
            <Route path="/:slug" element={<Rule />} />
          </Route>

          <Route path="/blog">
            <Route path="/:slug" element={<PostDetails />} />
          </Route>

          {/* <Route path="/breeds">
            <Route path="/" element={<Breeds />}></Route>
            <Route path="/:slug" element={<BreedDetails />} />
          </Route> */}

          {user ? (
            <Route path="/member" element={<Profile />}>
              <Route path="stud-certificates" element={<StudCertificates />} />
              <Route
                path="stud-certificates/:id"
                element={<StudCertificateDetails />}
              />
              <Route
                path="stud-certificates/create"
                element={<CreateStudCertificate />}
              />
              <Route path="my-profile" element={<MYProfile />} />
              <Route path="request-for-hd-ed" element={<HDEDLIST />} />
              <Route path="request-for-hd-ed/create" element={<CreateHDED />} />
              <Route path="ownership-transfer" element={<LeaseTransfer />} />
              <Route
                path="ownership-transfer/create"
                element={<CreateLeaseTransfer />}
              />
              <Route path="litter-inspection" element={<LitterInspection />} />
              <Route
                path="litter-inspection/create"
                element={<CreateLitterInspection />}
              />

              <Route
                path="litter-registration"
                element={<LitterRegistration />}
              />
              <Route
                path="litter-registration/:id"
                element={<LitterRegistrationDetails />}
              />
              <Route
                path="litter-registration/create"
                element={<CreateLitterRegistration />}
              />
              <Route path="my-show-survey-entries" element={<ShowEntries />} />
              <Route path="show-entry/:slug" element={<ShowEntryForm />} />
              <Route path="my-dog-entries" element={<DogEntries />} />
              <Route path="profile-news-updates">
                <Route path="/" element={<NewsAndUpdates />} />
                <Route path="/:slug" element={<PostDetails />} />
              </Route>
              <Route
                path="member-account-statement"
                element={<BalanceStatment />}
              />
            </Route>
          ) : null}
        </Routes>
      </div>
      <Footer />
      <ToastContainer
        // position="top-center"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover

        className="toast-containerr"
        transition={bounce}
        hideProgressBar
        autoClose={false}
        draggable={false}
        style={{
          margin: "auto",
          left: "0%",
          top: "45%",
        }}
      />{" "}
      <MobileBottomTabs />
    </div>
  );
}
export default App;

import React from 'react'
import { Outlet } from 'react-router'
import Sidebar_About from '../../layouts/SideNavigation_About'

const About = () => {
    return (
        <div className="rounded shadow mt-5 bg-white">
            <div className="row">
                <div className="col-md-3">
                    
                <Sidebar_About/>
                </div>
                <div className="col-md-8  rounded py-5">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default About    

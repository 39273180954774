import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router'
import { getAboutBreedBySlug } from '../../../../api'

const AboutTheBreedDetails = () => {

    const [data,setData]=useState([])

    const {slug}=useParams()

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getAboutBreedBySlug(slug);
            setData(res&&res[0])
        }
        fetchData()
    },[slug])


    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }

    return (
        <div>
            <h1 className=" txt-primary my-2 p-2">{data&&data?.title}</h1>
            <div className="p-2 bg-light rounded shadow-sm my-2" style={{fontSize:18}} dangerouslySetInnerHTML={{__html: convertHTMLEntity(data&&data?.content)}}></div>
            {/* <div>{data&&data?.content}</div> */}
        </div>
    )
}

export default AboutTheBreedDetails;

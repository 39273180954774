import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../context/store'
import MobileSearch from '../MobileSearch'
import MobileSideNav from '../MobileSideNav'

const MobileBottomTabs = () => {
    const {setMobileMenu,isMobileMenu,setSearch,isSearch}=useContext(GlobalContext)
 
    return (
        <div>
  <div className='container-fluid d-flex justify-content-center align-items-center'>
            <div className='bg-white shadow w-100 p-2 mobile-tabs-main container'>
            <ul className='nav-tabs-list d-flex align-items-center justify-content-around'>
                <li className='nav-tabs-item d-flex flex-column align-items-center justify-content-center'>
                    <Link className='nav-tabs-link'
                    onClick={()=>{
                        setMobileMenu(false)
                        setSearch(false)
                    }}
                    to="/">
                <i class="fas fa-home d-block"></i>
                    <span>
                      Home
                    </span> 
                    </Link>
                </li>
                <li className='nav-tabs-item '>
                    <Link onClick={()=>{
                         setMobileMenu(false)
                        setSearch(true)}} className='nav-tabs-link' to="#">
                    <i class="fas fa-search text-center"></i>
                    <span className='d-block'>
                        Search
                        </span>
                    </Link>
                </li>
                <li className='nav-tabs-item '>
                    <Link 
                     onClick={()=>{
                        setMobileMenu(false)
                        setSearch(false)
                    }}
                    className='nav-tabs-link' to="/prc-database">
                    <i class="fas fa-dog"></i>
                        <span className='d-block'>
                         Dogs
                        </span>
                            
                    </Link>
                </li>
                <li className='nav-tabs-item '>
                    <Link className='nav-tabs-link' to="#" onClick={()=>{
                        setSearch(false)
                        setMobileMenu(true)

                        }}>
                <i class="fas fa-bars"></i>
                <span className='d-block'>Menu</span>
                    </Link>
                </li>
            </ul>
        </div>
        </div>
        <MobileSideNav />
        
            <MobileSearch/>
    
        </div>
      
    )
}

export default MobileBottomTabs

import axios from "axios";
const baseURL='https://api.rottweiler.pk'
// const baseURL='http://localhost:5000'
const baseURL_PHP='https://admin.rottweiler.pk/api';
// const baseURL_PHP='http://localhost/prc/api';

export const getAlldogs = async () => {
  try {
    const res = await axios.get(baseURL+"/dogs");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getLimits = async () => {
  try {
    const res = await axios.get(baseURL_PHP+"/limits");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const signIn = async (user) => {
  const body={username:user?.username,password:user?.password}
  try {
    const res = await axios.post(baseURL+"/login",user);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDogIdBySlug = async (slug) => {
  try {
    const res = await axios.get(baseURL+"/dogID/"+slug);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllFemaledogs = async () => {
  try {
    const res = await axios.get(baseURL+"/dog_female");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMaledogs = async () => {
  try {
    const res = await axios.get(baseURL+"/dog_male");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getDogById = async (dog_id) => {
  try {
    const res = await axios.get(baseURL+`/dog/${dog_id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllDogsByBreed = async (breedId) => {
  try {
    const res = await axios.get(baseURL+`/dogs/${breedId}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getUserById = async (id) => {
  try {
    const res = await axios.get(baseURL+`/user/${id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getMemberBySlug = async (slug) => {
  try {
    const res = await axios.get(baseURL+`/userbyslug/${slug}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getKennelsByOwner = async (id) => {
  try {
    const res = await axios.get(baseURL+`/kennels/by_owner/${id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
   return []
  }
};
export const getAllDogsByOwner = async (id) => {
  try {
    const res = await axios.get(baseURL+`/dogs/dy_owners/${id}`);
    // console.log(res.data,"......res.data.......")
    return res.data;
  } catch (err) {
    return [];
  }
};

export const getAllSiblings = async ({sire,dam,dog_id}) => {
  let body={dog_id:dog_id,sire:sire,dam:dam}
  try {
    const res = await axios.post(baseURL+`/siblings/get`,body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getPadigree = async (dog_id) => {
  try {
    const res = await axios.get(baseURL+`/paddigery/${dog_id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllPuppies = async ({dam,sire}) => {
  var body={sire:sire,dam:dam};
  try {
    const res = await axios.post(baseURL+`/pupplies_by_sire_dam`,body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProgenyBySire = async (dog_id) => {
  try {
    const res = await axios.get(baseURL+`/sire_progeny/${dog_id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getProgenyByDam = async (dog_id) => {
  try {
    const res = await axios.get(baseURL+`/dam_progeny/${dog_id}`);
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllMembers = async () => {
  try {
    const res = await axios.get(baseURL+"/members");
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllkennels = async () => {
  try {
    const res = await axios.get(baseURL+"/kennels");
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const dogSearch = async (keywords) => {
  const body = { keyword: keywords };
  try {
    const res = await axios.post(baseURL+"/search_dogs", body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const kennelsearch = async (keywords) => {
  const body = { keyword: keywords };
  try {
    const res = await axios.post(baseURL+"/kennels/search", body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const memberSearch = async (keywords) => {
  const body = { keyword: keywords };
  try {
    const res = await axios.post(baseURL+"/members/search", body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getKennelDetailsById = async (id) => {

  try {
    const res = await axios.get(`${baseURL}/kennels/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllStudCertificates = async (id) => {
  try {
    const res = await axios.get(baseURL+"/stud_certificates-list/"+id);
    console.log(res.data,"getAllStudCertificates")
    return res.data;
  } 
  catch (err) {
    console.log(err);
  }
};

export const postStudNOC = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(baseURL_PHP+"/issue-stud-noc", requestOptions);
    
    return res.data;
  } 
  catch (err) {
    console.log(err);
  }
};

export const getStudCertificateById = async (id)=> {
  try {
    const res = await axios.get(baseURL+"/stud_certificate/"+id);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getStudsByOwnerId = async (id)=> {
  try {
    const res = await axios.get(baseURL+"/studs_by_owner/"+id);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getDamsByOwnerId = async (id)=> {
  try {
    const res = await axios.get(baseURL+"/dams_by_owner/"+id);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const removeDogOwnership = async (body) => {
  try {
    const res = await axios.post(baseURL+"/members/remove-ownership/",body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const transferDogOwnership = async (body) => {
  try {
    const res = await axios.post(baseURL+"/members/transfer-ownership/",body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const leaseDogOwnership = async (body) => {
  try {
    const res = await axios.post(baseURL+"/members/lease-ownership/",body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllEvents = async () => {
  try {
    const res = await axios.get(baseURL+"/events/");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getEventBySlug = async (slug) => {
  try {
    const res = await axios.get(baseURL+"/event/"+slug);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllGroups = async () => {
  try {
    const res = await axios.get(baseURL+"/dog-groups/");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllClasses = async (body) => {
  try {
    const res = await axios.get(baseURL+"/dog-classes");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getEventID = async (slug) => {
  try {
    const res = await axios.get(baseURL+"/eventsId/"+slug);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getEventByID = async (id) => {
  try {
    const res = await axios.get(baseURL+"/events/"+id);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllBreedsByGroup = async (id) => {
  try {
    const res = await axios.get(baseURL+"/breedbygroup/"+id);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getShowResults = async (body) => {
  try {
    const res = await axios.post(baseURL+"/show-results-back/",body);
    return res.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
// export const getMemberAccounts = async (id) => {
//   try {
//     const res = await axios.get(baseURL+"/members-accounts/"+id);
//     return res.data;
//   } catch (err) {
//     console.log(err);
//     return res.data;
//   }
// };
export const getMemberAccountsByDate = async (body) => {
  try {
    const res = await axios.post(baseURL+"/members-accountsbydate/",body);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllBlogs = async () => {
  try {
    const res = await axios.get(baseURL+"/blogs");
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getBlogPostBySlug = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/blogs/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getTeam = async () => {
  try {
    const res = await axios.get(`${baseURL}/team/`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getTeamBioBySlug = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/team/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllBreeds = async () => {
  try {
    const res = await axios.get(`${baseURL}/breeds`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getBreedBySlug = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/breed/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getBreedIdBySlug = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/breedId/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
  }
export const getAllDogsEntered = async (id) => {
  try {
    const res = await axios.get(`${baseURL}/dogs_entered/${id}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllDogsTransfered = async (id) => {
  try {
    const res = await axios.get(`${baseURL}/dogs_transfered/${id}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllDogOwners = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/dog-ownners/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllVisitingJudges = async () => {
  try {
    const res = await axios.get(`${baseURL}/visiting-judges`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllJudges = async () => {
  try {
    const res = await axios.get(`${baseURL}/judges`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getJudgesBio = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/judges/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getVisitingJudgesBio = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/visiting-judges/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getFeeStructure = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/fee-structure`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllRules = async () => {
  try {
    const res = await axios.get(`${baseURL}/rules`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getRule = async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/rule/${slug}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const register = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/membership-form-save`, requestOptions)
    console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const userlogin = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/member-login`, requestOptions)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getMemberAccounts = async (id) => {
  try {
    const res = await fetch(`${baseURL_PHP}/members-wallet/`+id);
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createlitinsp = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/create-litter-inspection`, requestOptions)
    // console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const TransferLeaseApi = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/ownership-transfer-lease-request`, requestOptions)
    // console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const saveLitterRegistration = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/create-litter-registration`, requestOptions)
    // console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const showDogSave = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/dogs_entery-save/`,body);
    console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const updateUserProfile = async (id,body) => {
  try {
    var requestOptions = {
      method: 'POST',
      body: body,
      redirect: 'follow'
    };
   const res=await fetch(`${baseURL_PHP}/account-update/${id}`, requestOptions)
   return res;  

  } catch (err) {
    console.log(err);
  }
};
export const updateDogrofile = async (slug,body) => {
  try {
    var requestOptions = {
      method: 'POST',
      body: body,
      redirect: 'follow'
    };
    const res=await fetch(`${baseURL_PHP}/change-pic/${slug}`, requestOptions)
    return res;  

  } catch (err) {
    console.log(err);
  }
};

export const getCatalogId = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/get_catalog_id/`,body);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAccountHead = async (userId) => {
  try {
    const res = await axios.get(`${baseURL}/user-account-head/${userId}`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllCities = async () => {
  try {
    const res = await axios.get(`${baseURL}/cities`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getStudCertificateFee = async () => {
  try {
    const res = await axios.get(`${baseURL}/stud_certificate_fee`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
// export const createStudCertificate = async (body) => {
//   try {
//     const res = await axios.post(`${baseURL}/stud_certificate_save`,body);
//     console.log(res)
//     return res.data;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const createStudCertificate = async (body) => {
  var requestOptions = {
    method: 'POST',
    body: body,
    redirect: 'follow'
  };
  try {
    const res = await fetch(`${baseURL_PHP}/create-stud-certificate`, requestOptions)
    console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const StudCertificateBySireDam = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/stud_by_sire_dam`,body);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const LatestStudCertificateBySireDam = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/stud_latest_by_sire_dam`,body);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const saveLitterInspection = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/litter_inspect_save`,body);
    console.log(res)
    return res;
  } catch (err) {
    console.log(err);
  }
};
// export const saveLitterRegistration = async (body) => {
//   try {
//     const res = await axios.post(`${baseURL}/litter_register_save`,body);
//     console.log(res)
//     return res;
//   } catch (err) {
//     console.log(err);
//   }
// };

// export const saveLitterRegistrationTransactions= async (body) => {
//   try {
//     const res = await axios.post(`${baseURL}/litter_register_transactions_save`,body);
//     console.log(res)
//     return res;
//   } catch (err) {
//     console.log(err);
//   }
// };

export const getAllLitterInspections = async (id) => {
  try {
    const res = await axios.get(`${baseURL}/litter_inspections/${id}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLitterDetailsById = async (id) => {
  try {
    const res = await axios.get(`${baseURL}/litter_details_by_id/${id}`);
    console.log(res.data,"IDDDDDDD")
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLitterPuppiesById = async (id) => {
  try {
    const res = await axios.get(`${baseURL}/litter_puppies_by_litter_id/${id}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLitterInspectionBySireDam = async (body) => {
  try {
    const res = await axios.post(`${baseURL}/litter_inspection_by_sire_dam`,body);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLitterID = async () => {
  try {
    const res = await axios.get(`${baseURL}/max_litter_id`);
    console.log(res.data)
    return res.data[0]?.id;
  } catch (err) {
    console.log(err);
  }
};
export const getLitterRegFee = async () => {
  try {
    const res = await axios.get(`${baseURL}/litter_reg_fee`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getLittersByOwner = async (ownerId) => {
  try {
    const res = await axios.get(`${baseURL}/litters_by_ownerId/${ownerId}`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getRecentMatings = async () => {
  try {
    const res = await axios.get(`${baseURL}/recent_matings`);
    console.log(res.data)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAboutBreed= async () => {
  try {
    const res = await axios.get(`${baseURL}/about-the-breed`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAboutBreedBySlug= async (slug) => {
  try {
    const res = await axios.get(`${baseURL}/about-the-breed/${slug}`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAboutClub= async () => {
  try {
    const res = await axios.get(`${baseURL}/about-club-content`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getKennelOwners= async (id) => {
  try {
    const res = await axios.get(`${baseURL}/kennelOwners/${id}`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllFooterSettings= async () => {
  try {
    const res = await axios.get(`${baseURL}/footer-settings`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllFooterLinks = async () => {
  try {
    const res = await axios.get(`${baseURL}/footer-links`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllFooterImages = async () => {
  try {
    const res = await axios.get(`${baseURL}/footer-images`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getHorizontalAds = async () => {
  try {
    const res = await axios.get(`${baseURL}/ads-horizontal`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const getGBW = async () => {
  try {
    const res = await axios.get(`${baseURL}/group_breed_warden`);
    console.log(res)
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import {
  getAllBreedsByGroup,
  getAllClasses,
  getAllGroups,
  getEventByID,
  getEventID,
  getShowResults,
} from "../../api";
import Badge from "../../components/badge";
import { toast } from "react-toastify";
import logo from '../../assets/Images/rottweiler-logo.png'
import VerticalAdverts from "../../components/verticalAdverts";
import Sidebar_Home from "../../layouts/SideNavigation_Home";

const ShowResults = () => {
  const { slug } = useParams();
  const [eId, setEid] = useState(null);
  const [event, setEvent] = useState(null);
  // const [breeds,setBreeds]=useState([])
  // const [groups,setGroups]=useState([])
  const [classes, setClasses] = useState([]);
  // const [breed,setBreed]=useState(0)
  // const [group,setGroup]=useState(0)
  const [classname, setClass] = useState(0);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [gander, setGander] = useState("");

  const notify = () =>
    toast.info("Please complete the results parameters!", {
      // position: "top-center",
      // autoClose: 3000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,

      style: { zIndex: "10000000" },

    });

  useEffect(() => {
    const fetchData = async () => {
      const res = await getEventID(slug);
      setEid(res && res[0]?.id);
      const res_ = await getEventByID(res && res[0]?.id);
      setEvent(res_ && res_[0]);
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchGroups = async () => {
      // const res=await getAllGroups()
      // setGroups(res&&res)
      const res_ = await getAllClasses({ eventType: "'" + event?.type + "'" });
      setClasses(res_ && res_);
    };
    fetchGroups();
  }, [event]);

  // useEffect(() => {
  //     const fetchBreeds=async()=>{
  //         const res=await getAllBreedsByGroup(group)
  //         setBreeds(res&&res)
  //     }
  //     fetchBreeds()
  // }, [group])


  useEffect(()=>{
    fetchResults()
  },[classname])

  const fetchResults = async () => {
    setLoading(true);
    if (eId) {
      let body = { showId: eId, classId: classname?.id };
      const res = await getShowResults(body);
      setResults(res && res);
      setGander("");
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const filterByGander = (g) => {
    if (g == "") {
      return results;
    }
    const d = results?.filter((result) => result.sex == g);
    return d;

  };

  return (
    <div className="row  bg-white rounded shadow">
        <div className="col-md-2">
        {/* <div className="logo">
                 <Link to="/">
                <img src={logo}/>
                </Link>
            </div>
            <hr/> */}
          {/* <div  className="border" style={{height:'500px'}}>
          <img src="#"  alt="advertising banner"/>
          </div> */}
          <Sidebar_Home/>
        </div>
      <div className="col-md-8">
        <div className="mt-5 container" style={{minHeight:'500px'}}>
          <div className="d-flex justify-content-between flex-wrap align-items-center">
            <h2 className="p-2">{event && event?.title}</h2>
            <Link className="butn shadow-sm rounded" style={{backgroundColor:'#ff3434', color:'white'}} to="/events">
              Back to Events
            </Link>
          </div>
          <div className="mt-3 d-flex ">
            <h5 className="mr-4">
              <b>Date:</b> {new Date(event && event?.start_date).toDateString()}
            </h5>
            {event && event?.venue ? (
              <h5 className="mx-4">
                <b>Venue:</b> {event && event?.venue}
              </h5>
            ) : null}
            {event && event?.judge ? (
              <h5 className="mx-4">
                <b>Judge(s):</b>
                {event && event?.judge}
              </h5>
            ) : null}
          </div>
          <hr />
          <div className="d-flex flex-wrap my-4">
            {classes?.map((item) => (
              <button
                onClick={(e) => {
                  setClass(item);
                  
                }}
                className="btn butn-grey my-2 shadow-sm border mx-2"
              >
                {item?.class}
              </button>
            ))}
          </div>
          {!loading&&classname?(
            <div className="my-5">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="">{classname?.class}</h4>
                <div className="d-flex justify-content-around w-25 my-4">
                  <div class="form-check ">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="gander"
                      id="male"
                      value="Male"
                      onClick={(e) => setGander(e.target.value)}
                    />
                    <label class="form-check-label" for="male">
                      Male
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      checked
                      name="gander"
                      id="female"
                      value="Female"
                      onClick={(e) => setGander(e.target.value)}
                    />
                    <label class="form-check-label" for="female">
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <table class="table  shadow p-2 rounded">
                <thead class="shadow-sm bg-dark text-light ">
                  <tr>
                    <th scope="col">Grading</th>
                    <th scope="col">Rank</th>
                    <th scope="col">Name of Dog</th>
                    <th scope="col">Name of Sire</th>
                    <th scope="col">Name of Dam</th>
                    <th scope="col">Owner</th>
                  </tr>
                </thead>
                <tbody>
                  {results?.length? 
                    filterByGander(gander)
                   
                      ?.map((item) => (
                        <tr
                          className={item?.seat == 1 ? "bg-light  border" : ""}
                        >
                          <th scope="row">{item?.grading || "-"}</th>
                          <td>
                            {item?.seat === 1 ? (
                              <Badge title="Class Winner" />
                            ) : item?.seat === 2 ? (
                              "Second"
                            ) : item?.seat === 3 ? (
                              "Third"
                            ) : (
                              "-"
                            )}
                          </td>
                          <td>
                            <Link style={{textDecoration:'none'}}
                            to={item?.dog_url?"/dog/bio/"+item?.dog_url:"#"}>
                            {item?.dog_name || "_"}
                            </Link>
                          </td>
                          <td><Link  style={{textDecoration:'none'}} 
                          to={item?.sire_url?"/dog/bio/"+item?.sire_url:"#"}>
                            {item?.sire_name || "_"}
                          </Link></td>
                          <td><Link  style={{textDecoration:'none'}}
                           to={item?.dam_url?"/dog/bio/"+item?.dam_url:"#"}>{item?.dam_name || "-"}</Link>
                           </td>
                          <td>
                            
                            {item?.owners || "_"}</td>
                        </tr>
                      ))
                   : 
                    <tr>
                      <td colSpan="6" align="center">
                        <h4 class="my-2">No result(s) found</h4>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-md-2">
        <VerticalAdverts/>
      </div>
    </div>
  );
};

export default ShowResults;

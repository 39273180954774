import React, { useContext, useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { dogSearch } from '../../api'
import { GlobalContext } from '../../context/store'
import Highlight from "react-highlighter";
import { Scrollbars } from "react-custom-scrollbars";
const MobileSearch = () => {

const{isSearch,setSearch}=useContext(GlobalContext)   
const [isMenu,setIsMenu]=useState(false)
const [keyword, setKeyword] = useState("");
const [results, setResults] = useState([{}]);

const getUniqueSearch = (arr) => {
  let newArr=Object.values(
    arr.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
  );
 return newArr
  
};

useEffect(async () => {
  if (keyword && keyword.length >= 3) {
    const res = await dogSearch(keyword);

    setResults(getUniqueSearch(res));
    console.log(res, "res");
  }
  if (keyword == "") {
    setResults([]);
  }
}, [keyword]);

const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

    return (
        <div className={`side-nav-main ${isSearch?'sidenav-show':'sidenav-hide'}`}>
            <div className='sidenav-header'>
                <div className='bg-white w-100 p-2 mobile-search d-flex justify-content-between align-items-center'>
                <input type="search" 
                onChange={(e)=>{setKeyword(e.target.value)}}
                placeholder='Search for Dog, KP, Microchip # ' className='form-control '/>
                    <span className='text-dark p-2'>
                    <i class="fas fa-search"></i>
                    </span>
                </div>
            </div>
            {
                   results ? 
                    <Scrollbars
                      style={{
                        position: "absolute",
                        height: '100vh',
                        zIndex: 10000,
                      }}
                    >
               { results?.map(({id,dog_name,image,friendly_URL})=>(
                    <Link onClick={()=>{setSearch(false)}} className='text-decoration-none mob-search-item' to={`/dog/bio/${friendly_URL}`}>
                    <div className='shadow-sm  m-3 d-flex justify-content-berween align-items-center p-3 '>
                       <div>
                           <img style={{maxHeight:50}} src={image?`https://admin.rottweiler.pk/public/dog_images/${id}/`+image:placeHolder}/>
                       </div>
                       <Highlight search={keyword}>
                                        {dog_name}
                                      </Highlight>
                    </div>
                    </Link>
                ))}
                </Scrollbars>
                :null
            }
        
           
        </div>
    )
}
export default MobileSearch

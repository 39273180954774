import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';
import logo from '../../assets/Images/rottweiler-logo.png'
const Sidebar_About = ({id}) => {
    return (
        <div className="side-menu bg-white pt-2 rounded position-sticky px-2" style={{maxWidth:'280px'}}>
            <div className="logo">
                <Link to="/">
                <img src={logo}/>
                </Link>
            </div>
            <hr/>
        <ul className="mx-2 p-2 rounded sider-bar-background">
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/about`}> About PRC </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`the-breed`}>About The Breed </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`subscription`}>Subscription & Fee Structure</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/rules/code-of-ethics`}> Rules & Regulations </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`become-a-member`}>Membership Application </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`PRC-team`}> The PRC Team</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`PRC-judges`}> PRC Judges</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`visiting-judges`}>Visiting Judges</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`news-and-updates`}> News & Updates </Link></li>
        </ul>
    </div>
    )
}

export default Sidebar_About

import React, { useEffect, useState } from 'react';
import { MDBDataTableV5,MDBBadge } from 'mdbreact';
import { Link } from 'react-router-dom';
import { getAllDogsTransfered, getAllStudCertificates } from '../../api';
import Loader from '../../components/loader';

export default function LeaseTransfer() {

  const [data,setData]=useState([])
  const [loading,setLoading]=useState(false)
  
  useEffect(() => {
    const fetchData=async()=>{
      setLoading(true)
      const user=JSON.parse(localStorage.getItem('user'))
      const res=await getAllDogsTransfered(user?.id)
      setData(res)
      setLoading(false)
    }
    fetchData()
  }, [])

  var datatable={
      columns: [
      {
        label: 'Date',
        field: 'created_at',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Name',
        },
      },
      {
        label: 'Dog',
        field: 'dog_name',
        width: 270,
      },
      {
        label: 'Owner',
        field: 'owner',
        sort: 'disabled',
        width: 150,
      },
    ],
    rows:data
  }

  const badgesData = {
    columns: [
      ...datatable.columns,
      {
        label: 'Start Date	',
        field: 'date_from',
        width: 200,
      },
      {
        label: 'End Date',
        field: 'date_to',
        sort: 'asc',
        width: 100,
      },
     
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ],
    rows: [
      ...datatable.rows.map((row, order) => ({
        ...row,
        status: (
          <MDBBadge style={{color:'black',backgroundColor:'lightgrey'}} pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            {row?.status}
          </MDBBadge>
        ),
        actions: (
          <MDBBadge  pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            <Link to={`/ownership/${row?.friendly_URL}`}><i style={{fontSize:18}} className="fas fa-eye"></i></Link>
          </MDBBadge>
        ),
        mating_date:(<span>{row?.mating_date?.slice(0,10)}</span>),
        created_at:(<span>{row?.created_at?.slice(0,10)}</span>)
      })),
    ],
  };

  return( 
      <>
      <div className="d-flex justify-content-between align-items-center">

      <h1>Transfer/Lease - List</h1>
      
      <Link to={`create`}><button className="btn btn-primary shadow">
        <i className="fas fa-plus-circle"></i>&nbsp;New</button>
        </Link>
      </div>
      {
        loading?
        <Loader size={50}/>:
        <MDBDataTableV5 className="shadow mt-2 p-2 rounded " 
        hover entriesOptions={[5,15,25,50,100,500]} entries={10}
        pagesAmount={10} data={badgesData} sortRows={['badge']} 
        searchTop searchBottom={false} materialSearch />
     

      }
       </>
  );
}

import React, { useEffect, useState } from 'react'
import { DOG_BREED } from '../constants'
import { Link } from 'react-router-dom';

const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"
const ProgenyChild = ({data}) => {
  const [dog,setDog]=useState({})
  useEffect(() => {
    setDog(data)
  },[data])

  const imageURL=`http://admin.rottweiler.pk/public/dog_images/${dog?.id}/`
    return (
      <div className="row">
        <div className="col-md-10">
          <div className="row py-2 mt-3 mx-0 sibblings-dog bg-white border rounded shadow-sm ">
            <div className="col-3">
              <img src={dog?.image?imageURL+dog?.image:placeHolder} className="border shadow-sm progeny-child-dog-img rounded" />
            </div>
            <div className="col-9 d-flex flex-column justify-content-center ">
              <div className="d-flex flex-column justify-content-between mx-2">
                <h4 className="txt-primary">
                  <Link to={`/dog/bio/${dog?.friendly_URL}`} style={{textDecoration:'none'}}>{dog?.dog_name}</Link></h4>
                  <span className="breed-kp mx-5">{dog?.is_champ == 'Yes' ? 'Champion' : ''}</span>
                <h6>
                  
                  <i className={`fas ${dog&&dog?.sex=="Female"?"fa-venus":dog&&dog?.sex=="Male"?"fa-mars":null} mx-2`}></i>
                  {dog.sex} | {"Rottweiler"}
                </h6>
                <div className="d-flex justify-content-between flex-wrap">
                  <span className="txt-primary">
                    <b>Whelped:</b>
                  </span>{" "}
                  <span>{dog?.dob?.slice(0,10)}</span>
                  <span className="text-warning">
                    <b>
                      <i>Registry:</i>
                    </b>
                  </span>{" "}
                  <span>{dog?.KP || dog?.regestration_no }</span>
                </div>
              </div>
            </div>
          </div>
         
        </div>
    </div>
    )
}

export default ProgenyChild

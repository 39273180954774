import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getBlogPostBySlug } from '../../../api'

const PostDetails = () => {
    const[post,setPost]=useState({})
    const {slug}=useParams()

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getBlogPostBySlug(slug)
            setPost(res&&res[0])
        }
        fetchData()
    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    
    return (
        <div className="news-details container mt-5 bg-white p-3 rounded shadow-sm">
            <h1 className="p-2">{post?.title}</h1>
            {/* <h6 className="p-2 fw-bold mb-4 mt-2">Posting Date:  {new Date(post?.publishing_date).toDateString()}</h6> */}
            <div className="p-3" dangerouslySetInnerHTML={{__html: convertHTMLEntity(post?.content)}}></div>
        </div>
    )
}

export default PostDetails

import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllEvents } from '../../api'
import logo from '../../assets/Images/rottweiler-logo.png'
import { GlobalContext } from '../../context/store'
import Sidebar_Home from '../../layouts/SideNavigation_Home'
import Sidebar_Member from '../../layouts/SideNavigation_Member'

const ShowResultsLinsting = () => {
    const [events, setEvents] = useState([])
    // const [end_Date, setEnd_Date] = useState([])
    const { user } = useContext(GlobalContext)

    useEffect(() => {

        const fetchEvents = async () => {
            const res = await getAllEvents()
            const dateData = []
            var today = new Date();
            res.forEach(function(res) {
                var end_date = new Date(res.end_date);
                if (today>end_date){
                    dateData.push(res); 
                  }
            });
            // console.log(dateData,"today")
            setEvents(dateData)

        }

        fetchEvents()
    }, [])

    return (
        <div className="row mx-auto p-2 mt-5  bg-white rounded shadow">
            <div className="col-md-2">
                {
                    !user ?
                        <Sidebar_Home />
                        : <Sidebar_Member />

                }
            </div>
            <div className="col-md-8 my-2">
                <div className="">
                    <div className="d-flex justify-content-between flex-wrap align-items-center">
                        <h1>PRC EVENTS' RESULT(S)</h1>
                        <Link className="butn shadow-sm rounded" style={{ backgroundColor: '#ff3434', color: 'white' }} to="/events">Back to Events</Link>
                    </div>
                    <div className="row">
                        {
                            events && events?.reduce((arr, item) => {
                                const filtered = arr.filter(i => i['id'] !== item['id']);
                                return [...filtered, item];
                            }, []).map(({ title, start_date, end_date, venue, club_judge, visit_judge, friendly_URL }) => (

                                <div className="col-lg-3 col-md-5" id="oldCard">
                                    <div className="mt-4">
                                        {/* <div className="card bg-light shadow-sm" style={{ width: "18rem" }}> */}
                                        <div className="card bg-light shadow-sm" >

                                            <div className="card-body" >
                                                <h5 className="fw-bold my-3">{title}</h5>
                                                <span className="d-block my-2"><label className="fw-bold mx-2">Date:</label>{start_date.slice(0, 10)}</span>
                                                <span className="d-block my-2"><label className="fw-bold mx-2">Venue:</label> {venue}</span>
                                                <span className="d-block my-2"><label className="fw-bold mx-2">Judge(s):</label>
                                                    {
                                                        club_judge ? club_judge : ""
                                                            + " " + visit_judge ? visit_judge : ""
                                                    }
                                                </span>

                                                <Link to={friendly_URL || "#"} className="btn btn-primary w-100 shadow-sm">Results</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>
            <div className="col-md-2">

            </div>
        </div>


    )
}

export default ShowResultsLinsting

import React, { useContext, useEffect, useState } from 'react'
import { getAllDogsEntered } from '../../api'
import {Link} from 'react-router-dom'
import { GlobalContext } from '../../context/store';
export const DogEntries = () => {

    const [data,setData]=useState([]);
    const [events,setEvents]=useState([]);
    const [loading,setLoading]=useState(false);
    const {user}=useContext(GlobalContext);
    
    useEffect(() => {
        const fetchData=async()=>{
            setLoading(false)
            const res=await getAllDogsEntered(user&&user?.id)
            setData(res&&res)
            setLoading(true)
        }
        fetchData()
    }, [])

    useEffect(() => {
        changeData()
    }, [data])

    const changeData=()=>{
        var dd=[];
        var d=Object.keys(data?.reduce((r,{title}) => (r[title]='', r) , {}))
        for(let a of d){
            dd.push({title:a,dogs:[]})
        }
        for(let i in dd){
            for(let d_ of data){
                if(d_?.title==d[i]){
                    dd[i]?.dogs?.push(d_)
                }
            }
        }
        setEvents(dd&&dd)
    }    

    
    return (    
            <div className="dog-entries-main py-4 px-2">
                <h1>Dog Enteries</h1>
                {
                    events?events?.map((event,ind)=>(
                        <div className="dog-entry " key={ind}>
                        <h5 className="p-2 bg-light rounded shadow-sm">{event?.title}</h5>
                        <button className="btn btn-info shadow-sm">View Catalog</button>
                       <div className='overflow-scroll'>
                       <table className="overflow-scroll table shadow-sm mt-2   p-2 w-100">
                        <tr className="bg-light p-2 mx-1 mt-2 rounded shadow-sm">
                            <th><span>Event Name</span></th>
                            <th><span>Dog Name</span></th>
                            <th><span>Class</span></th>
                            <th><span>Date of Event</span></th>
                            <th><span>Result</span></th>
                            <th><span>Certificates</span></th>
                        </tr>
                        {
                            event?.dogs?.map((item,_i)=>(
                                <tr className="p-2" key={_i}>
                                <td>{item?.title}	</td>
                                <td>{item?.dog_name}	</td>
                                <td>{item?.class_name}</td>
                                <td>{new Date(item?.start_date)?.toDateString()}</td>
                                <td>{item?.result || "-"}</td>
                                <td>{item?.result?<Link to="#" className="btn btn-sm  btn-info">Certificate</Link>: "-"}</td>
                            </tr>
                            ))
                        }
                        </table>  
                       </div>
                               
                    </div>
                    )):null
                }
                 
            </div>
    
    )
}

export default DogEntries;
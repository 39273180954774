import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllPuppies } from '../api'
import ProgenyChild from './progeny_child'

const ProgenyChildren = ({sire,dam}) => {
    const [puppies,setPuppies]= useState([])

    useEffect(async() => {
       const res=await getAllPuppies({sire:sire,dam:dam})
       setPuppies(res)
       console.log("puppies",puppies)
    }, [sire,dam])

    return (
        <div>
            {puppies&&puppies.map((puppie,_id)=>(
                <Link to={`/dog/bio/${puppie?.friendly_URL}`} style={{textDecoration:"none"}}>
                    <ProgenyChild data={puppie}/>
                </Link>
            ))}
            
        </div>
    )
}

export default ProgenyChildren

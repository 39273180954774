import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { createStudCertificate, 
         getAccountHead, 
         getAllFemaledogs, 
         getDogById, 
         getKennelsByOwner, 
         getMemberAccounts, 
         getStudCertificateFee, 
         getStudsByOwnerId,
         getLimits } from "../../api";
import Loader from "../../components/loader";
import StudDetails from "../../components/stud_details";
import { GlobalContext } from "../../context/store";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateStudCertificate = () => {
  const { user, club_fees } = useContext(GlobalContext);
  const [stud_fee, setFees] = useState(0);
  const [females, setFemales] = useState([])
  const [femaleId, setFemaleId] = useState(null)
  const [female, setFemaleData] = useState({})
  const [males, setMales] = useState([])
  const [kennel, setkennel] = useState({})
  const [femaleLimits, setFemLim] = useState({})
  const [maleLimits, setMaleLim] = useState({})
  const [maleId, setMaleId] = useState(null)
  const [male, setMaleData] = useState({})
  const [noc, setNoc] = useState(false)
  const [balance, setBalance] = useState(0)
  const [femaleMsg, setFemaleMsg] = useState('')
  const [maleMsg, setMaleMsg] = useState('')
  const [balMsg, setBalMsg] = useState('')
  const [matingMsg, setmatingMsg] = useState('')
  const [matingData, setMatingDate] = useState('')
  const [loading, setLoading] = useState(false)

  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const kenl = await getKennelsByOwner(user?.id)
      setkennel(kenl && kenl[0])
      const res = await getAllFemaledogs()
      setFemales(res && res)
      const res_ = await getStudsByOwnerId(user && user?.id)
      setMales(res_ && res_)

      set_fee();
      Balance();
      limits();
    }
    fetchData()
    setLoading(false);
  }, []);

  const set_fee = async () => {
    for (let i = 0; i < club_fees.length; i++) {
      if (club_fees[i].id == 53) {
        setFees(club_fees[i].option_value.toLocaleString());
      }
    }
  }

  const limits = async () => {
    const res = await getLimits();
      setMaleLim(res?.response?.lims?.male);
      setFemLim(res?.response?.lims?.female);
    

    // console.log(response, 'limits api response');
  }

  const Balance = async () => {
    const memberId = user?.account_id
    const response = await getMemberAccounts(memberId);
    response.json().then(balance => ({
      balance: balance,
      status: response.status
    })
    ).then(res => {
      if (res.balance.balance < 0) {
        setBalance(Math.abs(res.balance.balance));
      }
      else {
        setBalance(- res.balance.balance);
      }
    });
  }


  function monthDiff(d1, d2) {
    var months;
    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth();
    months += new Date(d2).getMonth();
    // const res=classes?.filter(({to_age,from_age})=>(months>=from_age && months<=to_age))
    return months
  }

  const validate = () => {
    var isValid = false;
    var femaleCheck = false;
    var maleCheck = false;
    var balanceCheck = false;
    var matingDataCheck = false;

    if (male && male?.dob && female && female?.dob) {
      const maleAge = parseInt(monthDiff(male && male?.dob, matingData))
      const femaleAge = parseInt(monthDiff(female && female?.dob, matingData))

      ///Male Age checks
      if (maleAge < maleLimits.min) {
        setMaleMsg("Stud is too young!")
        maleCheck = false
      } else if (maleAge > maleLimits.max) {
        setMaleMsg("Stud is too old!")
        maleCheck = false
      } else if (!male?.dob) {
        maleCheck = false
        setMaleMsg("Stud is too old!")
      }
      else {
        setMaleMsg("")
        maleCheck = true
      }
      ///Female age checks
      if (femaleAge < femaleLimits.min) {
        setFemaleMsg("Bitch is too young!")
        femaleCheck = false
      }
      else if (femaleAge > femaleLimits.max) {
        setFemaleMsg("Bitch is too old!")
        femaleCheck = false

      } else if (!female?.dob) {
        setMaleMsg("Bitch is too old!")
      }
      else {
        setFemaleMsg("")
        femaleCheck = true
      }

      ///mating Date check
      if (!matingData) {
        setmatingMsg("Please select Mating Date")
        matingDataCheck = false
      }
      else {
        setmatingMsg("")
        matingDataCheck = true
      }

      if (!matingDataCheck || !femaleCheck || !maleCheck) {
        isValid = false
        return isValid
      }
      else {
        isValid = true;
        return isValid;
      }

    }
    else {
      alert("Please fill out all fields!")
      return isValid
    }
  }

  const saveCertificate = async () => {
    if (validate()) {
      const user_accounts_head = await getAccountHead(user?.id)
      const studFee = await getStudCertificateFee()

      let formData = new FormData();
      formData.append("sire", maleId);
      formData.append("dam", femaleId);
      formData.append("sire_owner_id", user?.id);
      formData.append("dam_owner_id", female?.owners?.split(",")[0]);
      formData.append("mating_date", matingData);
      formData.append("created_by", user?.id);

      // console.log(noc);
      const res = await createStudCertificate(formData);

      if (res.status == 200) {

        toast.success(
          "Stud Certificate has been created successfully!",
          {
            // position: "top-center",
            // autoClose: 2000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,

            style: { zIndex: "10000000" },

          },
        );

        navigate(`/member/stud-certificates`);
      }
      else {
        if (res.status == 406) {
          toast.error("Failed to Save! Non-Sufficient Funds of Member", {
            // position: "top-center",
            // autoClose: 3000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,

            style: { zIndex: "10000000" },

          });
          setMaleId(null)
          setFemaleId(null)
        }
        else {
          if (res.status == 500) {
            // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
            toast.error("Something went wrong! Please try again", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setMaleId(null)
            setFemaleId(null)
          }
        }
      }
    }
  }

  const maleChange = async (e) => {
    setMaleId(e.value)
    const res = await getDogById(e.value)
    setMaleData(res && res[0])
  }
  const femaleChange = async (e) => {
    setFemaleId(e.value)
    console.log("femaleId", femaleId)
    const res = await getDogById(e.value)
    console.log("female_data", res);
    setFemaleData(res && res[0])
  }
  return (
    <div>
      <h1>Online Stud Certificate</h1>

      {
        loading ? <Loader size={50} /> :
          <>
            <span className="p-1 badge badge-primary  bg-success">
              Current Balance : {balance}
            </span>
            <p className="py-3">
              By filling out this online stud certificate, I certify that the
              information contained in is correct to the best of my knowledge and
              understanding. Furthermore, I have also informed my local Group Breed
              Warden of this mating.
            </p>
            <div className="bg-light shadow-sm">
              <div className="row d-flex justify-content-around align-items-start">
                <div className="col-md-5 shadow-sm bg-white p-3 my-2 rounded">
                  <label className="text-dark">
                    <b>Name of Dog:</b>
                  </label>
                  <Select
                    options={males && males?.map(male => ({ value: male.id, label: male.dog_name }))}
                    onChange={(e) => maleChange(e)}

                  />
                  {
                    maleId && maleId ?
                      <StudDetails type="Stud" id={maleId} /> :
                      null
                  }
                </div>
                <div className="col-md-5 shadow-sm bg-white p-3 my-2 rounded">
                  <label className="text-dark">
                    <b>Name of Bitch:</b>
                  </label>
                  <Select options={females && females?.map(female => ({ value: female.id, label: female.dog_name }))}
                    onChange={(e) => femaleChange(e)}
                  />
                  {
                    femaleId && femaleId ?
                      <StudDetails type="Dam" id={femaleId} /> :
                      null
                  }
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center container mx-2 mt-3">
                <div>
                  {/* <input type="button" onClick={()=>validate()} value="click"/> */}
                </div>
                <div className="col-md-5 shadow-sm bg-white p-3 rounded" style={{marginRight: "auto"}}>
                  <label className="fw-bold text-muted">Mating Date</label>
                  <input
                    onChange={(e) => { setMatingDate(e.target.value) }} type="date" className="form-control w-75 mx-2" />
                </div>
                  {balance >= 0 ?
                  <div className="col-md-5 ">
                    <button onClick={() => saveCertificate()} className="shadow-lg btn btn-lg btn-success text-white">Submit</button>
                  </div>
                   : 
                  <span className="m-2 w-75 alert alert-danger" role="alert">You cannot make this request, your account is delinquent!</span>
                  }
              </div>
              <div className="mx-4 mt-3">
                {
                  femaleMsg ?
                    <div class="alert alert-danger" role="alert">
                      {femaleMsg}
                    </div> : null
                }

                {
                  maleMsg ?
                    <div class="alert alert-danger" role="alert">
                      {maleMsg}
                    </div> : null
                }

                {
                  balMsg ?
                    <div class="alert alert-danger" role="alert">
                      {balMsg}
                    </div> : null
                }
                {
                  matingMsg ?
                    <div class="alert alert-danger" role="alert">
                      {matingMsg}
                    </div> : null
                }
              </div>
              <h2 className="px-3 mt-5 ">STUD CERTIFICATE</h2>
              <p className="text-justify p-3">
                Once you click Submit, this certificate will be recorded. An online
                copy of this certificate will also be available to the owner of the
                bitch. If you already have a positive balance in your account, that
                balance will be used for payment. If payment is required, you will be
                alerted of the amount payable. All payments are only acceptable in the
                form of a Bank Draft or Pay Order, drawn on a local bank and made out
                in favor of “ PRC ". No payments made via any other
                method are acceptable.
              </p>
              {stud_fee > 0 ?
                <div className="p-3">
                  <div className="card-basic ">
                    <div className="card-header header-basic">
                      <h4>Stud Certificate Fee</h4>
                    </div>
                    <div className="card-body">
                      <p>
                        {" "}
                        Rs. <h2>{stud_fee}</h2> Certificate
                      </p>
                    </div>
                  </div>
                </div>
                :
                ""
              }

            </div>
          </>

      }
    </div>
  );
};

export default CreateStudCertificate;

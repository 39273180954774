import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import { getKennelDetailsById, getKennelOwners } from "../../../api";
import logo from '../../../assets/Images/website_logo_large-300x241.png';
import placeHolder from '../../../assets/Images/image_paceholder.png';
import { Link } from "react-router-dom";
import Loader from "../../../components/loader";
import Sidebar_Member from "../../../layouts/SideNavigation_Member";
import Sidebar_Home from "../../../layouts/SideNavigation_Home";
import { GlobalContext } from "../../../context/store";
import VerticalAdverts from "../../../components/verticalAdverts";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const KennelDetails = () => {
    const {id}=useParams()
    const [data,setData]=useState({})
    const [kennel_owners,setKennelOwners]=useState([])
    const [loading,setLoading]=useState(false)
    const{user}=useContext(GlobalContext)

    useEffect(() => {

        const fetchData=async()=>{
            setLoading(true)
            const res=await getKennelDetailsById(id)
            setData(res&&res[0])
            const res_=await getKennelOwners(id)
            setKennelOwners(res_&&res_)
            setLoading(false)
        }
        fetchData()
      

    }, [id])  

const imageURL="https://admin.rottweiler.pk/public/members/profile_pic/";
const kennelImageURL="https://admin.rottweiler.pk/public/kennel_images/"

  return (
    <div className="row mx-0 bg-white rounded p-2 ">
    <div className="col-md-2  rounded">
       {
           user?
           <Sidebar_Member/>:
           <Sidebar_Home/>
       }
    </div>
    <div className="col-md-8 my-2 px-0 ">
        {
            loading?<Loader size={50}/>:

            <>
               <div>
    <div className=" row mx-4">
        <div className="col-md-4">
            <div className="kennel-img">
            {
            data?.photo?
            <LazyLoadImage
            alt={""}
            className="rounded-circle shadow-sm"
            maxHeight={200}
            effect="blur"
            src={
            "https://admin.rottweiler.pk/public/members/profile_pic/" +
            data?.photo
            } // use normal <img> attributes as props
/>:<img className="rounded-circle shadow-sm" src={placeHolder}/>
            }
            </div>
        </div>
        <div className="col-md-8 d-flex justify-content-start align-items-center">
        <h1 className="my-auto ">{data?.kennel_name}</h1>
        <hr/>
        </div>
    </div>
    
    <div className="row mx-4  my-4 bg-light shadow-sm">
        <div className="col-md-6 d-flex">
            <div className="p-2"><b>Suffix:</b></div>
            <div  className="p-2">{data?.suffix}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Phone No:</b></div>
            <div  className="p-2">{data?.phone}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Email:</b></div>
            <div  className="p-2">{data?.email || "-"}</div>
        </div>
   
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Address:</b></div>
            <div  className="p-2">{data?.address}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Social Media:</b></div>
            <div  className="p-2">{data?.facebook}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Website:</b></div>
            <div  className="p-2">{data?.websile}</div>
        </div>
   
    </div>

    </div>
    
    <div className="col-md-12  rounded">
      <div className="row container mx-1">
          {
                   kennel_owners?kennel_owners?.map(({friendly_url,first_name,last_name,membership_no,photo})=>(
                    <Link to={`/member-profile/${friendly_url}`} style={{textDecoration:"none"}}>
                <div className="col-md-6 bg-white d-flex justify-content-around p-2 shadow-sm  border rounded">

                    <div className="rounded-circle border shadow-sm">
                        <img src={photo?imageURL+photo:placeHolder} style={{minWidth:"80px",height:"80px",borderRadius:'50%'}} />
                    </div>
                    <div>
                    <h2 className="text-dark">
                    {first_name+ " "+last_name}
                    </h2>
                    <label className="fw-bold">Membership #: </label><span className="mx-2">{membership_no}</span>
                    </div>
                </div>
                 </Link>
              )):null
          }
         
      </div>
    </div>
            </>
        }
 
    </div>
    <div className="col-md-2">
        <VerticalAdverts/>
    </div>
 
  </div>
   
  );
};

export default KennelDetails;

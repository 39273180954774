import React, { useEffect, useState } from 'react'
import { getAllBlogs } from '../../api'
import News from '../../components/news'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const NewsAndUpdates = () => {
    const [posts,setPosts]=useState([])
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        const fetchData=async()=>{
            setLoading(true)
            const res=await getAllBlogs()
            setPosts(res&&res)
setLoading(false)            
        }
        fetchData()
    },[])

    return (
        <div className="container-fluid py-3">
            {/* <h2>Pakistan Rottweiler Club PRC</h2> */}
            <h4 className=" fw-bolder">News Updates</h4>
            <hr/>
            {
                loading?
                <div className='border rounded p-2'>
                    <Skeleton height={40} className='mb-2' width={'80%'}/>
                    <Skeleton count={2} height={18} width={'100%'}/>
                    <Skeleton  height={18} width={'60%'}/>
                    <Skeleton className='btn mt-2' width={100} height={40}/>
                </div>
                :
                posts&&posts?.map(({preview_text,title,link,publishing_date})=>(
                    <News link={link?`${link}`:"#"} title={title} content={preview_text} />
                ))
            }
        </div>
    )
}

export default NewsAndUpdates

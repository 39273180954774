import React, { useContext, useEffect, useState} from "react";
import { MDBDataTableV5,MDBBadge } from "mdbreact";
import { Link } from "react-router-dom";
import { getAllLitterInspections, getGBW, getMemberAccounts } from "../../api";
import Loader from "../../components/loader";
import { GlobalContext } from '../../context/store';
import { useNavigate } from "react-router-dom";

const LitterInspection = () => {

  const[data,setData]=useState([])
  const[loading,setLoading]=useState(false)
  const[gbws,setGBWS]=useState([])
  let navigate = useNavigate();

  const{user}=useContext(GlobalContext)

      const balance=async()=>{
        const memberId=user?.account_id
        const response=await getMemberAccounts(memberId);
        response.json().then(balance => ({
          balance: balance,
          status: response.status
        })
        ).then(res => {
          if(res.balance.balance > 0)
          {
            navigate(`/member/member-account-statement`);
          }
        });
      }

  useEffect(()=>{
    const fetchData=async()=>{
      setLoading(true)
      const res=await getAllLitterInspections(user?.id)
      console.log(res,"getAllLitterInspections........")
      setData(res)
      const g_res=await getGBW()
      setGBWS(g_res)
      
      setLoading(false)
    }
    fetchData()
    console.log(fetchData(),"LETTER..........")
    balance();

    },[])

    var datatable={
      columns: [
     
    ],
    rows:data
  }

  const badgesData = {
    columns: [
      ...datatable.columns,
      {
        label: 'Breeder',
        field: 'breeder_name',
        width: 200,
      },
      {
        label: 'Sire',
        field: 'sire_name',
        width: 200,
      },
    
      {
        label: 'Dam	',
        field: 'dam_name',
        width: 200,
      },
      {
        label: 'City',
        field: 'city_name',
        width: 200,
      },
      {
        label: 'Group Breed Warden',
        field: 'gbw',
        width: 200,
      },
      {
        label: 'Received on',
        field: 'created_at',
        sort: 'asc',
        width: 100,
      },
     
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      // {
      //   label: 'Actions',
      //   field: 'actions',
      // },
    ],
    rows: [
      ...datatable.rows.map((row, order) => ({
        ...row,
        status: (
          <MDBBadge style={{color:'black',backgroundColor:'rows?.status==3?"Expired"lightgrey'}} pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            {row?.status==1?"Pending":row?.status==2?"Approved":row?.status==3?"Expired":row?.status==5?"Approved for Inspection":row?.status==6?"Pending for Inspection Approval":"Rejected"}
          </MDBBadge>
        ),
        actions: ( 
          
            <Link to={`/member/stud-certificates/${row?.id}`}><i style={{fontSize:18}} className="fas fa-eye"></i></Link>
        ),
        sire_name: (
            <Link to={`/dog/bio/${row?.sire_url}`} style={{textDecoration:'none'}}>{row.sire_name}</Link>
        ),
        dam_name: (
            <Link to={`/dog/bio/${row?.dam_url}`}  style={{textDecoration:'none'}}>{row.dam_name}</Link>
        ),
        breeder_name: (
            <Link to={`/member-profile/${row?.breeder_url}`} style={{textDecoration:'none'}}>{row.breeder_name}</Link>
        ),
        gbw: (
            <Link to={`/member-profile/${row?.gbw_url}`} style={{textDecoration:'none'}}>
              {row?.gbw_name}
              </Link>
        ),
        created_at:(<span>{row?.created_at?.slice(0,10)}</span>)
      })),
    ],
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h1>Litter Inspection Requests - List</h1>
        <Link to={'create'} className="btn btn-primary shadow">
          <i className="fas fa-plus-circle"></i>&nbsp;Create New Request
        </Link>
      </div>
      {
        !loading?<MDBDataTableV5
        className="shadow mt-2 rounded" 
        hover
        entriesOptions={[10, 20, 25, 50]}
        entries={10}
        pagesAmount={10}
        data={badgesData}
        searchTop
        searchBottom={false}
        materialSearch
      />:<Loader size={50}/>
      }
    </>
  );
};

export default LitterInspection;

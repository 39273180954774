import React, { useContext, useState } from 'react'
import { signIn, userlogin } from '../../../api'
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../../../context/store';
import { toast } from "react-toastify";

const Login = (e) => {
  const [user, setUser] = useState()
  const [password, setPassword] = useState()
  let navigate = useNavigate();
  const { setAuthUser, setClubFees } = useContext(GlobalContext)

  const loginHandler = async (e) => {
    // e.preventdefault()


    let formData = new FormData();
    formData.append("username", user);
    formData.append("password", password);

    const response = await userlogin(formData);

    if (response.status == 200) {
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          setAuthUser(res?.data.data);
          setClubFees(res?.data.fees);

          localStorage.setItem("user", JSON.stringify(res?.data.data));
          localStorage.setItem("club_fees", JSON.stringify(res?.data.fees));

          toast.success(
            "Welcome Back " + res?.data.data.first_name + ' ' + res?.data?.data.last_name + '!', {
            // position: "top-center",
            // autoClose: 2000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,

            style: { zIndex: "10000000" },

          },
          );
          // console.log(res.data.data);
          navigate(`/member-profile`);
        });
    } else {
      toast.error(
        "Credentials did not match any user. Please try again",
        {
          // position: "top-center",
          // autoClose: 2000,
          // hideProgressBar: false,
          // closeOnClick: true,
          // pauseOnHover: true,
          // draggable: true,
          // progress: undefined,
          
          style: { zIndex: "10000000" },

        },
      );
    }
  };


  return (
    <div className=" d-flex justify-content-center align-items-center login-wraper" >
      <div className="login-main  shadow  d-flex justify-content-center align-items-center">
        <div className="login-frm d-flex justify-content-center flex-column align-items-center w-100">
          <h2 className="login-frm-heading mb-4 fw-bold" >Login to PRC</h2>
          <form className="d-flex justify-content-center flex-column align-items-center w-100">
            <div className="form-group my-2 w-75">
              <input onChange={({ target }) => setUser(target.value)} type="text" className="form-control" placeholder="Username" required />
            </div>
            <div className="form-group my-2 w-75">
              <input type="password" onChange={({ target }) => setPassword(target.value)} className="form-control" placeholder="Password" required />
            </div>
            <input onClick={(e) => loginHandler()} type="button" value="Login" className="mt-4 w-25 btn btn-light shadow" />
          </form>

        </div>
      </div>
    </div>

  )
}

export default Login

import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';
import regIcon from '../../assets/Images/register.png'
import logo from '../../assets/Images/rottweiler-logo.png'

const Sidebar_Home = () => {
    return (
        <div className="side-menu bg-white pt-2 rounded position-sticky px-2" style={{maxWidth:'280px'}}>
               <div className="logo">
                 <Link to="/">
                     <img src={logo}/>
                </Link>
                </div>
              <div className="mt-4 mb-4">

                 <Link style={{textDecoration:'none', borderTop:"1px solid lightgrey",borderBottom:"1px solid lightgrey"}}
                  className="py-3 my-3 mx-1 fw-bold txt-primary" to="/become-a-member">
                     
                     <img  src={regIcon}/><span className="register-title mx-2">Become a Member</span></Link>
              </div>

        <ul className="mx-2 p-2 rounded sider-bar-background">
            <h5 className="text-left fw-bold">Quick Links</h5>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/show-results`}> Shows Result </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/prc-database`}>PRC Database</Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/members-directory`}>Members Directory</Link></li>

        </ul>
    </div>
    )
}

export default Sidebar_Home;

import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/store";

const Header = () => {
  const { user } = useContext(GlobalContext);
  const { setAuthUser } = useContext(GlobalContext);
  let navigate = useNavigate();
  return (
    <div className="d-flex justify-content-between align-items-end px-2 secondary-header">
      <div>
        <Link to="/" style={{ textDecoration: "none" }}>
          <h1 className="pt-2 mt-2 text-white text-shadow  text-shadow-black brand-heading">
            PRC
          </h1>
        </Link>
        <p className="mb-2 text-white text-shadow-black">
          Pakistan Rottweiler Club
        </p>
      </div>
      <div className="user-links mb-2">
        <i className="fas fa-user ml-2"></i>
        {!user ? (
          <Link to={"/become-a-member"}> Register</Link>
        ) : (
          <a
            href="#"
            onClick={() => {
              setAuthUser(null);
              localStorage.removeItem("user");
              navigate("/");
            }}
          >
            Logout
          </a>
        )}{" "}
        |
        {!user ? (
          <Link to="/login">Login</Link>
        ) : (
          <Link to="/member/my-profile" className="">
            Profile
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;

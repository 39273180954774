import React, { useState,useEffect, useContext} from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAllDogOwners, getDogById, getDogIdBySlug } from "../../../../api";
import { DOG_BREED } from "../../../../constants";
import { GlobalContext } from "../../../../context/store";
import logo from '../../../../assets/Images/website_logo_large-300x241.png';
import Sidebar from "../../../../layouts/SideNavigation";
import Loader from "../../../../components/loader";
import EditDogImage from "../../../../Modals/EditDogImage";
import VerticalAdverts from "../../../../components/verticalAdverts";

const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const ZTP = () => {
const {slug}=useParams()
const [dog,setDog]=useState({})
const [sire,setSire]=useState("")
const [dam,setDam]=useState("")
const [loading,setLoading]=useState(false)
const [imageURL,setImageURL]=useState("")
const [dog_owners,setDogOwners]=useState([])
const [show, setShow] = useState(false);
const {user} = useContext(GlobalContext);
const {setDogId}=useContext(GlobalContext)


useEffect(async() => {

  setLoading(true)
  const id=await getDogIdBySlug(slug)
  const res=await getDogById(id&&id[0]?.id)
  setDog(res&&res[0])
  const resـ=await getAllDogOwners(slug)
  setDogOwners(resـ)
  setLoading(false)

  // console.log(dog&&dog?.id,"dog id")
}, [slug])

useEffect(async() => {
  setDogId(dog?.id)
const {id}=dog
const uri=`https://admin.rottweiler.pk/public/dog_images/${id}/`
if(id){
  setImageURL(uri)
}
}, [dog])

useEffect(()=>{
  setShow(false)

  if(dog_owners){
    for(let d of dog_owners?.map(({id})=>(id)) ){
      if(d==user?.id){
        setShow(true)
      }
      else{
        setShow(false)
      }
    }
    console.log(user?.id,dog_owners,"owners")
  }
  
},[dog_owners])

  return (

    <div className="row mx-0 mt-5 bg-white rounded p-2 ">
    <div className="col-md-2 mt-5  rounded">
    
      <Sidebar id={dog?.friendly_URL}/>
    </div>
    <div className="col-md-8 my-2 px-0 ">
    <div
      className="col-md-12  mt-3 py-4 rounded shadow px-3"
    
    >
      
      <div className="col-md-12  ">
        <h3 className="my-3 bg-light p-2 rounded shadow-sm w-100">
         {dog&&dog?.dog_name}
          <span className="breed-kp mx-2">{dog&&dog?.KP}</span>{" "}
          <i className={`fas ${dog?.sex=="Male"?'fa-mars':"fa-venus"}`}></i>
          <span className="breed-kp mx-5">{dog?.is_champ == 'Yes' ? 'Champion' : ''}</span>
        </h3>
      </div>
      {loading?<Loader size={50}/>:
      <>
      {dog?.breed_survey_done == 1 || dog?.breed_survey_life == 1 ? 
      <>
      <div className="row py-2 mx-0  bg-light basic_data-item  rounded shadow-sm ">
        <h3 className="font-weight-bold">ZTP Survey - General Information</h3>
        <div className="col-md-4">
          <table className="reponsive-table ">
            <tr>
              <th>Height:</th>
              <td>{dog?.height ? dog?.height+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Chest:</th>
              <td>{dog?.depth_chest ? dog?.depth_chest+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Chest Circumference:</th>
              <td>{dog?.chest_circumference ? dog?.chest_circumference+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Weight:</th>
              <td>{dog?.weight ? dog?.weight+' kg' : " --"}</td>
            </tr>
          </table>
        </div>
        <div className="col-md-4">
          <table className="reponsive-table ">
            <tr>
              <th>Skull:</th>
              <td>{dog?.head ? dog?.head+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Mouth Pigmentation:</th>
              <td>{dog?.pigment ? dog?.pigment : " --"}</td>
            </tr>
            <tr>
              <th>Body Length:</th>
              <td>{dog?.body_length ? dog?.body_length+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Eye Color:</th>
              <td>{dog?.eyes ? dog?.eyes : " --"}</td>
            </tr>
          </table>
        </div>
        <div className="col-md-4">
          <table className="reponsive-table ">
            <tr>
              <th>Muzzle:</th>
              <td>{dog?.muzzle ? dog?.muzzle+' cm' : " --"}</td>
            </tr>
            <tr>
              <th>Bite:</th>
              <td>{dog?.bite ? dog?.bite : " --"}</td>
            </tr>
            <tr>
              <th>Temperament:</th>
              <td>{dog?.character ? dog?.character : " --"}</td>
            </tr>
            <tr>
              <th>Gun Test:</th>
              <td>{dog?.reaction_to_gun_test ? dog?.reaction_to_gun_test : " --"}</td>
            </tr>
            
          </table>
        </div>
        <div className="col-md-6">
          <table className="table">
            <tr></tr>
          </table>
        </div>
      </div>

      <div className="row py-2 mt-3 mx-0 bg-light basic_data-item basic_data-item  rounded shadow-sm ">
      <h3 className="font-weight-bold">ZTP Survey - Critique</h3>
        <div className="col-md-12">
          {dog?.description ? dog?.description : "--"}
        </div>
      </div>
      </>
      :
      <div className="row py-2 mx-0  bg-light basic_data-item  rounded shadow-sm ">
        <h3 className="font-weight-bold">ZTP Not Done</h3>
      </div>
      }
    </>
      }
    </div>
    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
    <VerticalAdverts/>
    </div>
   <EditDogImage url={slug}/>
  </div>

  );
};

export default ZTP;

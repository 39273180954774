import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getAllFemaledogs,
  getStudsByOwnerId,
  getMemberAccounts,
  getLitterInspectionBySireDam,
  getKennelsByOwner,
  saveLitterRegistration,
  getLitterID,
  getLitterRegFee,
  getAccountHead,
  saveLitterRegistrationTransactions,
  getDamsByOwnerId,
  getAllMaledogs,
  LatestStudCertificateBySireDam,
} from "../../api";
import Loader from "../../components/loader";
import { GlobalContext } from "../../context/store";

const CreateLitterRegistration = () => {
  const [dogInputs, setDogInputs] = useState([
    {
      id: 0,
      name: "",
      color: "",
      sex: "",
      puppy_full_name: "",
      isValid: true,
    },
  ]);
  const [balance, setBalance] = useState(0);
  const [stud_certificate, setStud_certificate] = useState({});
  const [kennel, setKennel] = useState({});
  const [males, setMales] = useState([]);
  const [females, setFemales] = useState([]);
  const [femaleName, setFemaleName] = useState(null);
  const [maleName, setMaleName] = useState(null);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const [whelping_date, setWhelpingDate] = useState(null);
  const [lts, setLts] = useState([]);
  const [puppies, setPuppies] = useState([]);
  const [puppieFee, setPuppieFee] = useState(0);
  const [LitterFee, setLitterFee] = useState(0);
  const [puppie_Acc_Head, setPuppie_Acc_Head] = useState("");
  const [litter_Acc_Head, setLitter_Acc_Head] = useState("");
  const [puppie_Acc_Id, setPuppie_Acc_Id] = useState("");
  const [litter_Acc_Id, setLitter_Acc_Id] = useState("");
  const [user_Acc_Head, setUser_Acc_Head] = useState([]);
  const [loading, setLoading] = useState(false);


  const { user } = useContext(GlobalContext);
  const { f_nameRef } = useRef(null);
  const navigate = useNavigate();

  const bal = async () => {
    const memberId = user?.account_id
    const response = await getMemberAccounts(memberId);
    response.json().then(balance => ({
      balance: balance,
      status: response.status
    })
    ).then(res => {
      if (res.balance.balance <= 0) {
        setBalance(Math.abs(res.balance.balance));
      }
      else {
        setBalance(-res.balance.balance);
      }
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const kenl = await getKennelsByOwner(user?.id);
      setKennel(kenl && kenl[0]);
      const res = await getDamsByOwnerId(user?.id);
      setFemales(res && res);
      const res_ = await getAllMaledogs();
      setMales(res_ && res_);

      bal();
      // const bal = await getMemberAccounts(user && user?.account_id);
      // let debit =
      //   bal && bal?.map((item) => item.debit).reduce((n, acc) => acc + n, 0);
      // let credit =
      //   bal && bal?.map((item) => item.credit).reduce((n, acc) => acc + n, 0);
      // setBalance(credit - debit);

      const litterFee = await getLitterRegFee();
      const puppie_fee = litterFee?.filter(
        ({ option_name }) => option_name == "puppy_fees"
      )[0];
      const litter_fee = litterFee?.filter(
        ({ option_name }) => option_name == "litter_fee"
      )[0];

      setLitterFee(litter_fee && litter_fee?.option_value);
      setLitter_Acc_Head(litter_fee && litter_fee?.company_head);
      setLitter_Acc_Id(litter_fee && litter_fee?.account_id);
      setPuppieFee(puppie_fee && puppie_fee?.option_value);
      setPuppie_Acc_Head(puppie_fee && puppie_fee?.company_head);
      setPuppie_Acc_Id(puppie_fee && puppie_fee?.account_id);
      const uacc = await getAccountHead(user?.id);
      setUser_Acc_Head(uacc && uacc);

      setLoading(false)
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getLitterInspectionBySireDam({
        sire: male,
        dam: female,
      });
      const stud_cert = await LatestStudCertificateBySireDam({
        sire: male,
        dam: female,
      });
      setStud_certificate(stud_cert && stud_cert[0])
      setLts(res && res[0]);
      setDogInputs([
        {
          id: Math.random(),
          name: "",
          sex: "",
          color: "Black & Brown",
          puppy_full_name: "",
          isValid: true,
        },
      ]);
    };
    fetchData();
  }, [male, female]);

  useEffect(() => {
    setDogInputs([
      {
        id: Math.random(),
        name: "",
        sex: "",
        color: "Black & Brown",
        puppy_full_name: "",
        isValid: true,
      },
    ]);
  }, [!dogInputs?.length]);

  const updateFieldChanged = (index) => (e) => {
    let newArr = [...dogInputs];
    newArr[index][e.target.name] = e.target.value;
    setDogInputs(newArr);
  };

  const validate = () => {
    const data = [...dogInputs];

    for (let j of data) {
      if (j["color"] == "" || j["dog_name"] == "" || j["sex"] == "") {
        j["isValid"] = false;
      } else {
        j["isValid"] = true;
      }
    }
    setDogInputs(data);

    let checkValid = dogInputs?.filter((dog) => dog?.isValid === false);

    if (checkValid.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const submitLitter = async () => {
    if (validate()) {
      const maxId = await getLitterID();
      var inputArr = dogInputs?.map(
        ({ name, color, puppy_full_name, sex }) => ({
          name,
          color,
          puppy_full_name,
          sex,
        })
      );

      for (let a in inputArr) {
        inputArr[a] = {
          ...inputArr[a],
          puppy_full_name: inputArr[a]?.name + " " + kennel?.kennel_name
        };
      }
      const litter = {
        owner_id: user?.id,
        kennel_id: kennel?.id,
        breed: "Rottweiler",
        breed_location: "Pakistan",
        dob: whelping_date,
        no_of_puppies: inputArr?.length,
        puppies: inputArr,
        stud_id: stud_certificate?.id,
        sire: male,
        dam: female,
        created_by: user?.id,
      };

      let formData = new FormData();
      formData.append("owner_id", user?.id);
      formData.append("kennel_id", kennel?.id);
      formData.append("breed", "Rottweiler");
      formData.append("breed_location", "Pakistan");
      formData.append("dob", whelping_date);
      formData.append("no_of_puppies", inputArr?.length);
      formData.append("puppies", JSON.stringify(inputArr));
      formData.append("stud_id", stud_certificate?.id);
      formData.append("sire", male);
      formData.append("dam", female);
      formData.append("created_by", user?.id);

      const transactions = [];


      const litterDetails = inputArr;
      if (litterDetails) {
        const res = await saveLitterRegistration(formData);
        if (res.status == 201) {
          // notify("Litter Inpection Request Submitted")
          toast.success("Litter Registration Request Submitted", {
            // position: "top-center",
            // autoClose: 3000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,

            style: { zIndex: "10000000" },

          });
          setMale(null)
          setFemale(null)
          navigate('/member/litter-registration')
        }
        else {
          if (res.status == 406) {
            // notify("Failed to Save! This request is overdue. Please contact your Group Breed Warden")
            toast.error("Failed to Save! This request is overdue. Please contact your Group Breed Warden", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setMale(null)
            setFemale(null)
          }
          else {
            if (res.status == 404) {
              // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
              toast.error("Failed to Save! Stud Certificate not found of sire and dam as a pair", {
                // position: "top-center",
                // autoClose: 3000,
                // hideProgressBar: false,
                // closeOnClick: true,
                // pauseOnHover: true,
                // draggable: true,
                // progress: undefined,

                style: { zIndex: "10000000" },

              });
              setMale(null)
              setFemale(null)
            }
            else {
              if (res.status == 403) {
                // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                toast.error("Failed to Save! Please check the number of puppies and try again", {
                  // position: "top-center",
                  // autoClose: 3000,
                  // hideProgressBar: false,
                  // closeOnClick: true,
                  // pauseOnHover: true,
                  // draggable: true,
                  // progress: undefined,

                  style: { zIndex: "10000000" },

                });
                setMale(null)
                setFemale(null)
              }
              else {
                if (res.status == 409) {
                  // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                  toast.error("Failed to Save! You have In-Sufficient funds", {
                    // position: "top-center",
                    // autoClose: 3000,
                    // hideProgressBar: false,
                    // closeOnClick: true,
                    // pauseOnHover: true,
                    // draggable: true,
                    // progress: undefined,

                    style: { zIndex: "10000000" },

                  });
                  setMale(null)
                  setFemale(null)
                }
                else {
                  if (res.status == 500) {
                    // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                    toast.error("Failed to Save! No Group Breed Warden found in your city", {
                      // position: "top-center",
                      // autoClose: 3000,
                      // hideProgressBar: false,
                      // closeOnClick: true,
                      // pauseOnHover: true,
                      // draggable: true,
                      // progress: undefined,

                      style: { zIndex: "10000000" },

                    });
                    setMale(null)
                    setFemale(null)
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const addDog = () => {
    setDogInputs([
      ...dogInputs,
      {
        id: Math.random(),
        name: "",
        sex: "",
        color: "Black & Brown",
        puppy_full_name: "",
        isValid: true,
      },
    ]);
  };

  return (
    <div>
      <h1>Online Litter Registration</h1>
      {loading ? <Loader size={50} /> : kennel ? (
        <>
          <p className="py-3">
            By filling out this online form, I certify that this litter has been
            duly inspected by a Group Breed Warden of the GSDCP during the first
            week after birth.
          </p>

          <div className="bg-light shadow-sm">
            <div className="d-flex justify-content-start align-items-center p-2 flex-wrap">
              <span className="p-1 m-2 badge badge-primary  bg-success">
                Litter Registration Fee: {LitterFee.toLocaleString()}
              </span>
              <span className="p-1 m-2  badge badge-primary  bg-success">
                Per Puppy Fee: {puppieFee.toLocaleString()}
              </span>
              <span className="p-1 m-2  badge badge-primary  bg-success">
                Second Litter Inspection Charges: 1500 same city / 3000 other
                city
              </span>
            </div>
            <div className="row m-3 p-2 bg-white shadow-sm">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Full Name" className="fw-bold">
                      Full Name :
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{user?.first_name + " " + user?.last_name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Address" className="fw-bold">
                      Address :
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{user?.address}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Phone #" className="fw-bold">
                      Phone #
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{user?.phone}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Full Name" className="fw-bold">
                      Email :
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{user?.email}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Address" className="fw-bold">
                      Kennel Name:
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{kennel?.kennel_name}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Phone #" className="fw-bold">
                      Suffix / Prefix:
                    </label>
                  </div>
                  <div className="col-md-6">
                    <span>{kennel?.suffix || kennel?.prefix}</span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <span className="p-1   badge badge-primary  bg-primary">
                  Current balance is {balance}
                </span>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="Phone #" className="fw-bold">
                      Previous Letter Used:
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label>Suffix / Prefix:</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3 py-4">
              <h6>Litter Parent</h6>
              <div className="row">
                <div className="col-md-6 px-2">
                  <div className="shadow-sm bg-white p-2 my-2 rounded">
                    <label className="text-dark">Sire:</label>
                    <Select
                      options={
                        males &&
                        males?.map(({ id, dog_name }) => ({
                          label: dog_name,
                          value: id,
                        }))
                      }
                      onChange={(e) => {
                        setMale(e.value);
                        setMaleName(e.label);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 px-2">
                  <div className="shadow-sm bg-white p-2 my-2 rounded">
                    <label className="text-dark">Dam:</label>
                    <Select
                      options={
                        females &&
                        females?.map(({ id, dog_name }) => ({
                          label: dog_name,
                          value: id,
                        }))
                      }
                      onChange={(e) => {
                        setFemale(e.value);
                        setFemaleName(e.label);
                      }}
                    />
                  </div>
                </div>
              </div>
              {lts ? (
                <>
                  <h6>Details of Litter</h6>
                  <div className="row">
                    <div className="col-md-4 px-2">
                      <div className="shadow-sm bg-white p-2 my-2 rounded">
                        <label className="text-dark">
                          Litter Whelping Date:
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setWhelpingDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 px-2">
                      <div className="shadow-sm bg-white my-2 rounded">
                        <label className="text-dark p-2 rounded">
                          Total Chargable Amount:
                        </label>
                        <p className="mb-0 fw-bold bg-light p-2 text-danger">
                          {(parseInt(LitterFee) + (parseInt(puppieFee) * dogInputs?.length)
                          ).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                  {dogInputs &&
                    dogInputs.map((item, _ind) => (
                      <div className="position-relative">
                        <div className="row bg-white my-2 p-2 shadow-sm rounded mx-0 delete-option-main ">
                          <div className="position-absolute">
                            {_ind >= 1 ? (
                              <span
                                className="position-absolute delete-option "
                                onClick={() =>
                                  setDogInputs(
                                    dogInputs.filter(({ id }) => id != item?.id)
                                  )
                                }
                              >
                                X
                              </span>
                            ) : null}
                          </div>

                          <div className="col-md-3">
                            <label>Dog Name</label>
                            <input
                              value={item?.name}
                              name="name"
                              onChange={updateFieldChanged(_ind)}
                              className="form-control"
                              type="text"
                            />
                          </div>
                          <div className="col-md-3">
                            <label>Gender </label>
                            <select
                              className="form-select"
                              onChange={updateFieldChanged(_ind)}
                              name="sex"
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div>
                          {/* <div className="col-md-3">
                            <label>Color and Markings </label>
                            <select
                              className="form-select"
                              onChange={updateFieldChanged(_ind)}
                              name="color"
                            >
                              <option value="Black-Gold">Black-Gold</option>
                              <option value="Black & Brown">
                                Black & Brown
                              </option>
                            </select>
                          </div> */}
                          <div className="col-md-6">
                            <label>Full Name</label>
                            <input
                              value={item?.name + " " + kennel?.kennel_name}
                              className="form-control"
                              type="text"
                              name="puppy_full_name"
                              ref={f_nameRef}
                              readOnly
                              id={_ind}
                            />
                          </div>
                          <div className="container">
                            {item?.isValid ? null : (
                              <div className="container alert alert-danger mx-2 my-2 ">
                                Please fill out all feilds!{" "}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="d-flex justify-content-between aling-items-center py-2">
                    <button
                      onClick={() => {
                        addDog();
                      }}
                      className="btn shadow-sm btn-success"
                    >
                      <i className="fas fa-plus-circle"></i>
                      &nbsp;Add
                    </button>
                {balance >= 0 ?
                    <button
                    className="btn-danger btn shadow border"
                    onClick={() => {
                      submitLitter();
                    }}
                  >
                    Submit
                  </button>
                :
                  <span className="m-2 w-75 alert alert-danger" role="alert">You cannot make this request, your account is delinquent!</span>
                  }
                    
                  </div>
                </>
              ) : male && female && !lts ? (
                <div className="alert alert-danger">
                  Litter inspection not done. Please request litter inspection
                  first!
                </div>
              ) : null}
            </div>
            {/* /////// */}
          </div>
        </>
      ) : (
        <div className="container mt-5 w-100 text-center">
          <div
            className="badge badge-lg bg-danger text-center fw-bold"
            style={{ fontSize: 25 }}
          >
            <i class="fas mx-2 fa-exclamation-circle"></i>Oops! You don't have a registered kennel. Please contact the PRC office.
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateLitterRegistration;

import React, { useState, useEffect, useContext } from "react";
// import logo from '../../assets/Images/website_logo_large-300x241.png';
import placeHolder from '../../assets/Images/image_paceholder.png';
import { GlobalContext } from "../../context/store";
import { Outlet, useParams,useNavigate} from "react-router";
import { getAllDogsByOwner, getKennelsByOwner, getUserById, getMemberAccounts } from "../../api";
import ProgenyChild from "../../components/progeny_child";
// import Kennels from "../../Kennels";
import Kennel from "../../components/kennel";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import EditProfile from "../../Modals/EditProfile";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const MYProfile= () => {
  const {user,setAuthUser}=useContext(GlobalContext)
  const [user_data,setUserData]=useState()
  const [kennels,setKennels]=useState([])
  const [dogs,setDogs]=useState([])
  const [breedings,setBreedings]=useState([])

  const [delinquent, setdelinquent]=useState(false);

  const [wallet, setwallet]=useState(0);

  let navigate = useNavigate();

  const balance=async()=>{
    const memberId=user?.account_id
    const response=await getMemberAccounts(memberId);
    response.json().then(balance => ({
      balance: balance,
      status: response.status
    })
    ).then(res => {
      if(res.balance.balance <= 0)
      {
        setdelinquent(false);
        setwallet(Math.abs(res.balance.balance));
      }
      else
      {
        setdelinquent(true);
        setwallet(- res.balance.balance);
      }
    });
    // console.log(balance,"BALANCE PPPPPPPPP")
}

  useEffect(async() => {
      const res=await getUserById(user?.id)
      console.log(res,"user")
      setUserData(res&&res[0])
    const kenl_res=await getKennelsByOwner(user?.id)
    setKennels(kenl_res)
    const dogs_res=await getAllDogsByOwner(user?.id)
    if(dogs_res){
      setDogs(dogs_res&&dogs_res)
    }
    // console.log(dogs,"dogs")
    balance();
      }, [])


  const imgURL="http://admin.rottweiler.pk/public/members/profile_pic/"
  return (
    <div className="col-md-12 my-2 px-0 ">
    <div className=" row mx-auto">
        <div className="col-md-4">
            <div className="kennel-img">
                <img className="rounded-circle shadow-sm" src={user_data?.photo?imgURL+user_data?.photo:placeHolder}/>
            </div>
            <button 
            data-toggle="modal" data-target="#editModal"
            className="text-center mt-2 btn-light px-2 py-1  border">
              <i class="rounded-circle shadow fas text-dark fa-edit"></i> Edit</button>
        </div>
        <div className="col-md-4 d-flex justify-content-start align-items-start">
          <h1 className="my-auto ">{user_data?user_data?.first_name +" "+user_data?.last_name:null}</h1>
        <hr/>
        </div>
        <div className="col-md-4 d-flex justify-content-center align-items-center">
          <span className="badge bg-info">{'Current Balance : Rs. '+wallet}</span>
        <hr/>
        </div>

        {delinquent ? 
        <><div className="col-md-3"></div><div className="col-md-8 d-flex justify-content-start align-items-start">
            <h3 className="my-auto "><span className="badge bg-danger"><span className="badge bg-secondary">{'Rs. '+wallet}</span> Your account is delinquent. Please check your balance statement</span></h3>
            <hr />
          </div></>
        :
        ''
        }
        
    </div>
    <div className="user_data-bio mt-5">
    </div>
    <Tabs>
    <TabList>
      <Tab><i className="fa fa-user"></i> Profile</Tab>
      <Tab><i className="fas fa-paw"></i> Kennel</Tab>
      <Tab><i className="fas fa-venus-mars"></i> Breeding</Tab>
      <Tab><i className="fas fa-dog"></i> Dogs</Tab>
    </TabList>
    <TabPanel>
    <div className="row mx-auto container-fluid my-4 bg-light shadow-sm">
        <div className="col-md-6 d-flex">
            <div className="p-2"><b>First Name:</b></div>
            <div  className="p-2">{user_data?.first_name  || "--"}</div>
        </div>
       
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Email:</b></div>
            <div  className="p-2">{user_data?.email || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Last Name:</b></div>
            <div  className="p-2">{user_data?.last_name  || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Membership No:</b></div>
            <div  className="p-2">{user_data?.membership_no  || "--"}</div>
        </div>
   
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Address:</b></div>
            <div  className="p-2">{user_data?.address  || "--"}</div>
        </div>
        <div className="col-md-6 d-flex">
            <div  className="p-2"><b>Phone No.:</b></div>
            <div  className="p-2">{user_data?.phone  || "--"}</div>
        </div>
   
    </div>
    </TabPanel>
    <TabPanel>
    {
  kennels&&kennels.length&&kennels?.map((item)=>(
    <Kennel data={item}/>
  ))||<h5 className="m-4"> No kennels were found.</h5>
}
    </TabPanel>
    <TabPanel></TabPanel>
    <TabPanel>
    <div className="col-md-6">
    {
  // dogs&&dogs?.slice(0, 2).map((item)=>(
  dogs&&dogs?.map((item)=>(

    <ProgenyChild data={item}/>
  ))||<h5 className="m-4">No dogs were found.</h5>
}
    </div>
    </TabPanel>
  
    </Tabs>

<div className=" row mx-4">
<div className="d-flex justify-content-between w-100">
  {/* <button type="button" className="rounded shadow-sm border text-danger fw-bold butn butn-grey" 
  onClick={()=>{
    setAuthUser(null)
    localStorage.removeItem('user')
    navigate('/')
    
  }}
  >
  <i class="fas mx-2 fa-power-off"></i>
    Logout
  </button> */}
  
</div>
</div>
<EditProfile/>
    </div>
  
  );
};

export default MYProfile;

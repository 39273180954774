import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAllJudges, getTeam } from '../../../api'
import placeHolder from'../../../assets/Images/userPlaceholder.png'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const Judges = () => {
    const[judges,setJudges]=useState([])
    const[loading,setLoading]=useState(false)

    useEffect(()=>{
        const fetchData=async()=>{
            setLoading(true)
            const res=await getAllJudges()
            setJudges(res&&res)
            setLoading(false)
        }
    fetchData()
    },[])

    const imgURL="https://admin.rottweiler.pk/public/judge_images/";

    return (
        <div className="container">
            <h1 className="text-dark">PRC Judges</h1>
            <div className="row">
            {
                loading?
                <>
                 {
            [0,1,2,].map((a)=>(<div className="col-md-4  my-2">
              <div className="border mx-2 rounded">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-8">
              <Skeleton width={150} height={150} className="text-center shadow-sm rounded-circle"/>
                </div>
                <div className="my-2 col-md-10">
              <Skeleton width={'100%'} height={25} className="shadow-sm my-2"/>
              <Skeleton width={'90%'} height={25} className="shadow shadow-sm my-1"/>
              <Skeleton width={'90%'} height={15} className="shadow shadow-sm my-1"/>
              </div>
              </div>
           
              </div>
         
            </div>))
            } 
                </>
                :
                judges&&judges.length?judges?.map(({full_name,description,position_in_club,image,url_link,facebook_url,youtube_url,twitter_url})=>(
                    <div class="col-md-4  mt-4 mb-4 p-3 d-flex justify-content-center">
                    <div class="p_card p-4 shadow bg-white">
                        <div class=" image d-flex flex-column justify-content-center align-items-center"> 
                        {
                            image?
                            <LazyLoadImage
                            alt={full_name}
                            effect="blur"
                            maxHeight={200}
                            aiamgelt="Card image cap"
                            className="card-img-top p-2 rounded-circle"
                            width={"auto"}
                            src={imgURL +image}
                          />
                            :
                            <img className="shadow rounded-circle" src={placeHolder} height="150" width="158" />
                        }
                      
                        <span class="name mt-3">{full_name}</span> <span class="idd">{position_in_club}</span>
                        {
                            description?
                            <div class=" d-flex mt-2"> <Link to={url_link||"#"} className="btn  butn-grey shadow-sm">Read Bio</Link> </div>
                            :
                            null
                        }
                         <div class="gap-3 mt-3 icons d-flex flex-row justify-content-center align-items-center">
                             {
                                 facebook_url?
                                 <a href={facebook_url?facebook_url:"#"}><i class="fab fa-facebook"></i></a>
                                 :null
                             
                             }
                             {
                                     youtube_url?
                                     <a href={youtube_url?youtube_url:"#"}><i  class="fab fa-youtube"></i></a>
                                     :null
                             }
                             {
                                 twitter_url?
                                     <a href={twitter_url?twitter_url:"#"}><i class="fab fa-twitter"></i></a>
                                :null

                             }
                                
                        </div>
                        </div>
                    </div>
                </div>          
                )):null
            }
            </div>
        </div>
    )
}


export default Judges

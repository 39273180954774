import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProgenyBySire,getProgenyByDam, getPadigree, getAllPuppies, getDogById, getDogIdBySlug } from "../../../../api";
// import placeHolder from "../../../../assets/Images/placeholderdog.jpg";

import ProgenyChildren from "../../../../components/progeny_children";
import ProgenyChild from "../../../../components/progeny_children";
import { DOG_BREED } from "../../../../constants";
import logo from '../../../../assets/Images/website_logo_large-300x241.png';
import Sidebar from "../../../../layouts/SideNavigation";
import Loader from "../../../../components/loader";
import { Link } from "react-router-dom";
import VerticalAdverts from "../../../../components/verticalAdverts";
const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const Progeny = () => {
  
  const [progeny,setProgeny]= useState([])
  const [dog,setDog]= useState({})
  const [loading,setLoading]= useState(false)
  const {slug}=useParams()

  useEffect(async() => {
    const d=await getDogIdBySlug(slug)
    const res=await getDogById(d[0]?.id)
    setDog(res&&res[0])

  }, [slug])

  useEffect(async () => {
    setLoading(true)
    if (!dog) {
      console.log("Dog not found");
    } else if (dog) {
      if (dog?.sex === "Male") {
        const res = await getProgenyBySire(dog?.id);
        setProgeny(res);
        console.log("progeny",progeny)
      } else if(dog?.sex === "Female") {
        const res = await getProgenyByDam(dog?.id);
        setProgeny(res);
        console.log("progeny",progeny)
      }
    }
    setLoading(false)
   
 
  }, [dog]);

    return (
      <div className="row mx-0 mt-5 bg-white rounded p-2 ">
    <div className="col-md-2 mt-5  rounded">
        <Sidebar id={slug}/>
      </div>
      <div className="col-md-8 my-2 px-0 ">
      <div className="row px-4">
      <div className="col-md-12  ">
        <h3 className="my-3 bg-light p-2 rounded shadow-sm w-100">
            {dog&&dog?.dog_name}
          <span className="breed-kp"> KP:{" "}  {dog&&dog?.KP}</span>{" "}
          <i className={`fas ${dog&&dog?.sex=="Female"?"fa-venus":dog&&dog?.sex=="Male"?"fa-mars":null}`}></i>
          <span className="breed-kp mx-5">{dog?.is_champ == 'Yes' ? 'Champion' : ''}</span>
        </h3>
      </div>
      {loading?<Loader size={50}/>:progeny?.length&&progeny.map((item) => (
        <Link to={`/dog/bio/${item?.friendly_URL}`} style={{textDecoration:"none"}}>
        <div className="col-md-10">
          <div className="row py-2 mt-3 mx-0 sibblings-item bg-light rounded shadow-sm ">
            <div className="col-3">
              <img src={placeHolder} className="border shadow-sm sibblings-item-img rounded" />
            </div>
            <div className="col-9 d-flex flex-column justify-content-center ">
              <div className="d-flex flex-column justify-content-between mx-2">
                <h4 className="txt-primary">{item?.dog_name} </h4>
                <h6>
                  {" "}
                  <i className={`fas ${item&&item?.sex=="Female"?"fa-venus":item&&item?.sex=="Male"?"fa-mars":null} mx-2`}></i>
                  {item.sex} | {"Rottweiler"}
                </h6>
                <div className="d-flex justify-content-between">
                  <span className="txt-primary">
                    <b>Whelped:</b>
                  </span>{" "}
                  <span>{item?.dob?.slice(0,10)}</span>
                  <span className="text-warning">
                    <b>
                      <i>Registry:</i>
                    </b>
                  </span>{" "}
                  <span>{item?.regestration_no || item.KP}</span>
                </div>
              </div>
            </div>
          </div>
          {
            dog?.sex=="Male"?
            <ProgenyChildren sire={dog?.id} dam={item?.id}/>:

              <ProgenyChildren sire={item.id} dam={dog?.id}/>
          }
        </div>
        </Link>
      ))}
    </div>
      </div>
      <div className="col-md-2  rounded justify-content-center align-items-center">
      <VerticalAdverts/>
      </div>
    </div>
       
    )
}

export default Progeny

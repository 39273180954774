import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import { getAlldogs, getDogById } from "../api";
// import placeHolder from "../assets/Images/placeholderdog.jpg";

const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const PadigreeDetails = ({dog_id}) => {
  
  const [dog_details,setDogDetails]=useState({})
  
  const imageURL =`https://admin.rottweiler.pk/public/dog_images/${dog_details?.id}/`

    useEffect(async() => {
      if(dog_id!=undefined){
        const res=await getDogById(dog_id)
        setDogDetails(res&&res[0])
      }
       

    },[dog_id])

  return (
    <Link to={dog_id?`/dog/bio/${dog_details?.friendly_URL}`:'#'}>
    <div className="paddigery-hover flex-column justify-content-start align-items-center">
      <div className="paddigery-hover-img  d-inline-block">
        <img
          src={
            dog_details && dog_details.image ? imageURL+dog_details?.image : placeHolder
          }
        />
      </div>
      <div>
        <h4 className="mb-0 txt-primary p-2">
          <i className={`fas ${dog_details?.sex=="Male"?"fa-mars":dog_details?.sex=="Female"?"fa-venus":null}`}></i>
          &nbsp; {dog_details?.dog_name || "Unkown"}
        </h4>
        {dog_details?.show_title?<p className="my-0 badge bg-success text-wrap">
          {dog_details?.show_title}
        </p>:null}
      </div>
      <div className="w-auto">
        <div className="d-flex flex-wrap justify-content-center align-items-start p-2">
          {
            <>
              {dog_details && dog_details.KP ? (
                <p className="px-2">
                  <b>KP :</b>
                  <br />
                  {dog_details && dog_details.KP
                    ? dog_details?.KP
                    : dog_details?.regestration_no
                    ? dog_details?.regestration_no
                    : null}
                </p>
              ) : null}
              {dog_details && dog_details.dob ? (
                <p className="px-2">
                  <b>Date of Birth :</b>
                  <br />
                  {dog_details && dog_details.dob
                    ? dog_details?.dob?.slice(0, 10)
                    : null}
                </p>
              ) : null}
              {dog_details && dog_details.hip ? (
                <p className="px-2">
                  <b>HD :</b>
                  <br />{" "}
                  {dog_details && dog_details.hip ? dog_details?.hip : null}
                </p>
              ) : null}
              {dog_details && dog_details.elbows ? (
                <p className="px-2">
                  <b>ED:</b>
                  <br />
                  {dog_details && dog_details.elbows
                    ? dog_details?.elbows
                    : null}
                </p>
              ) : null}
            </>
          }
        </div>
      </div>
    </div>
    </Link>
  );
};

export default PadigreeDetails;

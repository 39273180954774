import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAlldogs, getDogById, getDogIdBySlug, getPadigree } from "../../../../api";
// import placeHolder from "../../../../assets/Images/placeholder.jpg";
import { Link } from "react-router-dom";

import PadigreeDetails from "../../../../components/padigree_details";
const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const FullPadigree = () => {
  
  const [padigree, setPadigree] = useState([]);
  const [dog, setDog] = useState([]);

  const {slug}=useParams()

  useEffect(async () => {
    const id=await getDogIdBySlug(slug)
    const dog_id = await getDogById(id&&id[0]?.id);
    setDog(dog_id&&dog_id[0]);
    const res = await getPadigree(id&&id[0]?.id);
    setPadigree(res&&res[0]);
  }, [slug]);

  return (
    <div >
      <h3 className="my-3 bg-white p-2 rounded shadow-sm w-100">
        {dog && dog?.dog_name}
        {dog && dog.KP ? (
          <span className="breed-kp px-2"> ( KP {dog?.KP} ) </span>
        ) : null}
        <i className={`fas ${dog?.sex == "Male" ? "fa-mars" : "fa-venus"}`}></i>
        <span className="breed-kp mx-5">{dog?.is_champ == 'Yes' ? 'Champion' : ''}</span>
        <Link className="butn butn-white border rounded" style={{float:'right', padding:'8px 20px', backgroundColor: 'red', color:'white'}} to={`/dog/pedigree/${dog?.friendly_URL}`}>
             Go Back
        </Link>
      </h3>
      <div id="tree"   style={{overflowX:"scroll"}} className="bg-light w-100 rounded shadow">
        <div className="branch">
          <div className="entry">
            <span>
              <div id={padigree?.father_id ? padigree?.father_id : 0}>
                <div className="paddigery-img">
                  <img
                    src={
                      padigree?.father_image
                        ? padigree?.father_image
                        : placeHolder
                    }
                  />
                </div>
                <div className="paddigery-text py-2">
                  <h6 className="font-weight-bold">
                    {padigree?.father?padigree?.father?.slice(0, 20) + "...." : "Unkown"}
                  </h6>
                </div>
              </div>
              <PadigreeDetails dog_id={padigree?.father_id} />
            </span>
            <div className="branch">
              <div className="entry">
                <span>
                  <div
                    style={{ zIndex: 1000 }}
                    id={padigree?.father_id ? padigree?.father_id : 0}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="paddigery-img-ii">
                        <img
                          src={
                            padigree && padigree?.fatherFather_image
                              ? padigree?.fatherFather_image
                              : placeHolder
                          }
                        />
                      </div>
                      <div className="paddigery-text py-2">
                        <h6 className="font-weight-bold">
                          {padigree &&
                            padigree?.fatherFather ?
                            padigree?.fatherFather.slice(0, 13) + "...."
                             :
                            "Unkown"}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.fatherFather_id} />
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree && padigree?.fathersfathersfather_image
                                ? padigree?.fathersfathersfather_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree &&
                              padigree?.fathersfathersfather ?
                              padigree?.fathersfathersfather.slice(0, 13) +
                                "...."
                                :
                              "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.fathersfathersfather_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersfather_image
                                    ? padigree?.fathersfathersfathersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersfather ?
                                  padigree?.fathersfathersfathersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersfather_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersmother_image
                                    ? padigree?.fathersfathersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersmother ?
                                  padigree?.fathersfathersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersmother_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.fathersfathersmother_image
                                ? padigree?.fathersfathersmother_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersfathersmother ?
                            padigree?.fathersfathersmother.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.fathersfathersmother_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersmother_image
                                    ? padigree?.fathersfathersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersmother ?
                                  padigree?.fathersfathersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersmother_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersmother_image
                                    ? padigree?.fathersfathersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersmother ?
                                  padigree?.fathersfathersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersmother_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-ii">
                      <img
                        src={
                          padigree?.fatherMother_image
                            ? padigree?.fatherMother_image
                            : placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.fatherMother ?
                        padigree?.fatherMother.slice(0, 13) + "...."
                        :
                          "Unknown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.fatherMother_id} />
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.fathersmothersfather_image
                                ? padigree?.fathersmothersfather_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersmothersfather ?
                            padigree?.fathersmothersfather.slice(0, 13) +
                              "...."
                              : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.fathersmothersfather_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersmothersfathersmother_image
                                    ? padigree?.fathersmothersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersmothersfathersmother ?
                                  padigree?.fathersmothersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersmothersfathersmother_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersmothersfathersfather_image
                                    ? padigree?.fathersmothersfathersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersmothersfathersfather ?
                                  padigree?.fathersmothersfathersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersmothersfathersfather_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.fathersmothersmother_image
                                ? padigree?.fathersmothersmother_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.fathersmothersmother ?
                            padigree?.fathersmothersmother.slice(0, 13) +
                              "...."
                              : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.fathersmothersmother_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersmothersmothersfather_image
                                    ? padigree?.fathersmothersmothersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersmothersmothersfather ?
                                  padigree?.fathersmothersmothersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersmothersmothersfather_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersmothersmothersmother_image
                                    ? padigree?.fathersmothersmothersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersmothersmothersmother ?
                                  padigree?.fathersmothersmothersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersmothersmothersmother_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="entry">
            <span>
              <div>
                <div className="paddigery-img">
                  <img
                    src={
                      padigree?.mother_image
                        ? padigree?.mother_image
                        : placeHolder
                    }
                  />
                </div>
                <div className="paddigery-text py-2">
                  <h6 className="font-weight-bold">
                    {padigree?.mother ? padigree?.mother.slice(0, 20) + "...." : "Unkown"}
                  </h6>
                </div>
              </div>
              <PadigreeDetails dog_id={padigree?.mother_id} />
            </span>
            <div className="branch">
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-ii">
                      <img
                        src={
                          padigree?.MotherFather_image
                            ? padigree?.MotherFather_image
                            : placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.MotherFather ? padigree?.MotherFather.slice(0, 13) + "...." :
                          "Unknown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.MotherFather_id} />
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.mothersfathersfather_image
                                ? padigree?.mothersfathersfather_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersfathersfather ? padigree?.mothersfathersfather.slice(0, 13) +
                              "...." : "Unknown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.mothersfathersfather_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersfather_image
                                    ? padigree?.fathersfathersfathersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersfather ? padigree?.fathersfathersfathersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersfather_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.fathersfathersfathersmother_image
                                    ? padigree?.fathersfathersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.fathersfathersfathersmother ? padigree?.fathersfathersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.fathersfathersfathersmother_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.mothersfathersmother_image
                                ? padigree?.mothersfathersmother_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersfathersmother ? padigree?.mothersfathersmother.slice(0, 13) +
                              "...." : "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.mothersfathersmother_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersfathersmothersmother_image
                                    ? padigree?.mothersfathersmothersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersfathersmothersmother ? padigree?.mothersfathersmothersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersfathersmothersmother_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersfathersmothersfather_image
                                    ? padigree?.mothersfathersmothersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersfathersmothersfather ? padigree?.mothersfathersmothersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersfathersmothersfather_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="entry">
                <span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="paddigery-img-ii">
                      <img
                        src={
                          padigree?.motherMother_image
                            ? padigree?.motherMother_image
                            : placeHolder
                        }
                      />
                    </div>
                    <div className="paddigery-text py-2">
                      <h6 className="font-weight-bold">
                        {padigree?.motherMother ? padigree?.motherMother.slice(0, 13) + "...." :
                          "Unkown"}
                      </h6>
                    </div>
                  </div>
                  <PadigreeDetails dog_id={padigree?.motherMother_id} />
                </span>
                <div className="branch">
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.mothersmothersfather_image
                                ? padigree?.mothersmothersfather_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersmothersfather ? padigree?.mothersmothersfather.slice(0, 13) +
                              "...." : "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.mothersmothersfather_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersmothersfathersfather_image
                                    ? padigree?.mothersmothersfathersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersmothersfathersfather ? padigree?.mothersmothersfathersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersmothersfathersfather_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersmothersfathersmother_image
                                    ? padigree?.mothersmothersfathersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersmothersfathersmother ? padigree?.mothersmothersfathersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersmothersfathersmother_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="entry">
                    <span>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              padigree?.mothersmothersmother_image
                                ? padigree?.mothersmothersmother_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {padigree?.mothersmothersmother ? padigree?.mothersmothersmother.slice(0, 13) +
                              "...." : "Unkown"}
                          </h6>
                        </div>
                      </div>
                      <PadigreeDetails
                        dog_id={padigree?.mothersmothersmother_id}
                      />
                    </span>
                    <div className="branch">
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersmothersmothersmother_image
                                    ? padigree?.mothersmothersmothersmother_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersmothersmothersmother ? padigree?.mothersmothersmothersmother.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersmothersmothersmother_id}
                          />
                        </span>
                      </div>
                      <div className="entry">
                        {" "}
                        <span>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="paddigery-img-iii">
                              <img
                                src={
                                  padigree &&
                                  padigree?.mothersmothersmothersfather_image
                                    ? padigree?.mothersmothersmothersfather_image
                                    : placeHolder
                                }
                              />
                            </div>
                            <div className="paddigery-text py-2">
                              <h6 className="font-weight-bold">
                                {padigree &&
                                  padigree?.mothersmothersmothersfather ? padigree?.mothersmothersmothersfather.slice(0, 13) +
                                    "...."
                                    :
                                  "Unkown"}
                              </h6>
                            </div>
                          </div>
                          <PadigreeDetails
                            dog_id={padigree?.mothersmothersmothersfather_id}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FullPadigree;

import React, { useEffect, useState } from 'react'
import { getHorizontalAds } from '../api';

const VerticalAdverts = () => {

    const[ads,setAds]=useState([])
    const imageURL="https://admin.rottweiler.pk/website_images/adverts/";

    useEffect(()=>{
        const fetchAds=async()=>{
            const ad=await getHorizontalAds()
            setAds(ad&&ad)
        }
        fetchAds()
    },[])

    return (
        <div>
            {
            ads&&ads?.map((ad)=>(
              <a href={ad?.link} target="_blank">
              <img src={imageURL+ad?.image}  alt={ad?.name}/>
              </a>
            ))
          }
        </div>
    )
}

export default VerticalAdverts

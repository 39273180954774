import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getAllFemaledogs,
  getStudsByOwnerId,
  StudCertificateBySireDam,
  createlitinsp,
  getKennelsByOwner,
  getDamsByOwnerId,
  getAllMaledogs,
  getUserById,
  getMemberAccounts,
  getGBW
} from "../../api";
import Loader from "../../components/loader";
import { GlobalContext } from "../../context/store";

export const CreateLitterInspection = () => {
  const [males, setMales] = useState([]);
  const [male, setMale] = useState(null);
  const [female, setFemale] = useState(null);
  const [kennel, setKennel] = useState({});
  const [females, setFemales] = useState([]);
  const [certificate, setCertificate] = useState({});
  const [whelping_date, setWhelpingDate] = useState(null);
  const [balance, setBalance] = useState(0);
  const [no_of_puppies, set_no_of_puppies] = useState(null);
  const [no_of_male_puppies, set_no_of_male_puppies] = useState(null);
  const [no_of_female_puppies, set_no_of_female_puppies] = useState(null);
  const [no_of_expired_puppies, set_no_of_expired_puppies] = useState(null);
  const [user_data, set_user_data] = useState({});
  const [gbws, setGbws] = useState([]);
  const [msg, setMsg] = useState("");
  const [loading,setLoading]=useState(false)

  const { user } = useContext(GlobalContext);
  let navigate = useNavigate();

  const Balance = async () => {
    const memberId = user?.account_id
    const response = await getMemberAccounts(memberId);
    response.json().then(balance => ({
      balance: balance,
      status: response.status
    })
    ).then(res => {
      if (res.balance.balance < 0) {
        setBalance(Math.abs(res.balance.balance));
      }
      else {
        setBalance(- res.balance.balance);
      }
    });
  }

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true)
      const kenl=await getKennelsByOwner(user?.id)
      setKennel(kenl&&kenl[0])
      const res = await getDamsByOwnerId(user?.id);
      setFemales(res && res);
      const res_ = await getAllMaledogs();
      setMales(res_ && res_);
      const u_res=await getUserById(user?.id)
      set_user_data(u_res&&u_res[0])

      setLoading(false)
    };
    fetchData();

    Balance();

  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const sc = await StudCertificateBySireDam({ sire: male, dam: female });
      setCertificate(sc && sc[0]);
    };
    fetchData();
  }, [male, female]);

  const handleLitterInspection = async(e) => {
    e.preventDefault();
    if (
      !no_of_expired_puppies ||
      !no_of_female_puppies ||
      !no_of_male_puppies ||
      !no_of_puppies ||
      !whelping_date
    ) {
      notify("Please fill out all feilds!");
    }
    else
    {

    let formData = new FormData();
    formData.append("breeder_id",user?.id);
    formData.append("city_id",user_data?.city_id);
    formData.append("sire_id",male);
    formData.append("dam_id",female);
    formData.append("whelping_date",whelping_date);
    formData.append("mating_date",certificate?.mating_date.slice(0,10));
    formData.append("puppies_born",no_of_puppies);
    formData.append("male_puppies",no_of_male_puppies);
    formData.append("female_puppies",no_of_female_puppies);
    formData.append("expired_puppies",no_of_expired_puppies);
    formData.append("created_by",user?.id);

    const res = await createlitinsp(formData);

      if(res.status == 201)
      {
            // notify("Litter Inpection Request Submitted")
            toast.success("Litter Inpection Request Submitted", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setMale(null)
            setFemale(null)
            navigate('/member/litter-inspection')
      }
      else
      {
        if(res.status == 406)
        {
            // notify("Failed to Save! This request is overdue. Please contact your Group Breed Warden")
            toast.error("Failed to Save! This request is overdue. Please contact your Group Breed Warden", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setMale(null)
            setFemale(null)
        }
        else
        {
          if(res.status == 404)
          {
            // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
            toast.error("Failed to Save! Stud Certificate not found of sire and dam as a pair", {
              // position: "top-center",
              // autoClose: 3000,
              // hideProgressBar: false,
              // closeOnClick: true,
              // pauseOnHover: true,
              // draggable: true,
              // progress: undefined,

              style: { zIndex: "10000000" },

            });
            setMale(null)
            setFemale(null)
          }
          else
          {
            if(res.status == 403)
            {
              // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
              toast.error("Failed to Save! Please check the number of puppies and try again", {
                // position: "top-center",
                // autoClose: 3000,
                // hideProgressBar: false,
                // closeOnClick: true,
                // pauseOnHover: true,
                // draggable: true,
                // progress: undefined,

                style: { zIndex: "10000000" },

              });
              setMale(null)
              setFemale(null)
            }
            else
            {
              if(res.status == 409)
              {
                // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                toast.error("Failed to Save! You have In-Sufficient funds", {
                  // position: "top-center",
                  // autoClose: 3000,
                  // hideProgressBar: false,
                  // closeOnClick: true,
                  // pauseOnHover: true,
                  // draggable: true,
                  // progress: undefined,

                  style: { zIndex: "10000000" },

                });
                setMale(null)
                setFemale(null)
              }
              else
              {
                if(res.status == 500)
                {
                  // notify("Failed to Save! Stud Certificate Not Found of Sire and Dam As A Pair")
                  toast.error("Failed to Save! No Group Breed Warden found in your city", {
                    // position: "top-center",
                    // autoClose: 3000,
                    // hideProgressBar: false,
                    // closeOnClick: true,
                    // pauseOnHover: true,
                    // draggable: true,
                    // progress: undefined,

                    style: { zIndex: "10000000" },

                  });
                  setMale(null)
                  setFemale(null)
                } 
              }
            }
          }
        }
      }
    }
  };

  const notify = (msg) =>
    toast.info(msg, {
      // position: "top-center",
      // autoClose: 3000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,

      style: { zIndex: "10000000" },

    });

  return (
    <div>
      <h1>Litter Inspection Form</h1>
{
loading?<Loader size={50}/>:kennel&&kennel?
  <>
    <div className="bg-light shadow-sm border">
        <div className="" >
          <div className="row p-2 bg-white my-2 mx-2 rounded shadow-sm">
            <div className="col-md-4">
              <label className="fw-bold">Breeder:</label><span className="mx-2">{user_data?user_data?.first_name+ " "+user_data?.last_name:null}</span>
            </div>
            <div className="col-md-4">
              <label className="fw-bold">City:</label><span className="mx-2">{user_data?user_data?.city:null}</span>
            </div>
            <div className="col-md-4">
              <label className="fw-bold">Kennel:</label><span className="mx-2">{kennel?kennel?.kennel_name:null}</span>
            </div>
          </div>
          <div className="row mx-0 ">
            <div className="col-md-6 px-2">
              <div className="shadow-sm bg-white p-2 my-2 rounded">
                <label className="text-dark">Sire:</label>
                <Select
                  options={
                    males &&
                    males?.map(({ id, dog_name }) => ({
                      value: id,
                      label: dog_name,
                    }))
                  }
                  onChange={(e) => setMale(e.value)}
                />
              </div>
            </div>
            <div className="col-md-6 px-2">
              <div className="shadow-sm bg-white p-2 my-2 rounded">
                <label className="text-dark">Dam:</label>
                <Select
                  options={
                    females &&
                    females?.map(({ id, dog_name }) => ({
                      value: id,
                      label: dog_name,
                    }))
                  }
                  onChange={(e) => setFemale(e.value)}
                />{" "}
              </div>
            </div>
            {certificate ? (
              <>
                <div className="col-md-6 px-2">
                  <div className="shadow-sm bg-white p-2 my-2 rounded">
                    <label className="text-dark">Whelping Date:</label>
                    <input
                      type="date"
                      className="form-control"
                      onChange={(e) => {
                        setWhelpingDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6 px-2">
                  <div className="shadow-sm bg-white p-2 my-2 rounded">
                    <label className="text-dark">Mating Date:</label>
                    <h6 className="mt-2 text-muted">
                      {new Date(certificate?.mating_date)?.toDateString()}
                    </h6>
                  </div>
                </div>
                <div className="row mx-0 px-2">
                  <div className="col-md-3">
                    <div className="shadow-sm bg-white p-2 my-2 rounded">
                      <label className="text-dark">No of Puppies(Alive):</label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => {
                          set_no_of_puppies(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="shadow-sm bg-white p-2 my-2 rounded">
                      <label className="text-dark">
                        No of Male Puppies Born:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => {
                          set_no_of_male_puppies(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="shadow-sm bg-white p-2 my-2 rounded">
                      <label className="text-dark">
                        No of Female Puppies Born:
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        onChange={(e) => {
                          set_no_of_female_puppies(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="shadow-sm bg-white p-2 my-2 rounded">
                      <label className="text-dark">Puppies Expired:</label>
                      <input
                        type="number"
                        className="form-control"
                        required
                        onChange={(e) => {
                          set_no_of_expired_puppies(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {balance > 0 ?
                <div className="d-flex justify-content-end align-items-center p-3">
                  <input type="button" className="shadow-sm mx-4 btn btn-danger " value="submit" onClick={(e)=>handleLitterInspection(e)}/>
                </div>
                :balance == 0 ?
                <span className="m-2 w-75 alert alert-danger" role="alert">You cannot make this request, your account is empty!</span>
                :
                <span className="m-2 w-75 alert alert-danger" role="alert">You cannot make this request, your account is delinquent!</span>
                  }
              </>
            ) : male && female ? (
              <span className="m-2 w-75 alert alert-danger" role="alert">
                No stud certificate found.
              </span>
            ) : null}
          </div>
        </div>
      </div>
  </>:
  <div className="container mt-5 w-100 text-center">
  <div className="badge badge-lg bg-danger text-center fw-bold" style={{fontSize:25}}><i class="fas mx-2 fa-exclamation-circle"></i>Oops! You don't have a registered kennel. Please contact the PRC office.</div>
</div>
}
    
    </div>
  );
};

export default CreateLitterInspection;

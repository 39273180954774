import React, { useEffect, useState } from "react";
import { getAllFooterSettings,getAllFooterLinks,getAllFooterImages} from "../../api";

const Footer = () => {

  const [Links, setLinks] = useState([]);
  const [Images, setImages] = useState([]);
  const [settings, setSettings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllFooterLinks();
      setLinks(res && res);
      const res_ = await getAllFooterImages();
      setImages(res_ && res_);
      const fs = await getAllFooterSettings();
      setSettings(fs && fs);
    };
    fetchData();
  }, []);


  function convertHTMLEntity(text){
    const span = document.createElement('span');

    return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
        span.innerHTML = entity;
        return span.innerText;
    });
}
const ImageURL="https://admin.rottweiler.pk/website_images/footer_images/"

  return (
    <div className="footer-main mt-5 d-flex flex-column justify-content-center align-items-center" style={{minHeight:"80px"}}>
      {
        Images&&Images?.length?
        <div className="container bg-white w-md-75 my-lg-4 my-sm-2 my-md-3 p-md-3 p-2 mx-3 rounded">
        <div className="footer-images-main d-flex justify-content-center align-items-center">
        {Images &&
            Images?.map(({ image, position }, ind) =>
              position === "Latest Project" ? (
                <img 
                style={{maxWidth:'100px'}}
                key={ind} src={ImageURL+image} alt={image} />
              ) : null
            )}
        </div>
    </div>:null
      }
    <div className="Copyright">
    <p className="text-center text-light">
     {settings
          ?.filter(({ setting_name }) => setting_name == "copyright")
          ?.map((address) => (
            <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(address?.setting_value)}}></div>
            // <span>{address?.setting_value}</span>
          ))}
      </p>
    </div>  
    </div>
  );
};

export default Footer;

import React, { useContext, useEffect, useState } from 'react';
import { MDBDataTableV5,MDBBadge } from 'mdbreact';
import { Link } from 'react-router-dom';
import { getLittersByOwner, getMemberAccounts } from '../../api';
import { GlobalContext } from '../../context/store';
import Loader from '../../components/loader';
import { useNavigate } from "react-router-dom";

const LitterRegistration=()=>{
  const{user}=useContext(GlobalContext)
  const [data,setData]=useState([])
  const [loading,setLoading]=useState(false)

  let navigate = useNavigate();

//   const balance=async()=>{
//     const memberId=user?.account_id
//     const res=await getMemberAccounts(memberId);
//     let debit=res&&res?.map((item)=>(item.debit)).reduce((n,acc)=>(acc+n),0)
//     let credit=res&&res?.map((item)=>(item.credit)).reduce((n,acc)=>(acc+n),0)
//     if(credit-debit <= 0)
//     {
//       navigate(`/member/member-account-statement`);
//     }
// }

      const balance=async()=>{
        const memberId=user?.account_id
        const response=await getMemberAccounts(memberId);
        response.json().then(balance => ({
          balance: balance,
          status: response.status
        })
        ).then(res => {
          if(res.balance.balance > 0)
          {
            navigate(`/member/member-account-statement`);
          }
        });
      }

  useEffect(()=>{

    const fetchData=async()=>{
      setLoading(true)
      const res=await getLittersByOwner(user?.id)
      setData(res)
      setLoading(false)
    }
    fetchData()
    balance();
    },[])

  const datatable={
        columns: [
        
    ],
    rows:data?data:[]
    }
  
  

  const badgesData = {
    columns: [
      datatable.columns,
      {
        label: 'Dob',
        field: 'dob',
        width: 120,
      },
      
      {
        label: 'Sire',
        field: 'sire_name',
        width: 200,
      },
    
      {
        label: 'Dam	',
        field: 'dam_name',
        width: 200,
      },
  
      {
        label: 'Created at',
        field: 'created_at',
        sort: 'asc',
        width: 100,
      },
     
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Actions',
        field: 'actions',
      },
    ],
    rows: [
      ...datatable.rows.map((row, order) => ({
        ...row,
        dob: (
          new Date(row?.dob).toDateString()
        ),
        
        status: (
          <MDBBadge style={{color:'black',backgroundColor:'lightgrey'}} pill color='primary' className='p-1 px-2' key={order} searchvalue={order}>
            {row?.status}
          </MDBBadge>
        ),
        actions: (
        
            <Link to={`/member/litter-registration/${row?.id}`}><i style={{fontSize:18}} className="fas fa-eye"></i></Link>
       
        ),
        sire_name: (
            <Link to={`/dog/bio/${row?.sire_url}`} style={{textDecoration:'none'}}>{row.sire_name}</Link>
        ),
        dam_name: (
            <Link to={`/dog/bio/${row?.dam_url}`}  style={{textDecoration:'none'}}>{row.dam_name}</Link>
        ),
        created_at: (
          new Date(row?.created_at).toDateString()
        ),
      })),
    ],
  };

  console.log(badgesData,"data")
  return( 
      <>
      <div className="d-flex justify-content-between align-items-center">

      <h1>Litters Registered - List</h1>
      <Link to="create" className="btn btn-primary shadow"><i className="fas fa-plus-circle"></i>&nbsp;Create New Request</Link>
      </div>
      {
        !loading?
        <MDBDataTableV5 className="shadow mt-2 p-2 rounded " 
        hover entriesOptions={[10, 20, 25.,50]} entries={10}
         pagesAmount={10} data={badgesData} searchTop searchBottom={false} materialSearch />
         :<Loader size={50}/>
      }

   </>
  );
}

export default LitterRegistration;
import React, { useState, useEffect, useContext } from "react";
// import logo from '../../assets/Images/website_logo_large-300x241.png';
import placeHolder from "../../assets/Images/image_paceholder.png";
import { GlobalContext } from "../../context/store";
import { Outlet, useParams, useNavigate } from "react-router";
import { getAllDogsByOwner, getKennelsByOwner, getUserById } from "../../api";
import ProgenyChild from "../../components/progeny_child";
// import Kennels from "../../Kennels";
import Kennel from "../../components/kennel";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import { Link } from "react-router-dom";

const Profile = () => {
  const { user } = useContext(GlobalContext);
  const [user_data, setUserData] = useState();
  const [kennels, setKennels] = useState([]);
  const [dogs, setDogs] = useState([""]);
  const { id } = useParams();

  useEffect(async () => {
    if (!id && user) {
      setUserData(user);
    } else if (id) {
      const res = await getUserById(id);
      setUserData(res && res[0]);
    }
    const kenl_res = await getKennelsByOwner(id || user?.id);
    setKennels(kenl_res);
    const dogs_res = await getAllDogsByOwner(id || user?.id);
    setDogs(dogs_res);
  }, [user]);

  const hideModal=()=>{
    let el = document.getElementById('HideCollapse ');
    if (el.onclick) {
       el.onclick();
    } else if (el.click) {
       el.click();
    }
  }
  
  return (
    <div className="row mx-0 mt-5 bg-white rounded ">
      <div className="user-mob-menu col-md-12 mt-3">
        <p>
          <button
            class="butn butn-grey shadow-sm"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            id="HideCollapse"
          >
            Profile Menu
          </button>
        </p>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <ul className="p-2 rounded sider-bar-background">
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                onClick={()=>{hideModal()}}
                to={`/member/my-profile`}> My Profile </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link 
                 onClick={()=>{hideModal()}}
                to={`/member/stud-certificates`}> Stud Certificate </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link 
                 onClick={()=>{hideModal()}}
                to={`/member/litter-inspection`}>
                  {" "}
                  Litter Inspection{" "}
                </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/litter-registration`}>
                  {" "}
                  Litter Registration{" "}
                </Link>
              </li>
              {/* <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/member/request-for-hd-ed`}> HD ED Request </Link></li> */}
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/ownership-transfer`}> Transfer/Lease </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/my-show-survey-entries`}>
                  {" "}
                  Show Entries{" "}
                </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/my-dog-entries`}> Dogs Entered </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/profile-news-updates`}>
                  {" "}
                  News & Updates{" "}
                </Link>
              </li>
              <li className="user-mob-menu-item">
                <i className="fas fa-chevron-right"></i> &nbsp;{" "}
                <Link
                 onClick={()=>{hideModal()}}
                to={`/member/member-account-statement`}>
                  {" "}
                  Balance Statment{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="col-md-2 rounded">
        <Sidebar_Member />
      </div>
      <div className="col-md-10 my-2 p-2">
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;

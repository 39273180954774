import React from 'react'
import { Outlet } from 'react-router'
import Sidebar_Rules from '../../../layouts/SideNavigation_Rules'

const RulesAndRegulations = () => {
    return (
        <div className=" mb-2 container rounded bg-white ">
            {/* <h1 className="text-light py-2">Rules And Regulations</h1> */}
            <div className="row">
                <div className="col-md-2">
                    <Sidebar_Rules/>
                </div>
                <div className="col-md-8  rounded  p-4">
                    
                    <Outlet/>
                </div>
                <div className="col-md-2">
                    {/* <Sidebar_Rules/> */}
                </div>
            </div>

        </div>
    )
}

export default RulesAndRegulations

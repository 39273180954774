import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAboutBreed } from '../../../api'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AboutTheBreed = () => {

    const [data,setData]=useState([])
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        const fetchData=async()=>{
            setLoading(true)
            const res=await getAboutBreed();
            setData(res&&res)
            setLoading(false)          
        }
        fetchData()
    },[])
    return (
        <div className="">
            <h1>The Rottweiler Breed</h1>
            <hr/>
            <>
            {
                loading?
                [0,1].map((a)=>(
                    <div className=' my-3 p-2 border rounded'>
                        <div className='my-2'>
                    <Skeleton className='shadow-sm' height={40} width={'40%'}/>
                        </div>
                    <Skeleton className='shadow-sm' count={5} height={18}/>
                    <div className='mt-2'>
                    <Skeleton width={120} className='btn' height={40}/>
                    </div>
                </div>
                ))
              
                :
                data&&data?.map((item)=>(
                    <div className="bg-light rounded shadow-sm p-2 my-2">
                    <h3 className="txt-primary">{ item.title}</h3>
                    <p className="p-2 about-breed-preview">{item?.preview_text}</p>
                    <Link className="btn border btn-sm btn-primary text-light shadow-sm" to={item?.link?item?.link:"#"}>Read More</Link>
                </div>
                ))
                 
            }
            </>
        
        </div>
    )
}

export default AboutTheBreed

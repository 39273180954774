import React, { useEffect, useState } from "react";
import {
  getAlldogs,
  getAllFemaledogs,
  getAllMaledogs,
  getDogById,
  getDogIdBySlug,
  getPadigree,
} from "../../../../api";
// import placeHolder from "../../../../assets/Images/placeholderdog.jpg";
import PadigreeDetails from "../../../../components/padigree_details";
import loader from "../../../../assets/Images/loader.gif";
import { useParams } from "react-router";
import logo from '../../../../assets/Images/website_logo_large-300x241.png';
import Sidebar from "../../../../layouts/SideNavigation";
import Select from 'react-select';
const placeHolder ="https://admin.rottweiler.pk/website_images/dog_not_found.png"

const VirtualBreeding = () => {
  const {slug}=useParams()
  const [padigree, setPadigree] = useState([]);
  const [sirePadigree, setSirePadigree] = useState([]);
  const [dog, setDog] = useState([]);
  const [studs, setStuds] = useState([]);
  const [dams, setDams] = useState([]);
  const [sireData, setSireData] = useState({});
  const [damData, setDamData] = useState({});
  const [loading, setLoading] = useState(false);
  const [listloading, setListLoading] = useState(false);

  useEffect(async() => {
    const id=await getDogIdBySlug(slug)
    const dog_res = await getDogById(id&&id[0]?.id);
    setDog(dog_res&&dog_res[0]);
  }, [slug])

  useEffect(async () => {
    if (dog?.sex == "Male") {
      const res = await getPadigree(dog?.id);
      setSirePadigree(res[0]);
      setListLoading(true)
      const dams = await getAllFemaledogs();
      setDams(dams);
      setListLoading(false)
      console.log("studs", studs);
    } else if (dog?.sex == "Female") {
      const d_res = await getPadigree(dog?.id);
      setPadigree(d_res[0]);
      setListLoading(true)
      const studs = await getAllMaledogs();
      setStuds(studs);
      setListLoading(false)
    }
  }, [dog]);

  const sireChange = async (id) => {
    setLoading(true);
    const res = await getDogById(id);
    setSireData(res&&res[0]);
    const res_s = await getPadigree(sireData?.id);
    setSirePadigree(res_s && res_s[0]);
    setLoading(false);
    lineBreeding(padigree,sirePadigree)
    console.log(id,"working")
  };

  const damChange = async (id) => {
    setLoading(true);
    const res = await getDogById(id);
    setDamData(res&&res[0]);
    const d_res = await getPadigree(damData?.id);
    setPadigree(d_res && d_res[0]);
      setLoading(false);
      lineBreeding()
  };

  const lineBreeding=(padigree,sirePadigree)=>{
    let line_arr=[];
    if(sirePadigree?.father_id && sirePadigree?.father_id!=null == padigree?.father_id && sirePadigree?.father_id!= null ){
      line_arr.push({id:sirePadigree?.father_id,data:"2-2",dog_name:sirePadigree?.father})
    }
    if(sirePadigree?.mother_id&&sirePadigree?.mother_id!=null==padigree?.mother_id && padigree?.mother_id!= null ){
      line_arr.push({id:padigree?.mother_id,data:"2-2",dog_name:padigree?.mother})
    }
    console.log("line breeding",line_arr)
  }


  return (
    <div className="row mx-0 mt-5 bg-white rounded p-2 ">
    <div className="col-md-2 mt-5  rounded">
      <Sidebar id={slug}/>
    </div>
    <div className="col-md-8 my-2 px-0 ">
    <div className="d-flex flex-column justify-content-center ">
      <h3 className="my-3 bg-light p-2 rounded shadow-sm w-100 ">
        {dog && dog?.dog_name}

        {dog && dog.KP ? (
          <span className="breed-kp px-2"> ( KP {dog?.KP} ) </span>
        ) : null}

        <i className={`fas ${dog?.sex == "Male" ? "fa-mars" : "fa-venus"}`}></i>
        <span className="breed-kp mx-5">{dog?.is_champ == 'Yes' ? 'Champion' : ''}</span>
      </h3>
      <div className="row w-100 justify-content-between">
        <div className="col-md-5 shadow-sm bg-light p-2">
          <label className="text-dark">
            <b>Stud:</b>
          </label>
        
          {listloading?<img style={{marginLeft:20,height:25}} src={loader}/>:dog?.sex == "Female" ? (
  
             <Select
             options={studs.map(item=>({label:item.dog_name,value:item.id}))}
             onChange={(e) => sireChange(e?.value)}
            />
            
          ) : (
            <input
              type="text"
              value={dog && dog?.dog_name}
              id={dog && dog?.id}
              className="form-control bg-white"
              readOnly
            />
          )}
        </div>

        <div className="col-md-5 shadow-sm bg-light p-2">
          <label className="text-dark">
            <b>Dam:</b>
          </label>
          {listloading?<img style={{marginLeft:20,height:25}} src={loader}/>:dog?.sex == "Male" ? (
            <Select
            options={dams.map(item=>({label:item.dog_name,value:item.id}))}
            onChange={(e) => damChange(e?.value)}
          />
          ) : (
            <input
              type="text"
              value={dog && dog?.dog_name}
              id={dog && dog?.id}
              className="form-control bg-white"
              readOnly
            />
          )}
        </div>
        <div className="container px-2 mt-3">
          <button
            className="btn btn-danger  shadow-sm"
            onClick={() => {
              setDamData({});
              setSireData({});
            }}
          >
            Clear
          </button>
        </div>
      </div>
      {loading ? (
        <div className="w-100 d-flex justify-content-center align-items-center">
          <img className="loader" src={loader} />
        </div>
      ) : sireData?.id || damData?.id ? (
        <div id="tree">
          <div className="branch">
            <div className="entry">
              <span>
                <div id={padigree?.father_id && padigree?.father_id}>
                  <div className="paddigery-img-ii">
                    <img
                      src={
                        (padigree?.father_image && padigree?.father_image) ||
                        placeHolder
                      }
                    />
                  </div>
                  <div className="paddigery-text py-2">
                    <h6 className="font-weight-bold">
                      {dog?.sex == "Male"
                        ? dog.dog_name
                        : sireData?.dog_name?sireData?.dog_name + "...." : "Unknown"}
                    </h6>
                  </div>
                </div>
                <PadigreeDetails
                  dog_id={dog?.sex ==="Male" ? dog.id : sireData?.id}
                />
              </span>
              <div className="branch">
                <div className="entry">
                  <span>
                    <div
                      style={{ zIndex: 1000 }}
                      id={padigree?.father_id && padigree?.father_id}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="paddigery-img-i">
                          <img
                            src={
                              sirePadigree && sirePadigree.father_image
                                ? sirePadigree?.father_image
                                : placeHolder
                            }
                          />
                        </div>
                        <div className="paddigery-text py-2">
                          <h6 className="font-weight-bold">
                            {sirePadigree &&
                              sirePadigree?.father? sirePadigree?.father?.slice(0, 22) + "....":
                              "Unknown"}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <PadigreeDetails  dog_id={sirePadigree?.father_id} />
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                sirePadigree && sirePadigree.fatherFather_image
                                  ? sirePadigree?.fatherFather_image
                                  : placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {sirePadigree &&
                                sirePadigree?.fatherFather?sirePadigree &&
                                sirePadigree?.fatherFather?.slice(0, 22) +
                                  "....": 
                                "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails
                           dog_id={sirePadigree?.fatherFather_id}
                        />
                      </span>
                    </div>
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                sirePadigree?.fatherMother_image
                                  ? sirePadigree?.fatherMother_image
                                  : placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {sirePadigree?.fatherMother?sirePadigree?.fatherMother?.slice(0, 22) +
                                "....": "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails
                           dog_id={sirePadigree?.fatherMother_id}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="entry">
                  <span>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="paddigery-img-i">
                        <img
                          src={
                            sirePadigree&&
                              sirePadigree?.mother_image
                            || placeHolder
                          }
                        />
                      </div>
                      <div className="paddigery-text py-2">
                        <h6 className="font-weight-bold">
                          {sirePadigree?.mother?sirePadigree?.mother?.slice(0, 22) + "...." :
                            "Unknown"}
                        </h6>
                      </div>
                    </div>
                    <PadigreeDetails  dog_id={sirePadigree?.mother_id} />
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                sirePadigree&&
                                  sirePadigree?.MotherFather_image
                                  || placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {sirePadigree?.MotherFather?sirePadigree?.MotherFather?.slice(0, 22) +
                                "...." : "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails
                           dog_id={sirePadigree?.MotherFather_id}
                        />
                      </span>
                    </div>
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                (sirePadigree &&
                                  sirePadigree?.motherMother_image) ||
                                placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {sirePadigree?.motherMother?sirePadigree?.motherMother?.slice(0, 13) +
                                "...." : "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails
                           dog_id={sirePadigree?.motherMother_id}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="entry">
              <span>
                <div>
                  <div className="paddigery-img-ii">
                    <img
                      src={
                        (padigree?.mother_image && padigree?.mother_image) ||
                        placeHolder
                      }
                    />
                  </div>
                  <div className="paddigery-text py-2">
                    <h6 className="font-weight-bold">
                      {dog?.sex == "Female"
                        ? dog.dog_name
                        : damData?.dog_name?damData?.dog_name?.slice(0, 10) + "...." : "Unknown"}
                    </h6>
                  </div>
                </div>
                <PadigreeDetails
                   dog_id={dog?.sex == "Female" ? dog.id : damData?.id || 0}
                />
              </span>
              <div className="branch">
                <div className="entry">
                  <span>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="paddigery-img-i">
                        <img
                          src={
                            (padigree && padigree?.father_image) || placeHolder
                          }
                        />
                      </div>
                      <div className="paddigery-text py-2">
                        <h6 className="font-weight-bold">
                          {padigree?.father?padigree?.father?.slice(0, 22) + "...." : "Unknown"}
                        </h6>
                      </div>
                    </div>
                    <PadigreeDetails  dog_id={padigree?.father_id} />
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                (padigree && padigree?.fatherFather_image) ||
                                placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {padigree&&padigree?.fatherFather?padigree&&padigree?.fatherFather?.slice(0, 22) + "...." :
                                "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails  dog_id={padigree?.fatherFather_id} />
                      </span>
                    </div>
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                (padigree && padigree?.fatherMother_image) ||
                                placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {padigree?.fatherMother?padigree?.fatherMother?.slice(0, 22) + "...." :
                                "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails  dog_id={padigree?.fatherMother_id} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="entry">
                  <span>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="paddigery-img-i">
                        <img
                          src={
                            (padigree && padigree?.mother_image) || placeHolder
                          }
                        />
                      </div>
                      <div className="paddigery-text py-2">
                        <h6 className="font-weight-bold">
                          {padigree?.mother?padigree?.mother?.slice(0, 22) + "...." : "Unknown"}
                        </h6>
                      </div>
                    </div>
                    <PadigreeDetails  dog_id={padigree?.mother_id} />
                  </span>
                  <div className="branch">
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                (padigree && padigree?.MotherFather_image) ||
                                placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                            {padigree&&padigree?.MotherFather?padigree&&padigree?.MotherFather?.slice(0, 22) + "...." :
                                "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails  dog_id={padigree?.MotherFather_id} />
                      </span>
                    </div>
                    <div className="entry">
                      <span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="paddigery-img-iii">
                            <img
                              src={
                                (padigree && padigree?.motherMother_image) ||
                                placeHolder
                              }
                            />
                          </div>
                          <div className="paddigery-text py-2">
                            <h6 className="font-weight-bold">
                              {padigree?.motherMother?padigree?.motherMother?.slice(0, 13) + "...." :
                                "Unknown"}
                            </h6>
                          </div>
                        </div>
                        <PadigreeDetails  dog_id={padigree?.motherMother_id} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
      {/* <img className="rounded mt-2 w-100" src={sideimg} /> */}
    </div>
  </div>
  );
};

export default VirtualBreeding;

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getJudgesBio, getTeamBioBySlug } from '../../../../api';
import placeHolder from'../../../../assets/Images/userPlaceholder.png'
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const JudgesBio = () => {

    const {slug}=useParams();
    const[bio,setBio]=useState({})

    useEffect(()=>{

        const fetchData=async()=>{
            const res=await getJudgesBio(slug)
            setBio(res&&res[0])
        }
        fetchData()

    },[slug])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
    
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    const imgURL="https://admin.rottweiler.pk/public/judge_images/";

    return (
        <div className="container mt-5">
        <div id="circle-shape-example">
            <div>{
                    bio?.image?
                    <div className='curve p-5'>
                    <LazyLoadImage
                          alt={bio?.full_name}
                          effect="blur"
                          maxHeight={200}
                          aiamgelt="Card image cap"
                          className="card-img-top p-2 rounded-circle"
                          width={"auto"}
                          src={imgURL + bio?.image}
                        />
                    </div>
             :
                <img src={placeHolder} alt={bio?.full_name} class="curve p-5"/>

                }
            </div>
        <div><h1>{bio?.full_name}</h1></div>
  <div dangerouslySetInnerHTML={{__html: convertHTMLEntity(bio?.description)}}></div>
</div>
        </div>
    )
}

export default JudgesBio

import React, { useState, useEffect, useContext } from "react";
import { memberSearch, getAllMembers } from "../../api";
import placeHolder from "../../assets/Images/userPlaceholder.png";
import { getUnique } from "../../utils";
import loader from "../../assets/Images/loader.gif";
import logo from '../../assets/Images/website_logo_large-300x241.png';
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import Sidebar_Home from "../../layouts/SideNavigation_Home";
import Sidebar_Member from "../../layouts/SideNavigation_Member";
import { GlobalContext } from "../../context/store";
import VerticalAdverts from "../../components/verticalAdverts";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Members = () => {
  const [MemberData, setMemberData] = useState([]);
  const [searchLetters, setLetters] = useState([]);
  const [Member, setMember] = useState();
  const [loading, setLoading] = useState(false);
  const {user}=useContext(GlobalContext)

  useEffect(async () => {
    setLoading(true)
    const data = await getAllMembers();
    setMember(data);
    let letter_arr = [];
    if (MemberData) {
      for (let i of MemberData) {
        if(i?.total !== undefined)
        {
          letter_arr.push(i?.first_name?.charAt(0));
        }
      }
    }
    setLetters(getUnique(letter_arr));
    setLoading(false)
  }, []);

  useEffect(async () => {
    setLoading(true)
    setMemberData(Member);
    let letter_arr = [];
    if (MemberData) {
      for (let i of MemberData) {
        letter_arr.push(i?.first_name?.charAt(0));
      }
    }
    setLetters(getUnique(letter_arr));
    setLoading(false)
  }, [Member]);

  const filterByFirstLtr = (letter) => {
    setLoading(true);
    if (letter == "*") {
      setMemberData(Member);
    }
    else {
      const data = Member?.filter((data) => data.first_name.charAt(0) == letter);
      setMemberData(data);
    }
      setLoading(false);
  };

  const handleChange = async (e) => {
    setLoading(true);
    let result = await memberSearch(e);
    console.log(result,"RRRRR")
    if (e === "") {
      setMemberData(Member);
      let letter_arr = [];
      if (MemberData) {
        for (let i of MemberData) {
          letter_arr.push(i?.first_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
  
    } else {
      setMemberData(result);
      let letter_arr = [];
      if (MemberData) {
        for (let i of MemberData) {
          letter_arr.push(i?.first_name?.charAt(0));
        }
      }
      setLetters(getUnique(letter_arr));
    }
    setLoading(false);
  };

  return (
    <div className="row  mx-auto mt-5   bg-white rounded p-2 ">
    <div className="col-md-2  rounded">
    {
      !user?
      <Sidebar_Home/>
      :<Sidebar_Member/>
      }
    </div>
    <div className="col-md-8 my-2 px-0 ">
      <h1>Pakistan Rottweiler Club Members</h1>
    <>
      <div className="container mt-3 w-100 d-flex justify-content-end px-2">
        <input
          type="search"
          className="form-control  search-input   my-3 mx-3"
          PlaceHolder="Search by Kennel Name , User Name, Suffix , Prefix and City"
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <div className="container  w-100 flex-wrap d-flex justify-content-end px-2 ">
        <button
          onClick={() => {
            filterByFirstLtr("*");
          }}
          className="filterBtn my-2 btn shadow-sm fw-bold border"
        >
          All
        </button>
        {searchLetters &&
          searchLetters.sort().map((item, ind) => (
            <button
              key={ind}
              onClick={() => {
                filterByFirstLtr(item);
              }}
              className="filterBtn my-2  btn shadow-sm fw-bold border"
              >
              {item}
            </button>
          ))}
            {/* <button
          onClick={() => {
            filterByFirstLtr("asc");
          }}
          className="filterBtn my-2 "
        >
          A - Z
        </button>
            <button
          onClick={() => {
            filterByFirstLtr("dec");
          }}
          className="filterBtn my-2 "
        >
          Z - A
        </button> */}
      </div>
      <div className="d-flex justify-content-center align-items-center flex-wrap">
        {loading ? (
        <div className="card shadow-sm my-2 mx-2 p-2"
         style={{ width: "15rem" }}>
           <Skeleton height={150}/>
           <Skeleton height={30}/>
           <Skeleton height={20} width={'50%'}/>
          </div>
        ) : MemberData && MemberData ? (
          MemberData?.map(({friendly_url,first_name, membership_no,last_name,photo}) => (
            <Link to={`/member-profile/${friendly_url}`} style={{textDecoration:"none"}}>
            <div className="card shadow-sm my-2 mx-2" style={{ width: "15rem" }}>
              <div className="breed-img">
                {photo != "" && photo != null ? (
                    <LazyLoadImage
                    alt={""}
                    className="card-img-top"
                    maxHeight={200}
                    effect="blur"
                    src={
                      "https://admin.rottweiler.pk/public/members/profile_pic/" +
                      photo
                    } // use normal <img> attributes as props
                  />
                ) : (
                  <img
                    className="card-img-top"
                    src={placeHolder}
                    alt="Card image cap"
                  />
                )}
              </div>
              <div className="card-body ">
                <h5 className="card-title ">{first_name + ' ' + last_name}</h5>
                <h6 className="text-dark fw-bold">Membership #: {membership_no}</h6>
                {/* <a href="#" className="btn-grad">Go somewhere</a> */}
              </div>
            </div>
            </Link>
          ))
        ) : null}
      </div>
    </>
    </div>
    <div className="col-md-2  rounded justify-content-center align-items-center">
                  <VerticalAdverts/>
    </div>
  </div>
  );
};

export default Members;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getAccountHead,
  getAllClasses,
  getAllDogsByOwner,
  getCatalogId,
  getDogById,
  getEventBySlug,
  getMemberAccounts,
  showDogSave,
} from "../../../api";
import { GlobalContext } from "../../../context/store";
import { useNavigate } from "react-router-dom";

const ShowEntryForm = () => {
  const [dogs, setDogs] = useState([]);
  const [current_dog, setCurrent_dog] = useState({});
  const [show_dogs, setShow_dogs] = useState([]);
  const [dogID, setDogId] = useState(null);
  const [event, setEvent] = useState({});
  const [classes, setClasses] = useState([]);
  const [balance, setBalance] = useState(0);
  const [isCliked, setCliked] = useState(false);
  const dogRef = useRef(null);
  const dogClassRef = useRef(null);
  const { slug } = useParams();

  const { user } = useContext(GlobalContext);
  let navigate = useNavigate();



  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await getAllDogsByOwner(user?.id);
  //     setDogs(res);
  //     console.log(res,".....res....")
  //     const memberId = user?.account_id;
  //     console.log(memberId,".....memberId....")
  //     const transactions = await getMemberAccounts(memberId);
  //     console.log(transactions.data,".....transactions....")
  //     let debit =
  //       transactions &&
  //       transactions?.map((item) => item.debit).reduce((n, acc) => acc + n, 0);
  //     let credit =
  //       transactions &&
  //       transactions?.map((item) => item.credit).reduce((n, acc) => acc + n, 0);
  //       setBalance(credit - debit);
  //       console.log(credit,"........credit........")
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllDogsByOwner(user?.id);
      setDogs(res);
      console.log(res, ".....res....")
      const memberId = user?.account_id
      const transactions = await getMemberAccounts(memberId);
      transactions.json().then(balance => ({
        balance: balance,
        status: transactions.status
      })
      ).then(res => {
        if (res.balance.balance >= 0) {
          // setdelinquent(false);
          setBalance(Math.abs(res.balance.balance));      
          navigate(`/member/member-account-statement`);
        }
        else {
          // setdelinquent(true);
          setBalance(- res.balance.balance);
        }
      });
    };
    fetchData();
  }, []);

  useEffect(async () => {
    const data_e = await getEventBySlug(slug);
    setEvent(data_e && data_e[0]);
  }, [slug]);

  useEffect(() => {
    var cd = monthDiff(current_dog?.dob, event?.start_date);
    AddDogClass(current_dog?.id, cd[0]?.id, cd);
  }, [dogClassRef.current?.value]);

  useEffect(async () => {
    const cls_res = await getAllClasses();
    setClasses(cls_res && cls_res);
  }, [event]);

  useEffect(async () => {
    if (show_dogs?.length === 0) {
      // const memberId = user?.account_id;
      // const transactions = await getMemberAccounts(memberId);
      // let debit =
      //   transactions &&
      //   transactions?.map((item) => item.debit).reduce((n, acc) => acc + n, 0);
      // let credit =
      //   transactions &&
      //   transactions?.map((item) => item.credit).reduce((n, acc) => acc + n, 0);
      // setBalance(credit - debit);

      const memberId = user?.account_id
      const transactions = await getMemberAccounts(memberId);
      transactions.json().then(balance => ({
        balance: balance,
        status: transactions.status
      })
      ).then(res => {
        if (res.balance.balance < 0) {
          // setdelinquent(false);
          setBalance(Math.abs(res.balance.balance));
        }
        else {
          // setdelinquent(true);
          setBalance(- res.balance.balance);
        }
      });
    }

  }, [show_dogs]);

  function monthDiff(d1, d2) {
    var months;
    months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    months -= new Date(d1).getMonth() + 1;
    months += new Date(d2).getMonth();
    const res =
      classes &&
      classes?.filter(
        ({ to_age, from_age }) => months >= from_age && months <= to_age
      );
    return res;
  }

  const DeleteDog = (did) => {
    const r = show_dogs?.filter(({ id }) => id == did);
    const del = show_dogs?.filter(({ id }) => id != did);
    setShow_dogs(del);
    setDogs([...dogs, { ...r[0], dog_id: r[0]?.id }]);
    notify("Dog delete from the list !");
  };

  const AddDog = async (id) => {
    if (id) {
      const res = await getDogById(id);
      setCurrent_dog(res && res[0]);
      setShow_dogs([...show_dogs, { ...(res && res[0]), catalog_id: "" }]);
      setDogs(dogs?.filter((dog) => dog?.dog_id != id));
      dogRef.current.clearValue();
    } else {
      notify("Please select dog!");
    }
  };

  const AddDogClass = async (id, dogClass, dogClasses) => {
    var cat_id;
    const dog_gender = show_dogs?.filter((dog) => dog?.id === id)[0]?.sex;
    /// Get catalog id from data base
    const c_res = await getCatalogId({
      showId: event?.id,
      classId: dogClass,
      dogSex: dog_gender,
    });
    /// get id from catalog array
    var db_cat_id = c_res && c_res[0]?.catalog_id;

    // initial catalog ids from class api
    const cat_ids = dogClasses?.filter((cls) => cls.id == dogClass)[0];

    ///check if catalog id from db
    if (db_cat_id) {
      cat_id = parseInt(db_cat_id) + 1;
    }
    ////If catalog id doesn't found in api response
    else {
      ///extract catalog if by gender
      if (dog_gender === "Female") {
        //set cat_id
        cat_id = cat_ids?.female_no_from;
      } else {
        //set cat_id
        cat_id = cat_ids?.male_no_from;
      }
    }

    if (id && dogClass) {
      var res = show_dogs?.filter((dog) => dog.id == id)[0];
      var res_ = show_dogs;
      if (res && "classname" in res) {
        for (let i in res_) {
          if (res_[i]?.id == id) {
            res_[i].classname = dogClass;
            res_[i].catalog_id = cat_id;
            if (res_[i].invalid) {
              res_[i].invalid = false;
            }
          }
        }
        setShow_dogs([...res_]);
      } else {
        const ad = { ...res, classname: dogClass, catalog_id: cat_id };
        for (let i in res_) {
          if (res_[i]?.id == id) {
            res_[i] = ad;
            if (res_[i].invalid) {
              res_[i].invalid = false;
            }
          }
        }
        setShow_dogs([...res_]);
      }
    } else {
      notify("Please select dog!");
    }
  };

  const saveDog = async () => {
    setCliked(true);
    const d = new Date();
      let day = d.getFullYear(d.getTime())+'-'+d.getMonth(d.getTime())+'-'+d.getDate(d.getTime())+' '+d.getHours(d.getTime())+':'+d.getMinutes(d.getTime())+':'+d.getSeconds(d.getTime());
    if (validate()) {
      let dt = show_dogs?.map(({ id, classname, owners, catalog_id }) => ({
        dog_id: id,
        show_id: event?.id,
        class: classname,
        current_owner: owners,
        catalog_id: catalog_id,
        created_by: user?.id,
        created_at : day,
        updated_at : day
      }));
      
      
      const dd = await getAccountHead(user?.id);
      const res = await showDogSave({
        data: dt,
        debit: {
          account_id: user?.account_id,
          account_head: dd[1]?.company_head,
          debit: event?.fee,
          remarks: "Dog show entry for " + event?.title,
          ref_id: event?.id,
          ref_model: "Dog Show",
          created_at : day,
          updated_at : day
        }
      });
      if (res) {
        setShow_dogs([]);
        notify("Dogs save successfully !");
      }
    }
    setCliked(false);
  };

  const notify = (msg) =>
    toast.info(msg, {
      // position: "top-center",
      // autoClose: 2000,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,

      style: { zIndex: "10000000" },

    });

  const validate = () => {
    let dogs = show_dogs;
    for (let j in dogs) {
      if (!dogs[j].classname) {
        dogs[j] = { ...dogs[j], invalid: true };
        setShow_dogs([...dogs]);
      } else {
        if (!("invalid" in dogs[j])) {
          dogs[j] = { ...dogs[j], invalid: false };
          setShow_dogs([...dogs]);
        } else {
          dogs[j].invalid = { ...dogs[j], invalid: false };
          setShow_dogs([...dogs]);
        }
      }
    }
    var d = dogs.filter((dog) => dog?.invalid === true);
    if (d.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <h1>Show Entry Form</h1>
      <div className="form container">
        <div className="row">
          <div className="col-md-4 my-2">
            <input
              className="form-control"
              type="text"
              disabled
              value={user?.first_name + " " + user?.last_name}
            />
          </div>
          <div className="col-md-6 my-2">
            <Select
              ref={dogRef}
              options={
                dogs &&
                dogs?.map((item) => ({
                  label: item.dog_name,
                  value: item?.dog_id,
                }))
              }
              onChange={(e) => {
                setDogId(e?.value);
              }}
            />
          </div>
          <div className="col-md-2 my-2">
            <button
              className="butn butn-grey shadow-sm rounded"
              onClick={() => {
                AddDog(dogID);
              }}
            >
              Add
            </button>
          </div>
          {show_dogs &&
            show_dogs?.map(
              (
                {
                  invalid,
                  sire_KP,
                  dam_KP,
                  id,
                  dog_name,
                  sex,
                  dob,
                  breeder,
                  KP,
                  dam_name,
                  sire_name,
                  registration,
                  microchip,
                  elbows,
                  hip,
                  dam_reg_no,
                  sire_reg_no,
                },
                _ind
              ) => (
                <div
                  className="col-md-10 py-2 mx-2 my-3 shadow-sm rounded border bg-light show-entry-main"
                  key={_ind}
                >
                  <span
                    onClick={() => {
                      DeleteDog(id);
                    }}
                    style={{ left: "100%", opacity: 0.8 }}
                    className="close-btn shadow px-2 py-1 bg-danger text-light fw-bold position-relative"
                  >
                    X
                  </span>
                  <div className="row  ">
                    {invalid === true ? (
                      <div class="mx-2 alert alert-warning col-10" role="alert">
                        <i class="fas fa-exclamation-triangle mx-2"></i> Please
                        select class!
                      </div>
                    ) : null}
                    {monthDiff(dob, event?.start_date)?.length == 0 ? (
                      <div class="mx-2 alert alert-danger col-10" role="alert">
                        <i class="fas fa-exclamation-circle mx-2"></i>
                        Under Age
                      </div>
                    ) : null}
                    <h2>
                      {dog_name}
                      <i
                        class={`mx-2 fas ${sex == "Male" ? "fa-mars" : "fa-venus"
                          }`}
                      ></i>
                    </h2>
                    <div className="col-md-4 my-3 ">
                      <div>
                        <label className="fw-bold">Date Of Birth:</label>
                        <span className="mx-2">
                          {new Date(dob)?.toDateString() || "-"}
                        </span>
                      </div>
                      <div>
                        <label className="fw-bold">Sire:</label>
                        <span className="mx-2">{sire_name}</span>
                      </div>
                      <div>
                        <label className="fw-bold">Dam:</label>
                        <span className="mx-2">{dam_name}</span>
                      </div>
                      <div>
                        <label className="fw-bold">Breeder:</label>
                        <span className="mx-2">{breeder || "-"}</span>
                      </div>
                      <div className="d-flex my-2">
                        <label className="fw-bold ">Class:</label>
                        {monthDiff(dob, event?.start_date)?.length > 1 ? (
                          <Select
                            className="mx-2 "
                            options={monthDiff(dob, event?.start_date)?.map(
                              (item) => ({ label: item.class, value: item.id })
                            )}
                            onChange={(e) => {
                              AddDogClass(
                                id,
                                e.value,
                                monthDiff(dob, event?.start_date)
                              );
                            }}
                          />
                        ) : (
                          <>
                            <span className="mx-2">
                              {monthDiff(dob, event?.start_date)[0]?.class}
                            </span>
                            <input
                              type="hidden"
                              ref={dogClassRef}
                              id={id}
                              value={JSON.stringify(
                                monthDiff(dob, event?.start_date)
                              )}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 my-3 ">
                      <div>
                        <label className="fw-bold">Reg No:</label>
                        <span className="mx-2">
                          {registration || KP ? "KP: " + KP : "-"}
                        </span>
                      </div>
                      <div>
                        <label className="fw-bold">Sire Reg No:</label>
                        <span className="mx-2">
                          {sire_reg_no || sire_KP ? "KP: " + sire_KP : "-"}
                        </span>
                      </div>
                      <div>
                        <label className="fw-bold">Dam Reg No:</label>
                        <span className="mx-2">
                          {dam_reg_no || dam_KP ? "KP: " + dam_KP : "-"}
                        </span>
                      </div>
                      <div>
                        <label className="fw-bold">HD:</label>
                        <span className="mx-2">{hip || "-"}</span>
                      </div>
                    </div>
                    <div className="col-md-4 my-3 ">
                      <div>
                        <label className="fw-bold">Tattoo/Microchip:</label>
                        <span className="mx-2">{microchip || "-"}</span>
                      </div>
                      <div>
                        <label className="fw-bold">ED: </label>
                        <span className="mx-2">{elbows || "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          <div className="d-flex justify-content-end col-md-10">
            {show_dogs?.length ? (
              isCliked ? (
                <button className="shadow-sm btn btn-primary shadow">
                  Submit
                  {isCliked ? <i class="fa fa-spinner fa-spin"></i> : null}
                </button>
              ) : (
                <button
                  onClick={() => saveDog()}
                  className="shadow-sm btn btn-primary shadow"
                >
                  Submit
                  {isCliked ? <i class="fa mx-3 fa-spinner fa-spin"></i> : null}
                </button>
              )
            ) : null}
          </div>
        </div>
      </div>
      <div className="user-balance mx-3 border rounded shadow-sm col-md-10 shadow p-2 mt-5">
        <h5 className="fw-bold text-dark">
          Owner(s) Details - Current Balance:{" "}
          <span className="balance-amount">
            <span className="fw-normal">Rs. </span>
            {/* {balance ? balance.toLocaleString() : 0} */}
            {balance ? balance : 0}
          </span>{" "}
        </h5>
        <div className="row my-2">
          <div className="col-md-6">
            <span className="fw-bold mb-2 ">Name:</span>
            <span className="mx-2">
              {user?.first_name + " " + user?.last_name}
            </span>
          </div>
          <div className="col-md-6">
            <span className="fw-bold  mb-2">Email:</span>
            <span className="mx-2">{user?.email}</span>
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-6">
            <span className="fw-bold  mb-2">Phone:</span>
            <span className="mx-2">{user?.phone}</span>
          </div>
          <div className="col-md-6">
            <span className="fw-bold  mb-2">Address: </span>
            <span className="mx-2">{user?.address}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ShowEntryForm;
import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom';
import { GlobalContext } from '../../context/store';
import logo from '../../assets/Images/rottweiler-logo.png'

const Sidebar = ({id}) => {
    const {dogId}=useContext(GlobalContext)

    const hideModal=()=>{
        let el = document.getElementById('HideCollapse ');
        if (el.onclick) {
           el.onclick();
        } else if (el.click) {
           el.click();
        }
      }
      
    return (
        <>
          <div className="dog-mob-menu col-md-12 mt-3">
        <p>
          <button
            class="butn butn-grey shadow-sm"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            id="HideCollapse"
          >
            Menu
          </button>
        </p>
        <div class="collapse" id="collapseExample">
          <div class="card card-body">
            <ul className="p-2 rounded sider-bar-background">
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/bio/${id}`}> Basic Data </Link></li>
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/ztp/${id}`}> ZTP </Link></li>
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/virtualbreeding/${id}`}> Virtual Breeding </Link></li>
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/siblings/${id}`}> Siblings </Link></li>
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/progeny/${id}`}> Progeny </Link></li>
              <li className="user-mob-menu-item"><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/pedigree/${id}`}> Pedigree </Link></li>
            </ul>
          </div>
        </div>
      </div>

        <div className="side-menu ">
            <div className="logo">
                <Link to="/">
            <img src={logo}/>
            </Link>
            </div>
            <hr/>
        <ul className="mx-2 p-2 rounded sider-bar-background">
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/bio/${id}`}> Basic Data </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/ztp/${id}`}> ZTP </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/virtualbreeding/${id}`}> Virtual Breeding </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/siblings/${id}`}> Siblings </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/progeny/${id}`}> Progeny </Link></li>
            <li><i className="fas fa-chevron-right"></i> &nbsp; <Link to={`/dog/pedigree/${id}`}> Pedigree </Link></li>
        </ul>
    </div>
    </>
    )
}

export default Sidebar

import { createContext, useReducer } from "react";
import AppReducer from "../reducers/AppReducer";

const initialState = {
  dogs: [],
  dogId: 0,
  user: null,
  imageFile: null,
  IsMobileMenu: false,
  isSearch: false,
};

export const GlobalContext = createContext(initialState);
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const setAuthUser = (data) => {
    dispatch({
      type: "SET_USER",
      payload: data,
    });
  };

  const setClubFees = (data) => {
    dispatch({
      type: "SET_CLUB_FEES",
      payload: data,
    });
  };

  const setImageFile = (data) => {
    dispatch({
      type: "SET_IMG",
      payload: data,
    });
  };

  const loadDogs = (data) => {
    dispatch({
      type: "SET_DOGS",
      payload: data,
    });
  };
  const setDogId = (id) => {
    dispatch({
      type: "SET_DOG_ID",
      payload: id,
    });
  };
  const setMobileMenu = (status) => {
    dispatch({
      type: "SET_MOB_MENU",
      payload: status,
    });
  };
  const setSearch = (status) => {
    dispatch({
      type: "SET_MOB_SEARCH",
      payload: status,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        setDogId,
        dogId: state.dogId,
        loadDogs,
        dogs: state.dogs,
        setAuthUser,
        user: state.user,
        setClubFees,
        club_fees: state.club_fees,
        setImageFile,
        imageFile: state.imageFile,
        setMobileMenu,
        isMobileMenu: state.isMobileMenu,
        setSearch,
        isSearch: state.isSearch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

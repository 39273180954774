import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { updateDogrofile, updateUserProfile } from '../api'

const EditDogImage = (props) => {
  const [image, setImage] = useState('')
  const { url } = props


  const updateImage = async (e) => {
    var formdata = new FormData();
    formdata.append("dog_img", image);
    const res = await updateDogrofile(url, formdata)
    if (res.status == 200) {
      toast.success('Updated profile successfully', {
        // position: "top-center",
        // autoClose: 2000,
        // hideProgressBar: false,
        // closeOnClick: true,
        // pauseOnHover: true,
        // draggable: true,
        // progress: undefined,

        style: { zIndex: "10000000" },
      }, hideModal());
    }


  }
  const hideModal = () => {
    let el = document.getElementById('closeModal');
    if (el.onclick) {
      el.onclick();
    } else if (el.click) {
      el.click();
    }
  }

  return (
    <>

      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Edit Image</h5>
              <button type="button" class="close btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <input type="file" onChange={(e) => { setImage(e.target.files[0]) }} className="form-control" />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" id="closeModal" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" onClick={(e) => { updateImage(e) }}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditDogImage

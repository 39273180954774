import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getFeeStructure } from '../../../api'

const SubscriptionAndFeeStructure = () => {

    const [data,setData]=useState([])
    const [feeStructure,setFeeStructure]=useState([])

    useEffect(()=>{
        const fetchData=async()=>{
            const res=await getFeeStructure()
            setData(res)
            console.log(data,"feeStructure")

        }
        fetchData()
    },[])

    useEffect(() => {
        changeData()
    }, [data])

    const changeData=()=>{
        var dd=[];
        if(data){
            var d=Object.keys(data?.reduce((r,{group_name}) => (r[group_name]='', r) , {}))
        
        for(let a of d){
            dd.push({group_name:a,fees:[]})
        }
        for(let i in dd){
            for(let d_ of data){
                if(d_?.group_name==d[i]){
                    dd[i]?.fees?.push(d_)
                }
            }
        }
    }
        setFeeStructure(dd&&dd)
    }    


    return (
        <div>
            <div className="d-flex justify-content-between">
            <h1>Subscription And Fee Structure</h1>
            <div>
            <Link className="btn btn-primary" to="/about">Back</Link>
            </div>
            </div>
              
                {
                    feeStructure&&feeStructure?.map(({group_name,fees},_i)=>(
                        <div className="p-2" key={_i}>
                            <h5 className="fw-bold  bg-dark text-light shadow-sm rounded p-2">{group_name}</h5>
                            {fees&&fees?.map(({remarks,option_value})=>(
                                <div className="p-2">
                                        <div className="row bg-light shadow-sm rounded">
                                        <div className="col-md-8">
                                            <p className="mb-0 p-2 fw-bold">Remarks</p>
                                        </div>
                                        <div className="col-md-4">
                                          <p className="mb-0 p-2 fw-bold">Fee</p>  
                                        </div>
                                    </div>
                                    <div className="row bg-white shadow-sm rounded">
                                        <div className="col-md-8">
                                            <p className="mb-0 p-2">{remarks}</p>
                                        </div>
                                        <div className="col-md-4">
                                          <p className="mb-0 p-2">Rs. {option_value}</p>  
                                        </div>
                                    </div>
                                </div>
                                   
                            ))}
                        </div>
                    ))
                }
        </div>
    )
}

export default SubscriptionAndFeeStructure

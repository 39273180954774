import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getStudCertificateById } from '../../../api'
import Loader from '../../../components/loader'

const StudCertificateDetails = () => {

    const {id}=useParams()
    const [certificate,setCertificate]=useState({})
    const [loading,setLoading]=useState({})

    useEffect(()=>{

        const fetchData=async()=>{
            setLoading(true)
            const res=await getStudCertificateById(id)
            setCertificate(res&&res[0])
            setLoading(false)
        }

        fetchData()
    },[id])

    if (loading) return <Loader size={50}/>
    return (
        <div className="container-fluid py-3">
            <div className="bg-light shadow-sm p-2">
            <h2>  Stud Certificate of <span className="text-primary mx-2">{certificate?.sire_name}</span>
           & <span className="mx-2 text-primary">{certificate?.dam_name}</span></h2>
            The declaration made on this certificate is certified as true to the best of my knowledge the pedigree 
            of the bitch has been examined by me and has been certified/issue by the G S D.1D.P
            </div>
            <div className="row mt-3">
                <div className="col-md-6">
                    <h5 className="mx-2 fw-bold">Sire :</h5>
                    <div className="col-md-12 mx-2">
                <div className="row ">
                    <div className="col-md-4"><span className="fw-bold">Name of Sire:</span></div>
                    <div className="col-md-8"><span>{certificate?.sire_name}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">KP No:</span></div>
                    <div className="col-md-8"><span>{certificate?.sire_kp}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Date Of Birth:</span></div>
                    <div className="col-md-8"><span>{new Date(certificate?.sire_dob).toLocaleDateString()}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Tattoo:</span></div>
                    <div className="col-md-8"><span>{certificate?.sire_microchip}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Breed Survey</span></div>
                    <div className="col-md-8">{certificate?.sire_breed_survey}</div>
                </div>
                {/* <div className="row">
                    <div className="col-md-4"><span className="fw-bold">City:</span></div>
                    <div className="col-md-8"></div>
                </div> */}
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Name of Owner(s) of Stud:</span></div>
                    <div className="col-md-8"><Link style={{textDecoration:'none'}} to={certificate?.sire_owner_url?`/member-profile/${certificate?.sire_owner_url}`:"#"}>{certificate?.sire_owner}</Link></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Mating Date:</span></div>
                    <div className="col-md-8">{new Date(certificate?.mating_date).toLocaleDateString()}</div>
                </div>
                </div>
                </div>
                <div className="col-md-6">
                <h5 className="mx-2 fw-bold">Dam :</h5>
                <div className="col-md-12 mx-2">
                <div className="row ">
                    <div className="col-md-4"><span className="fw-bold">Name of Dam:</span></div>
                    <div className="col-md-8"><Link style={{textDecoration:'none'}} to={`/dog/bio/${certificate?.dam_url}`}>{certificate?.dam_name}</Link></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">KP No:</span></div>
                    <div className="col-md-8"><span>{certificate?.dam_kp}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Date Of Birth:</span></div>
                    <div className="col-md-8"><span>{new Date(certificate?.dam_dob).toLocaleDateString()}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Tattoo:</span></div>
                    <div className="col-md-8"><span>{certificate?.dam_microchip}</span></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Breed Survey</span></div>
                    <div className="col-md-8">{certificate?.dam_breed_survey}</div>
                </div>
                {/* <div className="row">
                    <div className="col-md-4"><span className="fw-bold">City:</span></div>
                    <div className="col-md-8"></div>
                </div> */}
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Name of Owner(s) of Dam:</span></div>
                    <div className="col-md-8"><Link style={{textDecoration:'none'}} to={certificate?.dam_owner_url?`/member-profile/${certificate?.dam_owner_url}`:"#"}>{certificate?.dam_owner}</Link></div>
                </div>
                <div className="row">
                    <div className="col-md-4"><span className="fw-bold">Mating Date:</span></div>
                    <div className="col-md-8">{new Date(certificate?.mating_date).toLocaleDateString()}</div>
                </div>
                </div>
                </div>
           
                </div>
            </div>
        
    )
}

export default StudCertificateDetails

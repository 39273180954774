import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getLitterDetailsById, getLitterPuppiesById } from '../../../api'

const LitterRegistrationDetails = () => {
    const [detail,setDetail]=useState({})
    const [puppies,setPuppies]=useState([])
    const {id}=useParams()

    useEffect(()=>{
     const fetchData=async()=>{
        const res=await getLitterDetailsById(id)
        setDetail(res&&res[0])
        const ress=await getLitterPuppiesById(id)
        setPuppies(ress&&ress)
        }
    fetchData()

    console.log(detail,"details")
    },[id])


    return (
        <div>
            <h1>Litter Registration Details</h1>
            <div className="row mt-5  justify-content-around">
                <div className="col-md-5  border rounded shadow-sm p-2">
                    <h4 className="fw-bold mb-2 bg-light p-2 rounded">Breeder Details</h4>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Name of Breeder:</label></div>
                        <div className="col-md-8">{detail?.breeder_name}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Address:</label></div>
                        <div className="col-md-8">{detail?.address}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Phone:</label></div>
                        <div className="col-md-8">{detail?.Phone}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Email:</label></div>
                        <div className="col-md-8">{detail?.email}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Kennel:</label></div>
                        <div className="col-md-8">{detail?.kennel_name}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Suffix:</label></div>
                        <div className="col-md-8">{detail?.kennel_suffex}</div>
                    </div>
                </div>
                <div className="col-md-5  border  rounded shadow-sm p-2">
                <h4 className="fw-bold mb-2 bg-light p-2 rounded">Litter Parent</h4>
                <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Sire:</label></div>
                        <div className="col-md-8">{detail?.sire_name} </div>
                    </div>
                <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">DOB:</label></div>
                        <div className="col-md-8">{new Date(detail?.sire_dob).toLocaleDateString()} </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">DNA Status:</label></div>
                        <div className="col-md-8">{detail?.sire_dna_status || "-"}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">HD / ED:</label></div>
                        <div className="col-md-8">
                            <span className="badge bg-success">{detail?.sire_hd}</span>
                            <span className="badge bg-success">{detail?.sire_ed}</span></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">Dam:</label></div>
                        <div className="col-md-8">{detail?.dam_name} </div>
                    </div>
                <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">DOB:</label></div>
                        <div className="col-md-8">{new Date(detail?.dam_dob).toLocaleDateString()} </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">DNA Status:</label></div>
                        <div className="col-md-8">{detail?.dam_dna_status || "-"}</div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-4"><label className="fw-bold">HD / ED:</label></div>
                        <div className="col-md-8">
                            <span className="badge bg-success">{detail?.dam_hd}</span>
                            <span className="badge bg-success">{detail?.dam_ed}</span>
                        </div>
                    </div>
            
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <h4 className="fw-bold shadow-sm bg-light rounded p-2">Puppies Information</h4>
                </div>
                <div className="col-md-12 my-2 "><label className="fw-bold">Mating Date:</label><span className="mx-2">{new Date(detail?.mating_date).toDateString()}</span></div>
                <div className="col-md-12 my-2 "> <label className="fw-bold">Whelping Date:</label><span className="mx-2">{new Date(detail?.dob).toDateString()}</span></div>
              
               
            </div>
            <div className="row mt-5">
            <table class="table table-responsive shadow-sm">
  <thead class="bg-dark text-white">
      
    <tr>
      <th scope="col">Puppy Name</th>
      <th scope="col">Gender</th>
      <th scope="col">DNA Sample Taken</th>
      <th scope="col">Microchips</th>
      <th scope="col">Hair</th>
      <th scope="col">Color</th>
    </tr>
  </thead>
  <tbody>
  {
          puppies.length>0?puppies?.map(({puppy_full_name,DNA_taken,sex,microchip,color,hair})=>(
            <tr>
            <th scope="col">{puppy_full_name?puppy_full_name:"-"}</th>
            <th scope="col">{sex?sex:"-"}</th>
            <th scope="col">{DNA_taken?DNA_taken:"-"}</th>
            <th scope="col">{microchip?microchip:"-"}</th>
            <th scope="col">{hair?hair:"-"}</th>
            <th scope="col">{color?color:"-"}</th>
          </tr>
          )):<tr><td align="center" colSpan="5">No Puppies found.</td></tr>
      }
   
  </tbody>
</table>
            </div>


        </div>
    )
}

export default LitterRegistrationDetails

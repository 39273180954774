import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getRecentMatings } from '../../api'
import logo from '../../assets/Images/rottweiler-logo.png'
import VerticalAdverts from '../../components/verticalAdverts'
import { GlobalContext } from '../../context/store'
import Sidebar_Home from '../../layouts/SideNavigation_Home'
import Sidebar_Member from '../../layouts/SideNavigation_Member'

const RecordsOfRecentMatings = () => {

    const [recent_matings, setRecent_matings] = useState([])
    console.log(recent_matings,"recent_matings")
    // const [meetingDate, setMeetingDate] = useState([])
    // console.log(meetingDate,"ooooooo")
    const { user } = useContext(GlobalContext)
    useEffect(() => {
        const fetchData = async () => {
            const res = await getRecentMatings();
            setRecent_matings(res && res)
        }
        fetchData()
        // getDate()
    }, [])


    // const getDate = async () => {
    //     const dateRes = await getRecentMatings();
    //     const onlydate = new Date(dateRes[0].mating_date);
    //     console.log(onlydate, "aaaaaaaaaaaa")
    //     // console.log(onlydate.getFullYear()  + "/" + (onlydate.getMonth() + 1) + "/" + onlydate.getDate(),"dddddddddd")
    //     setMeetingDate(onlydate.getFullYear() + "/" + (onlydate.getMonth() + 1) + "/" + onlydate.getDate())
    // }

    return (
        <div className="row mx-auto mt-5 bg-white shadow rounded">
            <div className="col-md-2">
                {
                    !user ?
                        <Sidebar_Home />
                        : <Sidebar_Member />

                }
            </div>
            <div className="col-md-8">
                <div className="p-2">
                    <h1> Upcoming Rottweiler Puppy Litters</h1>
                    <div className="row shadow-sm border p-2 rounded bg-light m-2 mt-5">
                        <div className="col-md-4">
                            <label className="m-2 fw-bold">Kennel Name:</label>
                            <input placeholder="Any Kennel Name" type="text" className="m-2 form-control" />
                        </div>
                        <div className="col-md-3">
                            <label className="m-2 fw-bold">Stud Name:</label>
                            <input placeholder="Any Stud Name" type="text" className="m-2 form-control" />

                        </div>
                        <div className="col-md-3">
                            <label className="m-2 fw-bold">Dam Name:</label>
                            <input placeholder="Any Dam Name" type="text" className="m-2 form-control" />
                        </div>
                        <div className="col-md-2 d-flex flex-column justify-content-center mt-2">
                            <input type="button" value="Search" className="mt-4 btn btn-primary" />
                        </div>

                    </div>
                    {
                        recent_matings?.length ? recent_matings?.map(({ sire_name, dam_name, sire_url, dam_url, mating_date,kennel_name, friendly_URl }, ind) => (

                            <div className="p-3 rounded border mx-2 mt-3" key={ind}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Link to={friendly_URl ? `../kennels-directory/${friendly_URl}` : "#"} style={{ textDecoration: "none" }}>
                                            <div className="d-flex justify-content-start bg-light p-2 shadow rounded">
                                                <div className="profile-img">
                                                    {/* <img src="#" className="rounded-circle"/> */}
                                                </div>
                                                {/* <div className="d-flex flex-column justufy-content-between align-items-start" > */}
                                                <div className="" style={{display:"flex", gap:50}} >
                                                    <div>
                                                        <label style={{ color: "black", fontSize: 13 }}>Kennel</label>
                                                        <h4 style={{ fontSize: 21 }}>{kennel_name}</h4>
                                                    </div>
                                                    <div>
                                                        <label style={{ color: "black", fontSize: 13 }}>Meeting Date</label>
                                                        <h4 style={{ fontSize: 21}}>{mating_date.slice(0,10)}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Link to={sire_url ? `../dog/bio/${sire_url}` : "#"} style={{ textDecoration: "none" }}>
                                            <div className="d-flex justify-content-start bg-light p-2 shadow rounded">
                                                <div className="profile-img">
                                                    {/* <img src="#" className="rounded-circle"/> */}
                                                </div>
                                                <div className="d-flex flex-column justufy-content-between align-items-start">
                                                    <label style={{ color: "black", fontSize: 13 }}>Stud </label>
                                                    <h4 style={{ fontSize: 21 }}>{sire_name}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                        <div>
                                            <span style={{ fontSize: 25 }}>X</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <Link to={sire_url ? `../dog/bio/${dam_url}` : "#"} style={{ textDecoration: "none" }}>
                                            <div className="d-flex justify-content-start bg-light p-2 shadow rounded">
                                                <div className="profile-img">
                                                    {/* <img src="#" className="rounded-circle"/> */}
                                                </div>
                                                <div className="d-flex flex-column justufy-content-between align-items-start">
                                                    <label style={{ color: "black", fontSize: 13 }}>Dam</label>
                                                    <h4 style={{ fontSize: 21 }}>{dam_name}</h4>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )) : <h3 className="p-2 text-center">No record(s) found.</h3>
                    }
                </div>
            </div>
            <div className="col-md-2">
                <VerticalAdverts />
            </div>
        </div>

    )
}

export default RecordsOfRecentMatings
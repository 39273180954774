import React, { useEffect, useState } from 'react'
import { getAboutClub } from '../../../api'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const AboutClub = () => {

    const [data,setData]=useState({})
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
            const fetchData=async()=>{
                setLoading(true)
                const res=await getAboutClub()
                setData(res&&res[0])
                setLoading(false)
            }
            fetchData()
    },[])

    function convertHTMLEntity(text){
        const span = document.createElement('span');
        return text?.replace(/&[#A-Za-z0-9]+;/gi, (entity,position,text)=> {
            span.innerHTML = entity;
            return span.innerText;
        });
    }
    return (
        <>
        {loading?
        <div>
            <Skeleton width={'40%'}   height={40}/>
              <div className="mt-5">
            <Skeleton width={'100%'} count={10} height={18}/>
            </div>
        </div>

             :    
    
        <div>
            <h1>About Club</h1>
            <div className="p-3" dangerouslySetInnerHTML={{__html: convertHTMLEntity(data?.content)}}></div>
        </div>
        }
        </>
    )
}

export default AboutClub

import React, { useContext, useEffect, useRef, useState } from "react";
import { getAllCities, register } from "../../api";
import ImageUploader from "../../components/imageUploader";
import { GlobalContext } from "../../context/store";
import Select from "react-select";
import { toast } from "react-toastify";
const Register = () => {
  const [cities, setCities] = useState([]);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [photo, setPhoto] = useState([]);
  const [cnic, setCnic] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");

  const { cityRef } = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getAllCities();
      setCities(res && res);
    };
    fetchData();
  }, []);

  const onSub = async (e) => {
    console.log(photo[0], "photo");
    let formData = new FormData();
    formData.append("photo", photo[0]);
    // formData.append("username",username)
    formData.append("email", email);
    formData.append("first_name", first_name);
    formData.append("last_name", last_name);
    formData.append("address", address);
    formData.append("cnic", cnic);
    formData.append("city", city);
    formData.append("phone", phone);
    e.preventDefault();

    const res = await register(formData);

    if (res.status == 200) {
      if (res.status == 200) {
        toast.success(
          "Profile submitted successfully",
          {
            // position: "top-center",
            // autoClose: 2000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,

            style: { zIndex: "10000000" },

          },
          EmptyForm()
        );
      }
    }
  };

  const EmptyForm = () => {
    // cityRef.current.clearValue()
    setAddress("");
    setPhone("");
    setCity("");
    setCnic("");
    setEmail("");
    setPhoto("");
    setAddress("");
    setFirst_name("");
    setlast_name("");
    setUsername("");
  };

  return (
    <div className="container bg-white rounded ">
      <div className="row p-5">
        <h1 className="mb-3">Online Membership Application</h1>
        <div className="col-md-12 col-md-offset-2 shadow rounded mt-2 p-4 bg-light">
          <form onSubmit={onSub}>
            <div className="row my-2">
              <div className="form-group col-md-6">
                <label for="firstName">First Name</label>
                <input
                  required
                  value={first_name}
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name"
                  onChange={(e) => setFirst_name(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label for="Last Name">Last Name</label>
                <input
                  required
                  value={last_name}
                  type="text"
                  className="form-control"
                  id="Lastname"
                  placeholder="Last Name"
                  onChange={(e) => setlast_name(e.target.value)}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="form-group col-md-6">
                <label for="inputAddress">CNIC</label>
                <input
                  required
                  value={cnic}
                  type="text"
                  className="form-control"
                  id="inputAddress"
                  placeholder="CNIC"
                  onChange={(e) => setCnic(e.target.value)}
                />
              </div>
              <div className="form-group col-md-6">
                <label for="Email">Email</label>
                <input
                  required
                  value={email}
                  type="email"
                  className="form-control"
                  id="Email"
                  placeholder="example@xyz.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="row my-2">
              <div className="form-group col-md-6">
                <label for="inputCity">City</label>
                <Select
                  ref={cityRef}
                  options={cities?.map(({ city, id }) => ({
                    value: id,
                    label: city,
                  }))}
                  onChange={(e) => setCity(e.value)}
                />
                {/* <input
            value={city} 
            type="text" className="form-control" id="inputCity"
                       onChange={(e)=>setCity(e.target.value)}
            /> */}
              </div>
              <div className="form-group col-md-6">
                <label for="PhoneNo">Phone No.</label>
                <input
                  required
                  value={phone}
                  type="text"
                  className="form-control"
                  id="phoneNo"
                  placeholder="03xxxxxxxxx"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="row my-2">
              <div className="form-group col-md-12">
                <label for="Address">Address.</label>
                <input
                  value={address}
                  type="text"
                  className="form-control"
                  id="Address"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="my-3">
              <input
                type="file"
                className="form-control"
                onChange={(e) => setPhoto(e.target.files)}
              />
            </div>
            {/* <ImageUploader/> */}

            <div className="row my-2">
              <button
                type="submit"
                className="butn butn-grey shadow-sm rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;

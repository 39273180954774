import React from 'react'
import badgeImg from '../assets/Images/badge.png'

const Badge = ({title}) => {
    return (
<span className="rounded badge text-dark bg-light p-2 shadow-sm d-flex align-items-center justify-content-center ">
<img src={badgeImg} style={{height:'35px',width:'35px'}}/>
    <spna>{title}</spna>
</span>
    )
}

export default Badge
